import useSystemStore from '@/stores/systemStore'; // 필요에 따라 경로를 조정하세요
import { msalInstance, ssoLogout } from '@fila/lib/plugins/authConfig';
import { MENU_INFO } from '@fila/lib/utils/constCommon';
import apiCall from '@/plugins/apiCall';

const getScopeByCompCd = async compCd => {
	const params = {
		cmCd: 'SYS_MS_SCOPE',
		compCd: 'D001',
		dtlCd: compCd,
		langCd: 'kr',
	};

	let scope = '';
	await apiCall
		.get('CM_API/cm/global/common/public/cmCdList', params)
		.then(res => {
			scope = res.data.data[0].rmrk1;
		});

	return scope;
};

export const afterLogin = async router => {
	try {
		const res = await apiCall.post('CM_API/cm/global/common/auth/ssoLogin');
		if (!res.data.data) {
			message.showAlert({
				message: '로그인 처리 중 오류 발생 1:',
				onConfirm: () => {
					ssoLogout();
				},
			});
		}
		await useSystemStore().load();
		localStorage.setItem('ssoYn', 'Y');
		nextTick(async () => {
			await router.push({
				path: MENU_INFO.HOME_PATH,
				// hash: '#' + uuidv4()
			});
			window.location.reload();
			loader.hide();
		});
	} catch (error) {
		console.error('Error during afterLogin:', error);
		// 오류 처리 로직 추가 가능
	}
};

export const acquireToken = async (compCd, router) => {
	const accounts = msalInstance.getAllAccounts();
	const accessToken = ref(null); // accessToken 상태 관리
	if (accounts.length > 0) {
		const scope = await getScopeByCompCd(compCd);
		const request = {
			account: accounts[0],
			scopes: [scope],
		};
		try {
			const response = await msalInstance.acquireTokenSilent(request);
			accessToken.value = response.accessToken;
			await useSystemStore().login(response.accessToken, '');

			afterLogin(router);
		} catch (error) {
			if (error.name === 'InteractionRequiredAuthError') {
				// 사용자 인터랙션이 필요한 경우, redirect 또는 popup을 통해 토큰을 요청
				const response = await msalInstance.acquireTokenRedirect(request);
				accessToken.value = response.accessToken;
				await useSystemStore().login(response.accessToken, '');
				afterLogin(router);
			} else {
				message.showAlert({
					message: '로그인 처리 중 오류 발생 2:',
					onConfirm: () => {
						ssoLogout();
					},
				});
			}
		}
	}
};
