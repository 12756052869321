<!--------------------------------------------------------------------
	파일   : CmCdPop.vue
	작성   : CJ Developer
	설명	 : [공통] 공통코드 조회 팝업 컴포넌트
---------------------------------------------------------------------->
<template>
	<FLayerPopup ref="refPopup" :title="currentTitle" width="1000" height="700">
		<template #body>
			<div v-html="htmlContent"></div>
		</template>
	</FLayerPopup>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';

const { t } = useI18n();
const htmlContent = ref('');

const props = defineProps({
	compCdValue: {
		type: [String, null],
	},
	pinfoPlcyTrgtCdValue: {
		type: [String, null],
	},
});
const compCd = computed({
	get() {
		return props.compCdValue;
	},
});
const pinfoPlcyTrgtCd = computed({
	get() {
		return props.pinfoPlcyTrgtCdValue;
	},
});

const currentTitle = ref(t('LBL.CM.PINFOPLCY.001'));

const refPopup = shallowRef(null);

//팝업오픈
const fnOpen = async () => {
	refPopup.value.open();
	const params = {
		compCd: compCd.value,
		pinfoPlcyTrgtCd: pinfoPlcyTrgtCd.value,
	};
	await apiCall
		.get('CM_API/cm/global/common/auth/getPinfoPlcyHtml', params)
		.then(res => {
			if (commonUtils.gfn_isEmpty(res.data.data)) {
				htmlContent.value = '';
			} else {
				htmlContent.value = res.data.data.pinfoPlcyCntn;
			}
			return;
		});
};

function getShow() {
	return refPopup.value.getShow();
}

defineExpose({
	fnOpen,
	getShow,
});
</script>
