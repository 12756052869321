/**********************************************************************
 * 파일   : menuStore.js
 * 작성   : CJ Developer
 * 설명   : 메뉴 정보를 담고있는 스토어
 *  pinia는 스토어 단위로 storage에서 읽기/쓰기를 하므로
 *  성능 향상을 위해 용량이 큰 메뉴 데이터를 별도 스토어로 분리함.
 **********************************************************************/
import { defineStore } from 'pinia';

const useMenuStore = defineStore({
	id: 'menuStore',
	state: () => ({
		menuTree: {},
		sitemapTree: {},
	}),
	getters: {},
	actions: {
		clear() {
			this.menuTree = {};
			this.sitemapTree = {};
		},
	},
	persist: {
		enabled: true,
		strategies: [
			{
				key: 'menu',
				storage: localStorage,
			},
		],
	},
});

export default useMenuStore;
