<!--------------------------------------------------------------------
    파일   : LoginPop03.vue
    작성   : CJ Developer
    설명   : 인증수단 선택 팝업
---------------------------------------------------------------------->

<template>
	<p>{{ t('LBL.CM.LGN.003') }}</p>
	<div class="certf-conwrap">
		<label :class="{ certf: true, disabled: commonUtils.gfn_isEmpty(emlAddr) }">
			<div class="ico-wrap">
				<img
					src="/images/ico-20-login-checkOn.svg"
					alt="체크"
					class="checked"
				/>
				<img src="/images/ico-40-email.svg" alt="이메일" />
			</div>
			<input
				v-model="certWaySeCd"
				type="radio"
				name="rdo-01"
				value="01"
				:disabled="commonUtils.gfn_isEmpty(emlAddr)"
			/>
			<div class="certf-text">
				<p>Email</p>
				<p class="fs-14">
					{{
						commonUtils.gfn_isNotEmpty(emlAddr)
							? emlAddr
							: t('MSG.CM.NOINFO.001')
					}}
				</p>
			</div>
		</label>
		<label :class="{ certf: true, disabled: commonUtils.gfn_isEmpty(cpno) }">
			<div class="ico-wrap">
				<img
					src="/images/ico-20-login-checkOn.svg"
					alt="체크"
					class="checked"
				/>
				<img src="/images/ico-40-mobile.svg" alt="SMS" />
			</div>
			<input
				v-model="certWaySeCd"
				type="radio"
				name="rdo-01"
				value="03"
				:disabled="commonUtils.gfn_isEmpty(cpno)"
			/>
			<div class="certf-text">
				<p>SMS</p>
				<p class="fs-14">
					{{ commonUtils.gfn_isNotEmpty(cpno) ? cpno : t('MSG.CM.NOINFO.001') }}
				</p>
			</div>
		</label>
	</div>
</template>

<script setup>
import commonUtils from '@fila/lib/utils/commonUtils';

const { t } = useI18n();
const props = defineProps({
	emlAddrValue: {
		type: [String, null],
	},
	cpnoValue: {
		type: [String, null],
	},
	certWaySeCdValue: {
		type: [String, null],
	},
});

const emit = defineEmits(['update:certWaySeCdValue']);
const emlAddr = computed({
	get() {
		const email = props.emlAddrValue;
		if (!email) return '';

		// 이메일을 사용자 이름과 도메인으로 분리
		const [username, domain] = email.split('@');

		// 사용자 이름이 2자 이상인 경우, 첫 글자와 마지막 글자를 제외하고 마스킹 처리
		let maskedUsername = username;
		if (username.length > 2) {
			maskedUsername = `${username[0]}${'*'.repeat(username.length - 2)}${
				username[username.length - 1]
			}`;
		}

		// 마스킹 처리된 사용자 이름과 도메인을 조합하여 반환
		return `${maskedUsername}@${domain}`;
	},
});
const cpno = computed({
	get() {
		const rawCpNo = props.cpnoValue;
		if (!rawCpNo) return '';
		// '010-****-1111' 형태로 변환
		return rawCpNo.replace(
			/(\d{2,3})-?(\d{3,4})-?(\d{4})/,
			(match, p1, p2, p3) => `${p1}-${'*'.repeat(p2.length)}-${p3}`,
		);
	},
});
const certWaySeCd = computed({
	get() {
		return props.certWaySeCdValue;
	},
	set(value) {
		emit('update:certWaySeCdValue', value);
	},
});
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
	}
}
.certf-conwrap {
	display: flex;
	flex-direction: column;
	padding-top: 15px;
	gap: 25px;
	.certf {
		position: relative;
		display: flex;
		align-items: center;
		cursor: pointer;
		> input {
			position: absolute;
			left: -1000px;
			opacity: 0;
		}
		.ico-wrap {
			position: relative;
			width: 55px;
			height: 55px;
			background-color: #f4f5f6;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			.checked {
				position: absolute;
				top: 0;
				right: 0;
				opacity: 0;
				transition: all 0.3s;
			}
		}
		p {
			font-size: 16px;
			font-weight: 500;
			color: var(--basic-text);
			&.fs-14 {
				font-size: 14px;
				color: var(--basic-text-gray);
			}
		}
		&:has(input:checked) {
			.ico-wrap {
				.checked {
					opacity: 1;
				}
			}
		}

		&.disabled {
			background-color: #eeeeee;
			cursor: not-allowed;
		}
	}
}
.certf-text {
	display: flex;
	flex-direction: column;
	margin-left: 12px;
}
</style>
