<!--------------------------------------------------------------------
    파일   : LoginPop05.vue
    작성   : CJ Developer
    설명   : 인증번호 입력 팝업
---------------------------------------------------------------------->

<template>
	<p class="fs-14">
		<strong>{{ sendMthd }}</strong> {{ t('LBL.CM.LGN.014') }} <br />
		{{ t('LBL.CM.LGN.015') }}
	</p>
	<div class="logconwrap">
		<FInput
			id="certNo"
			v-model="certNo"
			:label="t('LBL.CR.CERTNO.001')"
			name="certNo"
			class="login"
			:postfix="timeLeft"
		/>
		<p class="fs-14">
			{{ t('LBL.CM.LGN.016') }}
		</p>
		<button class="bord" @click="resetTimer">{{ t('LBL.CM.LGN.017') }}</button>
	</div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useMessage } from '@fila/lib/plugins/message/message';
import apiCall from '@/plugins/apiCall';

const { t } = useI18n();

const message = useMessage();
const timeLeft = ref('');
const sendMthd = ref('');

const props = defineProps({
	emlAddrValue: {
		type: [String, null],
	},
	lgnIdValue: {
		type: [String, null],
	},
	cpnoValue: {
		type: [String, null],
	},
	certWaySeCdValue: {
		type: [String, null],
	},
	userIdValue: {
		type: [String, null],
	},
	certNoValue: {
		type: [String, null],
	},
	compCdValue: {
		type: [String, null],
	},
});

const emit = defineEmits(['update:certNoValue']);

const emlAddr = computed({
	get() {
		return props.emlAddrValue;
	},
});
const lgnId = computed({
	get() {
		return props.lgnIdValue;
	},
});
const cpno = computed({
	get() {
		return props.cpnoValue;
	},
});
const certWaySeCd = computed({
	get() {
		return props.certWaySeCdValue;
	},
});
const userId = computed({
	get() {
		return props.userIdValue;
	},
});
const compCd = computed({
	get() {
		return props.compCdValue;
	},
});
const certNo = computed({
	get() {
		return props.certNoValue;
	},
	set(value) {
		emit('update:certNoValue', value);
	},
});

let intervalId = null;

function startTimer() {
	const duration = 5 * 60; // 5분
	let time = duration;

	clearInterval(intervalId); // 기존 타이머가 있다면 클리어
	intervalId = setInterval(() => {
		const minutes = Math.floor(time / 60);
		const seconds = time % 60;

		timeLeft.value = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

		if (--time < 0) {
			clearInterval(intervalId);
			timeLeft.value = '00:00';
			message.showAlert({
				message: t('LBL.CM.LGN.018'),
				onConfirm: () => {
					return;
				},
			});
		}
	}, 1000);
}

onMounted(() => {
	switch (certWaySeCd.value) {
		case '01':
			const email = emlAddr.value;
			if (!email) return '';

			// 이메일을 사용자 이름과 도메인으로 분리
			const [username, domain] = email.split('@');

			// 사용자 이름이 2자 이상인 경우, 첫 글자와 마지막 글자를 제외하고 마스킹 처리
			let maskedUsername = username;
			if (username.length > 2) {
				maskedUsername = `${username[0]}${'*'.repeat(username.length - 2)}${username[username.length - 1]}`;
			}
			sendMthd.value = `${maskedUsername}@${domain}`;
			break;
		case '02':
			sendMthd.value = lgnId.value;
			break;
		case '03':
			const rawCpNo = cpno.value;
			if (!rawCpNo) return '';
			// '010-****-1111' 형태로 변환
			sendMthd.value = rawCpNo.replace(
				/(\d{2,3})-?(\d{3,4})-?(\d{4})/,
				(match, p1, p2, p3) => `${p1}-${'*'.repeat(p2.length)}-${p3}`,
			);
			break;
		default:
	}
	resetTimer();
});

onUnmounted(() => {
	clearInterval(intervalId);
});

const resetTimer = async () => {
	const params = {
		emlAddr: emlAddr.value,
		lgnId: lgnId.value,
		cpno: cpno.value,
		userId: userId.value,
		certWaySeCd: certWaySeCd.value,
		compCd: compCd.value,
	};

	await apiCall
		.post('CM_API/cm/global/common/auth/sendCertNo', params, {
			loader: true,
		})
		.then(() => {
			message.showAlert({
				message: t('LBL.CM.LGN.021'),
				onConfirm: () => {
					startTimer();
				},
			});
		});
};
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
		> strong {
			color: var(--heading-text);
		}
	}
}
.logconwrap {
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin-top: 6px;
	.login {
		width: 100%;
	}
	.bord {
		font-size: 14px;
		text-decoration: underline;
		margin-top: 6px;
	}
}
</style>
<style lang="scss">
.login {
	label {
		font-size: 14px;
		margin-bottom: 10px;
	}
	> .input-item {
		border-radius: 12px !important;
		> input {
			padding: 24px 16px 23px !important;
			border-radius: 12px;
			font-size: 16px;
		}
		> span:last-child,
		span {
			padding-right: 16px !important;
			color: #e1002b !important;
			font-size: 16px !important;
			padding-left: 24px;
			background: url(/images/ico-16-time-red.svg) no-repeat;
		}
	}
}
</style>
