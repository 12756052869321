<!--------------------------------------------------------------------
    파일   : LoginIdCnfm.vue
    작성   : CJ Developer
    설명   : 아이디 확인 팝업
---------------------------------------------------------------------->

<template>
	<p>{{ t('LBL.CM.LGN.001') }}</p>
	<div class="logconwrap">
		<FInput
			id="inputValue"
			v-model="lgnId"
			:label="t('LBL.CM.ID.001')"
			name="inputValue"
			class="login"
		/>
		<p class="fs-14">{{ t('LBL.CM.LGN.002') }}</p>
	</div>
</template>

<script setup>
const { t } = useI18n();

const props = defineProps({
	lgnIdValue: {
		type: [String, null],
	},
	compCdValue: {
		type: [String, null],
	},
});

const emit = defineEmits(['update:lgnIdValue', 'update:compCdValue']);
const lgnId = computed({
	get() {
		return props.lgnIdValue;
	},
	set(value) {
		emit('update:lgnIdValue', value);
	},
});
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
	}
}
.logconwrap {
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin-top: 16px;
	.login {
		width: 100%;
	}
}
</style>
<style lang="scss">
.login {
	label {
		font-size: 14px;
		margin-bottom: 10px;
	}
	> .input-item {
		border-radius: 12px !important;
		> input {
			padding: 24px 16px 23px !important;
			border-radius: 12px;
			font-size: 16px;
		}
	}
}
</style>
