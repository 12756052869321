import { h, render } from 'vue';
import i18n from '@fila/lib/lang';
import authPop from '@/components/popup/AuthPop.vue';

/**
 *
 * @param {any} component
 * @param {any} props
 * @param {HTMLElement} parentContainer
 * @returns
 */
function createComponent(component, props, parentContainer) {
	const vnode = h(component, props);
	const container = document.createElement('div');
	parentContainer.appendChild(container);
	render(vnode, container);

	return vnode.component;
}

function removeElement(el) {
	if (typeof el.remove !== 'undefined') {
		el.remove();
	} else {
		el.parentNode?.removeChild(el);
	}
}

/**
 *
 * @param {object} globalSlots
 * @returns
 */
export function useAuthPop() {
	let authPopBox = [];

	const loading = {
		/**
		 * 전달받은 데이터로 메세지 컴포넌트를 생성하는 함수
		 * @param {object} customProps
		 */
		createAuthPop(customProps) {
			let instance = createComponent(authPop, customProps, document.body);
			authPopBox.push(instance);
		},
		/**
		 * Message 창을 제거하는 함수
		 */
		hide() {
			console.log('hide called');
			/**
			 * Message가 중복생성되는 경우가 존재하여 아래와 같이 수정함
			 */
			while (authPopBox.length > 0) {
				let instance = authPopBox.pop();
				let root = instance.vnode.el;
				removeElement(root.parentElement);
			}
		},
		/**
		 * Confirm 창을 생성하는 함수
		 * @param {object} props
		 * @returns
		 */
		showAuthPop(props) {
			return new Promise(resolve => {
				const propsData = Object.assign(
					{
						i18n: i18n,
						onConfirm: () => (props?.onConfirm ? props.onConfirm() : {}),
						onCancel: () => (props?.onCancel ? props.onCancel() : {}),
						onFinishConfirm: () => {
							this.hide();
							resolve(true);
						},
						onFinishCancel: () => {
							this.hide();
							resolve(false);
						},
					},
					props,
				);

				this.createAuthPop(propsData);
			});
		},
	};
	return loading;
}
