<!--------------------------------------------------------------------
	파일   : NotFound.vue
	작성   : CJ Developer
	설명	 : [공통] NotFound 화면 컴포넌트
---------------------------------------------------------------------->
<template>
	<div class="error-area">
		<div class="error-box">
			<img
				src="/images/logo-fila.svg"
				alt="FILA 로고 이미지"
				class="lightNot"
			/>
			<img
				src="/images/logo-fila-dark.svg"
				alt="FILA 로고 이미지"
				class="darkNot"
			/>
			<p class="fs-24 mt-11">요청하신 페이지를 찾을 수 없습니다.</p>
			<div class="mt-9">
				<p>
					페이지 주소가 정확하지 않거나, 주소가 변경되거나 삭제되어 찾을 수
					없습니다.
				</p>
				<p>입력하신 주소가 정확한지 다시 한번 확인해주시기 바랍니다.</p>
				<p class="mt-5">
					동일하게 오류가 발생하는 경우, 시스템 담당자에게 문의하세요.
				</p>
			</div>
			<div class="gray-box">
				<p>문의 : IT전략팀 OOO 02-0000-0000</p>
			</div>
			<FButton primary size="large" @click="onClickMoveHome">
				메인 화면으로 이동
			</FButton>
		</div>
	</div>
</template>

<script setup>
import { MENU_INFO } from '@fila/lib/utils/constCommon';

//const router = useRouter();

function onClickMoveHome() {
	window.location.href = MENU_INFO.HOME_PATH;
	//router.push({ path: MENU_INFO.HOME_PATH });
}
</script>

<style lang="scss" scoped>
.error-area {
	position: relative;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 900px;
		height: 140px;
		left: calc(50% - 450px);
		top: calc(50% - 70px);
		background: url(@/assets/images/img_error-bg.png) no-repeat center;
		z-index: -1;
	}
	.error-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: -20px;
		p {
			font-size: 16px;
			line-height: 24px;
			color: #555555;
		}
		.fs-24 {
			font-size: 24px;
			font-weight: 800;
			color: #111;
		}
		> div {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.gray-box {
			background-color: #f4f5f6;
			height: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 115px;
			border-radius: 4px;
			margin: 50px 0 70px 0;
			p {
				font-weight: 800;
				color: #111;
			}
		}
	}
}
.darkNot {
	display: none;
}
:root:has(.darkMode) {
	.darkNot {
		display: block;
	}
	.lightNot {
		display: none;
	}
	.error-area {
		&:before {
			opacity: 0.1;
		}
		.error-box {
			p {
				color: #bbb;
			}
			.fs-24 {
				color: #fff;
			}
			.gray-box {
				p {
					font-weight: 800;
					color: #111;
				}
			}
		}
	}
}
</style>
