<!--------------------------------------------------------------------
    파일   : LoginCertPop.vue
    작성   : CJ Developer
    설명   : 장기인증 팝업
---------------------------------------------------------------------->

<template>
	<FLayerPopup
		ref="refPopup"
		width="480"
		height="320"
		:title="currentTitle"
		:confirm="
			currentPopup === 'loginCertSend'
				? t('BTN.SA.TRAN.001')
				: t('BTN.CM.CNFM.001')
		"
		@confirm="onClickConfirm"
		@close="onClickClose"
	>
		<template #header />
		<template #body>
			<!-- 이메일 인증 팝업 -->
			<LoginCertMthd
				v-if="currentPopup === 'loginCertMthd'"
				v-model:emlAddrValue="emlAddr"
				v-model:cpnoValue="cpno"
				v-model:certWaySeCdValue="certWaySeCd"
			/>
			<LoginCertSend
				v-else-if="currentPopup === 'loginCertSend'"
				v-model:emlAddrValue="emlAddr"
				v-model:lgnIdValue="lgnId"
				v-model:cpnoValue="cpno"
				v-model:certWaySeCdValue="certWaySeCd"
				@prev-clicked="onClickPrev"
			/>
			<LoginCertNo
				v-else-if="currentPopup === 'loginCertNo'"
				v-model:emlAddrValue="emlAddr"
				v-model:lgnIdValue="lgnId"
				v-model:cpnoValue="cpno"
				v-model:userIdValue="userId"
				v-model:certNoValue="certNo"
				v-model:certWaySeCdValue="certWaySeCd"
				v-model:compCdValue="compCd"
			/>
			<LoginLtermCnfm
				v-else-if="currentPopup === 'loginLtermCnfm'"
				v-model:ltermChkValue="ltermChk"
			/>
		</template>
		<template #footer />
	</FLayerPopup>
</template>

<script setup>
import { ref } from 'vue';
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useMessage } from '@fila/lib/plugins/message/message';

import LoginCertMthd from '@/components/login/LoginCertMthd.vue';
import LoginCertSend from '@/components/login/LoginCertSend.vue';
import LoginCertNo from '@/components/login/LoginCertNo.vue';
import LoginLtermCnfm from '@/components/login/LoginLtermCnfm.vue';
const { t } = useI18n();

const emit = defineEmits(['ltermClicked']);

const props = defineProps({
	compCdValue: {
		type: [String, null],
	},
	lgnIdValue: {
		type: [String, null],
	},
	emlAddrValue: {
		type: [String, null],
	},
	cpnoValue: {
		type: [String, null],
	},
	userIdValue: {
		type: [String, null],
	},
});
const compCd = computed({
	get() {
		return props.compCdValue;
	},
});
const lgnId = computed({
	get() {
		return props.lgnIdValue;
	},
});
const emlAddr = computed({
	get() {
		return props.emlAddrValue;
	},
});
const cpno = computed({
	get() {
		return props.cpnoValue;
	},
});
const userId = computed({
	get() {
		return props.userIdValue;
	},
});

const currentPopup = ref('loginCertMthd');
//const currentTitle = ref('');
const currentTitle = computed(() => t('LBL.CM.LTERMCERT.003'));

const certWaySeCd = ref('01');
const certNo = ref('');
const ltermChk = ref('Y');
const message = useMessage();

const onClickConfirm = () => {
	switch (currentPopup.value) {
		case 'loginCertMthd':
			getLoginCertMthd();
			break;
		case 'loginCertSend':
			sendLoginCertNo();
			break;
		case 'loginCertNo':
			checkloginCertNo();
			break;
		case 'loginLtermCnfm':
			emit('ltermClicked', ltermChk.value);
			fnClose();
			break;
		default:
			break;
	}
};

const getLoginCertMthd = async () => {
	if (commonUtils.gfn_isEmpty(certWaySeCd.value)) {
		message.showAlert({
			message: t('MSG.CM.LGN.002'),
			onConfirm: () => {
				return false;
			},
		});
	} else {
		if (
			(certWaySeCd.value == '01' && commonUtils.gfn_isEmpty(emlAddr.value)) ||
			(certWaySeCd.value == '03' && commonUtils.gfn_isEmpty(cpno.value))
		) {
			message.showAlert({
				message: t('MSG.CM.LGN.002'),
				onConfirm: () => {
					return false;
				},
			});
		} else {
			currentPopup.value = 'loginCertSend';
		}
	}
};

const onClickPrev = async () => {
	currentPopup.value = 'loginCertMthd';
};

const sendLoginCertNo = async () => {
	certNo.value = '';
	currentPopup.value = 'loginCertNo';
};

const checkloginCertNo = async () => {
	const params = {
		userId: userId.value,
		certNo: certNo.value,
	};
	await apiCall
		.post('CM_API/cm/global/common/auth/checkCertNo', params)
		.then(res => {
			message.showAlert({
				message: res.data.statusMessage,
				onConfirm: () => {
					if (res.data.data.successYn === '1') {
						currentPopup.value = 'loginLtermCnfm';
					} else {
						certNo.value = '';
						return;
					}
				},
			});
		});
};

const refPopup = shallowRef(null);

//팝업오픈
const fnOpen = async () => {
	refPopup.value.open();
};

const fnClose = async () => {
	popupClose();
};

function onClickClose() {
	popupClose();
}

function popupClose() {
	currentPopup.value = 'loginCertMthd';
	certWaySeCd.value = '';
	certNo.value = '';
	ltermChk.value = 'Y';
	refPopup.value.close();
}

function getShow() {
	return refPopup.value.getShow();
}

defineExpose({
	fnOpen,
	getShow,
});
</script>
