<template>
	<div class="search-section">
		<div>
			<input
				ref="refInput"
				type="text"
				:placeholder="t('MSG.CM.COM.003')"
				maxlength="5000"
				@keydown.up="onMoveList('up')"
				@keydown.down="onMoveList('down')"
				@keypress.enter="onSelect"
				@focus="onDropdownStatus(true)"
				@blur="onDropdownStatus(false)"
				@click="onDropdownStatus(true)"
				@input="onInputSearch"
			/>
		</div>

		<!-- Dropdown -->
		<ul
			v-show="openDropdown && menuList?.length > 0"
			ref="refMenuDropDown"
			class="dropdown"
			:class="{ open: openDropdown }"
		>
			<li
				v-for="(menu, index) in menuList"
				:key="menu.menuId"
				:class="{ active: index === focusIndex }"
				@mousedown="onSelect($event, menu)"
			>
				<FPin :on="menu?.fxYn === 'Y'" />
				<div>
					<p v-html="menu.menuNm"></p>
					<p>{{ menu.urlNm }}</p>
				</div>
			</li>
		</ul>
	</div>
</template>

<script setup>
import commonUtils from '@fila/lib/utils/commonUtils';
import useSystemStore from '@/stores/systemStore';

const { t } = useI18n();
const refInput = ref(null);
let leafMenuList = useSystemStore().leafMenuList ?? [];

const menuList = ref(leafMenuList);

const refMenuDropDown = ref(null);
const focusIndex = ref(-1);

if (commonUtils.gfn_isEmpty(leafMenuList)) {
	useSystemStore()
		.setLeafMenuList()
		.then(() => {
			leafMenuList = useSystemStore().leafMenuList ?? [];
			menuList.value = leafMenuList;
		});
}

/**
 *
 * @param {string} direction
 */
// onMoveList 메서드 구현
function onMoveList(direction) {
	if (menuList?.length === 0) return;

	if (direction === 'up') {
		focusIndex.value = Math.max(0, focusIndex.value - 1);
	} else if (direction === 'down') {
		focusIndex.value = Math.min(
			menuList.value?.length - 1,
			focusIndex.value + 1,
		);
	}

	// 드롭다운 목록의 스크롤 위치 조정
	const dropdownList = refMenuDropDown.value;
	if (dropdownList) {
		const activeElement = dropdownList?.querySelector('.active');
		if (activeElement) {
			activeElement.scrollIntoView({ block: 'center' });
		}
	}
}

/**
 * Select Value
 * @param {event} event
 * @param {object} selectValue
 */
function onSelect(event, menu) {
	switch (event.type) {
		case 'keypress':
			if (focusIndex.value >= 0) {
				commonUtils.gfn_moveTabMenuById(
					menuList.value[focusIndex.value].menuId,
				);
			} else {
				if (menuList.value?.length > 0) {
					commonUtils.gfn_moveTabMenuById(menuList.value[0].menuId);
				}
			}
			break;
		default:
			commonUtils.gfn_moveTabMenuById(menu.menuId);
			break;
	}

	onDropdownStatus(false);
	refInput.value.blur();

	refInput.value.value = '';
	menuList.value = leafMenuList;
	focusIndex.value = -1;
}

/**
 * Dropdown
 */
const openDropdown = ref(false);

/**
 * Focus Event
 */
async function onDropdownStatus(value) {
	if (commonUtils.gfn_isEmpty(menuList.value)) {
		menuList.value = leafMenuList;
	}
	openDropdown.value = value;
}

function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

const onInputSearch = async e => {
	const rawInputValue = e.target.value;
	const trimmedValue = rawInputValue?.trim();
	const searchValue = trimmedValue?.toLowerCase().replace(/\s+/g, '');
	const isSearchValueEmpty = !searchValue;

	if (!openDropdown.value) {
		await onDropdownStatus(true);
	}

	const filteredMenuList = [];
	for (const menu of leafMenuList) {
		const rawMenuName = menu?.menuNm;
		const normalizedMenuName = rawMenuName?.toLowerCase().replace(/\s+/g, '');
		if (isSearchValueEmpty || normalizedMenuName.includes(searchValue)) {
			let highlightedMenuName = rawMenuName;
			if (!isSearchValueEmpty) {
				const matchedStr = getMatchedStr(trimmedValue, rawMenuName);
				if (matchedStr) {
					const regExp = new RegExp(escapeRegExp(matchedStr), 'gi');
					highlightedMenuName = rawMenuName.replace(
						regExp,
						`<strong style="color:#C31E39;">${matchedStr}</strong>`,
					);
				}
			}
			filteredMenuList.push({
				...menu,
				menuNm: highlightedMenuName,
			});
		}
	}

	menuList.value = filteredMenuList;
	focusIndex.value = filteredMenuList.length > 0 ? 0 : -1;
};
/**
 * @Function getMatchedStr 대소문자&공백 구분 없이 검색어와 매칭되는 문자열 반환
 * @param {string} 검색할 문자열
 * @param {string} 검색 대상 문자열
 * */
function getMatchedStr(str1, str2) {
	let resStr = '';
	let str1Index = 0;
	let i = 0;

	while (i < str2?.length) {
		const char1 = str1[str1Index].toLowerCase();
		const char2 = str2[i].toLowerCase();

		if (char2 === char1) {
			resStr += str2[i];
			str1Index++;
			i++;
		} else if (str2[i] === ' ') {
			resStr += str2[i];
			i++;
		} else {
			str1Index = 0;
			resStr = '';
			i++;
		}

		if (str1Index === str1?.length) {
			return resStr;
		}
	}

	return resStr;
}

async function reloadFxMenusEventHandler() {
	setTimeout(() => {
		leafMenuList = useSystemStore().leafMenuList ?? [];
		menuList.value = leafMenuList;
		refInput.value.value = '';
	}, 500);
}

onMounted(() => {
	window.addEventListener('reloadFxMenus', reloadFxMenusEventHandler);
});

onBeforeUnmount(() => {
	window.removeEventListener('reloadFxMenus', reloadFxMenusEventHandler);
});
</script>

<style lang="scss" scoped>
.search-section {
	position: relative;
	width: 240px;
	height: 32px;
	padding: 2px 16px;
	border-radius: 16px;
	background-color: var(--search-section-bg);
	border: 1px solid var(--search-section-bg);
	input {
		padding: 0 24px 0 0;
		border: 0;
		background-position: right center;
		background-repeat: no-repeat;
		background-size: 24px 24px;
		background-color: transparent;
		background-image: url(/images/ico-header-search-zoom.svg);
	}
	&:has(.open) {
		background-color: var(--search-section-bg);
		border-color: var(--search-section-border);
	}
}
:root:has(.darkMode) {
	.search-section {
		input {
			background-image: url(/images/ico-header-search-zoom-dark.svg);
		}
	}
}
.dropdown {
	position: absolute;
	display: none;
	border: 1px solid var(--search-dropdown-border);
	border-radius: 4px;
	margin-top: 4px;
	animation: fadeIn 0.3s linear;
	transform-origin: 0 0;
	background-color: var(--search-dropdown-bg);
	width: 100%;
	z-index: 3;
	left: 0;
	max-height: 240px;
	overflow-y: auto;
	&.open {
		display: block;
	}
	li {
		display: flex;
		align-items: flex-start;
		gap: 10px;
		font-size: 13px;
		padding: 8px;
		border-radius: 0;
		color: var(--basic-text);
		> img {
			transform: scale(0.8);
		}
		&:first-child {
			border-radius: 4px 4px 0 0;
		}
		&:last-child {
			border-radius: 0 0 4px 4px;
		}
		&:hover,
		&.active {
			background-color: rgb(196, 220, 255, 0.2);
		}
	}
}
</style>
