<!--------------------------------------------------------------------
    파일   : LoginPop06.vue
    작성   : CJ Developer
    설명   : 새로운 비밀번호 입력 팝업
---------------------------------------------------------------------->

<template>
	<p>{{ t('LBL.CM.LGN.019') }}</p>
	<div class="logconwrap">
		<FInput
			id="pswd"
			v-model="pswd"
			:v-validate:required|func="validatePwd"
			:label="t('LBL.CM.PASSWORD.001')"
			name="pswd"
			class="pwdchg"
			type="password"
		/>
		<FInput
			id="pswdChk"
			v-model="pswdChk"
			:v-validate:required|func="validatePwd"
			:label="t('LBL.CM.PASSWORD.002')"
			name="pswdChk"
			class="pwdchg"
			type="password"
		/>

		<p class="fs-14 txtC">
			{{ t('LBL.CM.LGN.020') }}
		</p>
	</div>
</template>

<script setup>
const { t } = useI18n();

const props = defineProps({
	pswdValue: {
		type: [String, null],
	},
});
const emit = defineEmits(['update:pswdValue', 'update:pswdChkValue']);
const pswd = computed({
	get() {
		return props.pswdValue;
	},
	set(value) {
		emit('update:pswdValue', value);
	},
});

const pswdChk = computed({
	get() {
		return props.pswdChkValue;
	},
	set(value) {
		emit('update:pswdChkValue', value);
	},
});
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
		&.txtC {
			text-align: center;
		}
	}
}
.logconwrap {
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	.pwdchg {
		width: 100%;
	}
}
</style>
<style lang="scss">
.pwdchg {
	label {
		font-size: 14px;
		margin-bottom: 10px;
	}
	> .input-item {
		border-radius: 12px !important;
		> .target > input {
			padding: 16px 16px 16px !important;
			border-radius: 12px;
			font-size: 16px;
		}
	}
}
</style>
