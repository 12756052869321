<template>
	<div class="login-page">
		<div class="login-content-left">
			<img alt="FILA" :src="FilaCi" width="110" />
			<div class="login-desc">
				<h1>FILA<br />Point of Sale<br />System</h1>
				<!-- <h2>{{ t('MSG.CM.FILALOGIN.001') }}</h2> -->
			</div>
			<div class="login-help">
				<div class="login-help-info">
					<h3>Help Desk</h3>
					<dl>
						<dt>{{ t('MSG.CM.SYSINQ.001', ['02)3470-9528']) }}</dt>
						<dd></dd>
					</dl>
				</div>
				<div v-if="compSelectValue == 'D002'" class="login-help-button">
					<div class="login-help-item">
						<FButton
							text
							icon="ico-32-help"
							class="size60"
							onclick="location.href = 'https://113366.com/FILA'"
						/>
						<div>{{ t('LBL.CM.REMOTESUP.001') }}</div>
					</div>
					<div class="login-help-item">
						<FButton
							text
							icon="ico-32-remote"
							class="size60"
							@click="openGdePop"
						/>
						<div>{{ t('LBL.CM.REMOTESUPREQ.001') }}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="login-content-right">
			<div class="login-form-wrap">
				<div class="login-form-top">
					<h1>Login</h1>
					<div class="login-lang">
						<h5>Language</h5>
						<UserSetting mode="locale" from="login" />
					</div>
				</div>
				<div class="login-form-bottom">
					<form @submit.prevent="fnLogin">
						<div class="user-corp">
							<FSelect
								id="compSelectValue"
								v-model="compSelectValue"
								:options="cmCdList"
								:placeholder="t('LBL.CM.SELECT.002')"
								:label="t('LBL.CM.COMPANY.001')"
								class="h64"
								click-only
							/>
						</div>
						<div class="login-form-item">
							<FInput
								id="username"
								ref="refUsername"
								v-model="loginParam.username"
								type="text"
								:placeholder="t('LBL.CM.ID.001')"
								:label="t('LBL.CM.ID.001')"
								class="h64"
								@keypress.enter.prevent="onClickLogin"
							/>
						</div>
						<div class="login-form-item">
							<FInput
								id="password"
								ref="refPassword"
								v-model="loginParam.password"
								type="password"
								:placeholder="t('LBL.CM.PASSWORD.001')"
								:label="t('LBL.CM.PASSWORD.001')"
								class="h64"
								@keypress.enter.prevent="onClickLogin"
							/>
						</div>
						<div class="login-form-check">
							<div class="login-form-check-item">
								<FCheckbox
									id="multipleValue"
									v-model="rememberId"
									:options="options"
									class="login-main"
									no-label
								/>
							</div>
							<FButton text @click="openPwdPop">{{
								t('LBL.CM.RESETPASSWORD.001')
							}}</FButton>
						</div>

						<FButton primary type="submit" @click="onClickLogin">
							{{ t('LBL.CM.LOGIN.001') }}
						</FButton>
					</form>
				</div>
			</div>
			<!-- ERP는 대상 제외로 주석 -->
			<div class="login-policy-wrap">
				<FButton text @click="openPlcyPop">{{
					t('LBL.CM.PINFOPLCY.001')
				}}</FButton>
				<div class="login-policy-desc">
					{{ t('LBL.CM.PERMISSION.001') }}
				</div>
				<div class="login-policy-copy">
					ⓒ 2024 Fila Holdings Corp. All rights reserved.
				</div>
			</div>
		</div>
	</div>
	<PinfoPlcyPop
		id="pinfoPlcyPop"
		ref="pinfoPlcyPop"
		v-model:compCdValue="compSelectValue"
		v-model:pinfoPlcyTrgtCdValue="pinfoPlcyTrgtCd"
	/>
	<RemoteSprtGdePop id="remoteSprtGdePop" ref="remoteSprtGdePop" />
	<LoginPwdPop
		id="loginPwdPop"
		ref="loginPwdPop"
		v-model:compCdValue="compSelectValue"
		v-model:compNmValue="compSelectNmValue"
	/>
	<LoginCertPop
		id="loginCertPop"
		ref="loginCertPop"
		v-model:lgnIdValue="loginParam.username"
		v-model:emlAddrValue="emlAddr"
		v-model:cpnoValue="cpno"
		v-model:userIdValue="userId"
		v-model:compCdValue="compSelectValue"
		@lterm-clicked="onClickLterm"
	/>
</template>

<script setup>
import hex from 'crypto-js/enc-hex';
// import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha256';
import VueCookies from 'vue-cookies';
import commonUtils from '@fila/lib/utils/commonUtils';

import apiCall from '@/plugins/apiCall';
import { useMessage } from '@fila/lib/plugins/message/message';

import useSystemStore from '@/stores/systemStore';
import { MENU_INFO } from '@fila/lib/utils/constCommon';
import FilaCi from '/images/img-fila-ci-w.png';

import UserSetting from '@/layout/header/UserSetting.vue';

import PinfoPlcyPop from '@/components/popup/PinfoPlcyPop.vue';
import RemoteSprtGdePop from '@/components/popup/RemoteSprtGdePop.vue';
import LoginPwdPop from '@/components/popup/LoginPwdPop.vue';
import LoginCertPop from '@/components/popup/LoginCertPop.vue';

import UAParser from 'ua-parser-js';

import { v4 as uuidv4 } from 'uuid';

const loginParam = ref({
	username: '',
	password: '',
	compCd: '',
	userSeCd: '',
});

const pinfoPlcyTrgtCd = ref('03');
const pinfoPlcyPop = ref(null);
const remoteSprtGdePop = ref(null);
const loginPwdPop = ref(null);
const loginCertPop = ref(null);

const refUsername = ref(null);
const refPassword = ref(null);
const rememberId = ref([]);
const message = useMessage();
const { t } = useI18n();
const cmCdList = ref([]);

const userId = ref('');
const cpno = ref('');
const emlAddr = ref('');
const accessToken = ref('');
const refreshToken = ref('');

const compSelectNmValue = computed(
	() =>
		cmCdList.value?.filter(cmCd => cmCd.dtlCd === compSelectValue.value)[0]
			?.dtlCdNm ?? '',
);

const compSelectValue = ref('D002');
const userSeSelectValue = ref('02');

onMounted(() => {
	if (VueCookies.get('rememberedUserId')) {
		rememberId.value = ['001']; // 아이디 저장 checkbox 활성화
		loginParam.value.username = VueCookies.get('rememberedUserId');
	}
	let params = {
		cmCd: 'COMP_CD',
		compCd: 'D001',
		langCd: 'kr',
	};
	apiCall.get('CM_API/cm/global/common/public/cmCdList', params).then(res => {
		cmCdList.value = res.data.data;
		return;
	});
});

/**
 * #################### 로그인 ####################
 */
const router = useRouter();

function onClickLogin() {
	let param = { ...loginParam.value };
	param.compCd = compSelectValue.value;
	param.userSeCd = userSeSelectValue.value;

	// param.password = hex.stringify(sha256('Styl API'));
	param.password = hex.stringify(sha512(param.password));

	apiCall.post('CM_API/cm/global/common/auth/login', param).then(async res => {
		switch (res.data.statusCode) {
			case 10:
				await message.showAlert({ message: res.data.statusMessage });
				return;
			case 50:
				await message.showAlert({ message: res.data.statusMessage });
				return;
			default:
				break;
		}
		const ltermCertKey = localStorage.getItem('ltermCertKey');

		refreshToken.value = res.data.data.refreshToken;
		accessToken.value = res.data.data.accessToken;

		const ltermCertParam = {
			lgnId: loginParam.value.username,
			compCd: compSelectValue.value,
			ltermCertKey: ltermCertKey,
		};

		await lgnAfter(); // 제거 예정

		//장기인증
		// if (!commonUtils.gfn_isEmpty(ltermCertKey)) {
		// 	const chkLtermCertYn = await chkLtermCert(ltermCertParam);

		// 	if (chkLtermCertYn) {
		// 		await lgnAfter();
		// 	} else {
		// 		await apiCall
		// 			.post('CM_API/cm/global/common/auth/findCertMthd', ltermCertParam)
		// 			.then(res => {
		// 				emlAddr.value = res.data.data.emlAddr;
		// 				cpno.value = res.data.data.cpno;
		// 				userId.value = res.data.data.userId;
		// 			});
		// 		loginCertPop.value.fnOpen();
		// 	}
		// } else {
		// 	await apiCall
		// 		.post('CM_API/cm/global/common/auth/findCertMthd', ltermCertParam)
		// 		.then(res => {
		// 			emlAddr.value = res.data.data.emlAddr;
		// 			cpno.value = res.data.data.cpno;
		// 			userId.value = res.data.data.userId;
		// 		});
		// 	loginCertPop.value.fnOpen();
		// }
	});
}

async function chkLtermCert(param) {
	try {
		const res = await apiCall.post(
			'CM_API/cm/global/common/auth/getLtermCert',
			param,
		);
		return res.data;
	} catch (error) {
		console.error('Error fetching LtermCert:', error);
		throw error;
	}
}

async function onClickLterm(chkLterm) {
	if (chkLterm == 'Y') {
		const parser = new UAParser();
		const result = parser.getResult();
		const ltermCertKey = uuidv4();

		localStorage.setItem('ltermCertKey', ltermCertKey);
		const param = {
			userId: userId.value,
			ltermCertKey: ltermCertKey,
			brwsNm: result.browser.name,
		};
		await apiCall
			.post('CM_API/cm/global/common/auth/saveLtermCert', param)
			.then(async res => {
				await lgnAfter();
			});
	} else {
		await lgnAfter();
	}
}

async function lgnAfter() {
	// 아이디 기억 체크 상태일 때, 로그인 성공 시 CooKie에 저장
	useSystemStore().g_timer =
		(await commonUtils.gfn_getCmCd('SYS_TMOT_CD', '02'))[0]?.rmrk1 ?? 30;

	if (rememberId.value.includes('001')) {
		VueCookies.set('rememberedUserId', loginParam.value.username, {
			expires: '1y',
		});
	}
	const ltermCertKey = localStorage.getItem('ltermCertKey');
	await clearLocalStorage();
	localStorage.setItem('ltermCertKey', ltermCertKey);

	await useSystemStore().login(accessToken.value, refreshToken.value);
	await useSystemStore().load();

	nextTick(() => {
		router.push({
			path: MENU_INFO.HOME_PATH,
			// hash: '#' + uuidv4()
		});
	});
}

function openPlcyPop() {
	pinfoPlcyPop.value.fnOpen();
}

function openGdePop() {
	remoteSprtGdePop.value.fnOpen();
}

function openPwdPop() {
	loginPwdPop.value.fnOpen();
}

function clearLocalStorage() {
	return new Promise((resolve, reject) => {
		try {
			localStorage.clear();
			resolve();
		} catch (error) {
			reject(error);
		}
	});
}

const options = [
	{
		label: t('LBL.CM.SAVEID.001'),
		value: '001',
	},
];
</script>

<style lang="scss" scoped>
.login-page {
	display: flex;
	height: 100vh;
	background-repeat: no-repeat;
	background: url(/images/img-login-bg-re.png) no-repeat;
	background-size: cover;
	justify-content: space-between;
	.login-content-right {
		display: flex;
		flex-direction: column;
		background: url(/images/img-login-bg-shape.png) no-repeat left;
		background-size: cover;
		justify-content: center;
		align-items: flex-end;
		padding-right: 80px;
		padding-bottom: 10px;
		min-width: 870px;
		//신규
		overflow: auto;
		.login-form-wrap {
			min-width: 500px;
			max-width: 500px;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.login-form-top {
				color: #000;
				font-weight: 800;
				display: flex;
				justify-content: space-between;
				h1 {
					font-size: 48px !important;
				}
				.login-lang {
					display: flex;
					align-items: center;
					gap: 24px;
					.user-items {
						display: flex;
						align-items: center;
						gap: 24px;
						label {
							position: relative;
							display: block;
							width: 24px;
							height: 24px;
							cursor: pointer;
							input {
								width: 24px;
								height: 24px;
								opacity: 0;
							}
							&::after {
								content: '';
								display: block;
								width: 24px;
								height: 24px;
								position: absolute;
								top: 0;
								left: 0;
								border-radius: 24px;
							}
							&:has(:checked) {
								&::after {
									background-position:
										right bottom,
										right bottom;
								}
							}
							&::after {
								background-repeat: no-repeat;
								background-position:
									-24px bottom,
									right bottom;
							}
							&.kr {
								&::after {
									background-image: url(/images/ico-24-user-mode-check.svg),
										url(/images/img-flag-kr.png);
								}
							}
							&.us {
								&::after {
									background-image: url(/images/ico-24-user-mode-check.svg),
										url(/images/img-flag-us.png);
								}
							}
							&.cn {
								&::after {
									background-image: url(/images/ico-24-user-mode-check.svg),
										url(/images/img-flag-cn.png);
								}
							}
						}
					}
				}
			}
			.login-form-bottom {
				form {
					display: flex;
					flex-direction: column;
					gap: 20px;
					margin-top: 40px;
					.login-form-check {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.login-form-check-item {
							display: flex;
							label {
								font-size: 14px;
							}
						}
					}
					button {
						margin-top: 30px;
						&.primary {
							height: 70px;
							font-size: 24px;
							font-weight: bold;
							border-radius: 40px;
						}
						&.text-only {
							text-decoration-line: underline;
						}
					}
					.login-form-check {
						button {
							margin-top: 0;
						}
					}
				}
			}
		}
		.login-policy-wrap {
			min-width: 500px;
			max-width: 500px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 14px;
			gap: 20px;
			margin-top: 50px;
			.login-policy-desc,
			.login-policy-copy {
				color: #777777;
			}
			button {
				&.text-only {
					text-decoration-line: underline;
					margin-right: auto;
				}
			}
		}
	}
	.login-content-left {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 80px;
		.login-desc {
			h1 {
				font-size: 64px !important;
				line-height: 1;
				color: #fff;
			}
			h2 {
				font-size: 20px !important;
				font-weight: normal !important;
				margin-top: 24px;
				color: #fff;
			}
		}
		.login-help {
			display: flex;
			align-items: flex-end;
			gap: 80px;
			transition: all 0.3s;
			.login-help-info {
				h3 {
					font-size: 24px !important;
					color: #fff;
				}
				dl {
					display: flex;
					dt,
					dd {
						font-size: 16px;
						color: #fff;
					}
				}
			}
			.login-help-button {
				display: flex;
				gap: 27px;
				.login-help-item {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					color: #fff;
					button {
						border-radius: 50%;
					}
				}
			}
		}
	}
}
@media (min-height: 676px) and (max-height: 765px) {
	.login-page {
		.login-content-left {
			padding: 50px 50px 50px 80px;
		}
		.login-content-right {
			.login-form-wrap {
				.login-form-bottom {
					form {
						margin-top: 15px;
						button {
							margin-top: 5px;
						}
					}
				}
			}
			.login-policy-wrap {
				margin-top: 35px;
				gap: 15px;
			}
		}
	}
}
@media all and (max-height: 675px) {
	.login-page {
		.login-content-left {
			padding: 40px 0 40px 80px;
			.login-help {
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
			}
		}
		.login-content-right {
			justify-content: flex-start;
			padding: 25px 80px 40px 0;
			.login-form-wrap {
				.login-form-bottom {
					form {
						margin-top: 25px;
						button {
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.login-form-bottom {
	.fSelect {
		&.h64 {
			input {
				height: 60px;
				border-radius: 12px;
				font-size: 16px;
				background: #fff url(/images/ico-16-login-select.svg) no-repeat right
					20px center;
			}
			> div {
				> div {
					> img {
						display: none;
					}
				}
			}
		}
	}
	.input-box {
		&.h64 {
			.input-item {
				height: 60px;
				border-radius: 12px;
				background-color: #fff;
				input {
					border-radius: 12px;
				}
			}
		}
	}
}
body:has(.login-page) {
	.dropdown {
		border-radius: 12px !important;
		li {
			font-size: 16px !important;
		}
	}
}
</style>
