<template>
	<FLayerPopup
		ref="refPopup"
		:title="t('LBL.CM.SYSTMOT.001')"
		width="480"
		height="320"
		@close="onClickLgnOut"
	>
		<template #header />

		<template #body>
			<div class="gray-box">
				<p>
					{{ t('MSG.CM.SYSTMOT.001') }}<br />
					{{ t('MSG.CM.SYSTMOT.002') }}<br />
					{{ t('MSG.CM.SYSTMOT.003') }}
				</p>
			</div>
			<p class="txtC">{{ t('MSG.CM.SYSTMOT.004') }}</p>
			<div class="button-area">
				<FButton class="large" @click="onClickLgnOut">{{
					t('LBL.CM.LOGOUT.001')
				}}</FButton>
				<FButton class="large" primary @click="onClickLgnIn">{{
					t('LBL.CM.LOGIN.002')
				}}</FButton>
			</div>
		</template>

		<template #footer />
	</FLayerPopup>
</template>

<script setup>
import useSystemStore from '@/stores/systemStore';
import { MENU_INFO } from '@fila/lib/utils/constCommon';

const refPopup = shallowRef(null);

const { t } = useI18n();

function onClickLgnIn() {
	refPopup.value.close();
}

function onClickLgnOut() {
	useSystemStore().logout();
	refPopup.value.close();
}

//팝업오픈
const fnOpen = async () => {
	refPopup.value.open();
};

function getShow() {
	return refPopup.value.getShow();
}

defineExpose({
	fnOpen,
	getShow,
});
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.txtC {
		text-align: center;
	}
}
.gray-box {
	border-radius: 12px;
	background-color: #f4f5f6;
	padding: 24px 0;
	p {
		text-align: center;
		color: var(--heading-text);
	}
}
.button-area {
	display: flex;
	justify-content: center;
	gap: 8px;
}
:root:has(.darkMode) {
	.gray-box {
		background-color: var(--form-search-bg);
	}
}
</style>
