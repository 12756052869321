<template>
	<div v-click-outside="hiddenList" class="tab-wrap">
		<!-- D&D -->
		<div ref="refTabWrapper" class="tab-item-wrap">
			<VueDraggableNext :list="tabs" :move="onMoveCallback" @end="onMoveEnd">
				<div
					v-for="tab in tabs"
					:id="'tab-' + tab?.uuid ?? tab.title"
					:key="tab.path"
					class="tab-item"
					@click="onClickTabMenu(tab)"
				>
					<FButton
						v-if="tab.title === 'home'"
						:id="'btn-' + tab.uuid"
						class="size40"
						:class="[tab.current ? 'on' : '']"
						text
						icon="ico-16-home"
					>
					</FButton>
					<span v-else :class="[tab.current ? 'on' : '']" :title="tab.title">
						{{ tab.title }}
					</span>
					<FButton
						v-if="tab?.fxYn !== 'Y'"
						:id="'btn-' + tab.uuid"
						class="tab-close size12"
						text
						icon="ico-12-btnTabClose"
						@click.stop="closeTab(tab)"
					/>
				</div>
			</VueDraggableNext>
		</div>

		<!-- 탭 우측 버튼 -->
		<div ref="refTabButton" class="tab-icon-wrap">
			<FButton
				text
				icon="ico-16-newWindow"
				class="size40"
				@click="onClickExpand"
			/>
			<FButton
				ref="refLeftButton"
				text
				icon="ico-16-leftArrow"
				class="size40"
				@click="onClickBtnMove('left')"
			/>
			<FButton
				id="rightBtn"
				text
				icon="ico-16-rightArrow"
				class="size40"
				@click="onClickBtnMove('right')"
			/>
			<FButton
				text
				icon="ico-16-tabClose"
				class="size40"
				@click="closeAllTab"
			/>
			<FButton text icon="ico-16-tabMore" class="size40" @click="showList" />
		</div>
		<!-- Tab list -->
		<ul
			v-show="isDrop"
			ref="refDropdown"
			class="tabDropdown"
			style="margin-top: 34px; min-width: 120px"
		>
			<li
				v-for="(tab, index) in tabs"
				:id="'drop-' + tab.title + index"
				:key="'drop-' + tab.path"
				@click.prevent="onClickTabMenu(tab)"
			>
				{{ tab.title === 'home' ? 'Home' : tab.title }}
			</li>
		</ul>
	</div>
</template>

<script setup>
import _ from 'lodash-es';
import { VueDraggableNext } from 'vue-draggable-next';

import apiCall from '@/plugins/apiCall';

import { useMessage } from '@fila/lib/plugins/message/message';
import useSystemStore from '@/stores/systemStore';
import { MENU_INFO } from '@fila/lib/utils/constCommon';

import commonUtils from '@fila/lib/utils/commonUtils';
import systemStorage from '@fila/lib/shared/systemStorage';
import { nextTick } from 'vue';
import useMenuStore from '@/stores/menuStore';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const menuStore = useMenuStore();
const menus = ref(menuStore.menuTree.children); // 전체 메뉴

const systemStore = useSystemStore();

const tabs = computed(() => {
	return systemStore.openedTabs;
});
const refTabWrapper = ref(null);
const refTabButton = ref(null);

const props = defineProps({
	// Cache된 Tab Component
	cachedTabs: {
		type: Map,
		default: new Map(),
	},
});

/**
 * 탭 메뉴 위치 이동
 * @param {*} type left: 좌측 이동, right: 우측 이동
 */
function onClickBtnMove(type) {
	if (type === 'left') {
		refTabWrapper.value.scrollTo({ left: 0, behavior: 'smooth' });
	} else {
		refTabWrapper.value.scrollTo({ left: 10000, behavior: 'smooth' });
	}
}

/**
 * 탭 메뉴 클릭으로 이동
 * @param {*} selectedTab 선택한 탭의 title(=메뉴명), path(=소문자), name(=component), page 정보
 */
function onClickTabMenu(selectedTab) {
	openTabMenu(selectedTab);
	// commonUtils.gfn_debounce(() => openTabMenu(selectedTab), 1000);
}

/**
 * 선택한 탭 변경
 * @param {*} selectedTab 선택한 탭의 title(=메뉴명), path(=소문자), name(=component), page 정보
 */
function currentTab(selectedTab) {
	tabs.value?.map(t => {
		_.isEqual(t?.menuId, selectedTab?.menuId)
			? (t.current = true)
			: (t.current = false);
	});
}

/**
 * 탭 메뉴 복사
 */
// function cloneTabs() {
// 	tabs.value = systemStore.openedTabs;
// }

function onMoveEnd(e) {
	try {
		const tab = e.item._underlying_vm_;

		if (tab.fxYn === 'Y') {
			const tabList = tabs.value
				.filter(t => t.fxYn === 'Y' && t.title != 'home')
				.map(t => {
					return { fxMenuId: t.menuId, rowStatus: 'U' };
				});
			apiCall.post('CM_API/cm/global/common/main/fxmenu/save', tabList);
		}
	} catch (e) {
		return false;
	}
}

function onMoveCallback(evt) {
	try {
		// 고정 탭 정렬 순서 api call
		if (
			!(
				evt.draggedContext.element.title === 'home' ||
				evt.relatedContext.element.title === 'home'
			) &&
			evt.draggedContext.element?.fxYn === 'Y' &&
			evt.relatedContext.element?.fxYn === 'Y'
		) {
			return true;
			// TO-DO 현재 리스트 반환

			return true;
		} else if (
			evt.draggedContext.element.title === 'home' ||
			evt.relatedContext.element.title === 'home' ||
			(evt.draggedContext.element?.fxYn === 'Y' &&
				evt.relatedContext.element?.fxYn === 'N') ||
			(evt.draggedContext.element?.fxYn === 'N' &&
				evt.relatedContext.element?.fxYn === 'Y')
		) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		return false;
	}
}

const message = useMessage();

/**
 * 전체 탭 닫기
 */
function closeAllTab() {
	if (systemStore.openedTabs.length === 1) {
		message.showAlert({
			message: t('MSG.CM.VAL.142'),
		});
		return;
	}

	let isModified = { valid: false, tab: null };

	systemStore.openedTabs.forEach(tab => {
		if (isModified.valid) {
			return;
		}
		// 탭 별로 변경 사항이 있는지 확인
		isModified.valid = isTabModified(tab.component);
		isModified.tab = tab ?? {};
	});

	// 메뉴가 존재하는 경우
	message.showConfirm({
		message: isModified.valid
			? // 변경된 내용이 있습니다. ({0}) 그래도 탭을 닫으시겠습니까?
				t('MSG.CM.VAL.135', [isModified.tab.title])
			: // 전체 탭을 닫으시겠습니까?
				t('MSG.CM.VAL.136'),
		onConfirm: () => {
			systemStore.setFxTabs(true);
			router.push({
				path: '/home',
			});
			message.hide();
		},
		onCancel: () => {
			if (isModified.valid && !isModified.tab.current) {
				// 비활성화된 Tab && 변경된 그리드일 때, tab focus
				openTabMenu({ path: isModified.tab.path });
			}
		},
	});
}

/**
 * page refresh 기능
 */

// 성능 개선을 위해 탭 UUID의 watch를 제거하고 route 객체만 유지
watch(
	route,
	() => {
		//탭 위치 지정
		nextTick(() => {
			moveTabPosition();
		});
	},
	{ flush: 'post' },
);

/**
 * 리플레시 탭
 * KeepAlive cache 변환 작업
 */
// function refreshTab() {
// 	// 현재 탭 찾기 (리플레시 탭 타겟)
// 	const tabIndex = systemStore.openedTabs.findIndex(
// 		item => item.path === route.path && item.current,
// 	);

// 	// page 구분 값 설정
// 	const refreshPage = uuidv4();
// 	systemStore.openedTabs[tabIndex].page = refreshPage;

// 	// [중복 탭] 수정
// 	// 리플레시 탭 실행
// 	router.push({
// 		name: systemStore.openedTabs[tabIndex].component,
// 		params: { page: refreshPage },
// 		// query 사용 시
// 	});
// }

const debouncedCheckOpenTabUrl = commonUtils.gfn_debounce(
	checkOpenTabUrl,
	1000,
);
/**
 * 탭 메뉴로 이동
 * @param {*} selectedTab
 */
async function openTabMenu(selectedTab) {
	// checkOpenTabUrl 함수 자체를 전달하고, gfn_debounce로부터 반환된 함수를 호출합니다.

	debouncedCheckOpenTabUrl(); // debounce 처리된 함수를 호출합니다.

	// 성능 개선을 위해 코멘트 아웃
	// systemStore.setNowUUID(selectedTab.uuid);

	// 라우터로 메뉴ID를 전달하기 위해서 moveTab 정보를 기록한다.
	systemStorage.set(
		'moveTab',
		{
			path: selectedTab.path,
			menuId: selectedTab.menuId,
		},
		true, // 주의! 이벤트를 발생시키지 않는다.
	);

	nextTick(() => {
		router.push({
			path: selectedTab.path,
			hash: selectedTab.menuId !== 'HOME' ? `#${selectedTab.menuId}` : '',
		});
		// moveTabPosition();
	});

	// 홈의 경우에는 [최근 본 메뉴] 추가 작업을 하지 않는다.
	if (selectedTab.menuId === 'HOME') {
		return;
	}

	let findItem = await systemStorage.getMenuById(selectedTab.menuId);

	let childrenData;
	if (menus.value[0]?.children[1]?.children[0]?.children) {
		childrenData = [...menus.value[0].children[1].children[0].children];
	} else {
		childrenData = [];
	}

	if (commonUtils.gfn_isEmpty(childrenData)) {
		childrenData = [findItem];
	} else {
		let index = childrenData.findIndex(item => item.menuId === findItem.menuId);
		if (index !== -1) {
			childrenData.splice(index, 1);
		}
		childrenData.unshift(findItem);
		if (childrenData.length > 10) {
			childrenData.pop();
		}
	}
	menus.value[0].children[1].children[0].children = childrenData;
}

function checkOpenTabUrl() {
	if (commonUtils.gfn_isNotEmpty(systemStore.currentMenu)) {
		if (systemStore.currentMenu?.menuPathNm !== window.location.pathname) {
			if (
				!window.location.pathname === '/error' &&
				!systemStore.currentMenu?.menuPathNm === '/error'
			) {
				let currentTab = tabs.value.find(data => data.current === true);
				if (currentTab) {
					// 성능 개선을 위해 코멘트 아웃
					// systemStore.setNowUUID(currentTab.uuid);
					nextTick(() => {
						router.push({
							path: currentTab.path,
						});
						// 성능 개선을 위해 코멘트 아웃
						// moveTabPosition();
					});
				}
			}
		}
	}
}

/**
 * 탭 메뉴 삭제
 */
function closeTab(selectedTab) {
	// HOME 삭제 불가
	if (selectedTab.path === MENU_INFO.HOME_PATH) {
		return;
	}

	// AUIGrid 변경 사항 여부
	const isModified = isTabModified(selectedTab.component);

	if (isModified) {
		// 변경 사항 존재 CASE
		message
			.showConfirm({
				// 변경된 내용이 있습니다. 재조회 그래도 탭을 닫으시겠습니까?
				message: t('MSG.CM.VAL.135', selectedTab.title),
				onConfirm: () => {
					return true;
				},
				onCancel: () => {
					return false;
				},
			})
			.then(res => {
				if (res) {
					deleteTab(selectedTab);
				}
			});
		return;
	}

	deleteTab(selectedTab);
}

function deleteTab(selectedTab) {
	// 마지막 메뉴 확인(삭제 전)
	const isLastTab = _.isEqual(selectedTab, tabs.value[tabs.value.length - 1]);

	// 탭 삭제
	let findIndex = -1;
	for (let i = 0; i < tabs.value.length; i++) {
		const tab = tabs.value[i];
		if (tab.uuid === selectedTab.uuid) {
			findIndex = i;
			break;
		}
	}

	tabs.value.splice(findIndex, 1);
	// 현재 메뉴 확인
	let currentTab = tabs.value.find(data => data.current === true);
	if (_.isEqual(selectedTab, currentTab)) {
		// 메뉴 이동
		if (tabs.value.length > 0) {
			if (isLastTab) {
				// 마지막 Tab (앞으로 이동)
				openTabMenu(tabs.value[tabs.value.length - 1]);
			} else {
				// 마지막 Tab 아닌 경우 (이동 없이 뒤에 메뉴)
				openTabMenu(tabs.value[findIndex]);
			}
		}
	} else {
		if (isLastTab) {
			// 마지막 Tab (홈 메뉴)
			openTabMenu(tabs.value[tabs.value.length - 1]);
		} else {
			if (currentTab === undefined || currentTab === '') {
				// 마지막 Tab 아닌 경우 (이동 없이 뒤에 메뉴)
				openTabMenu(tabs.value[findIndex]);
			} else {
				// 마지막 Tab 아닌 경우 (선택된 메뉴로 유지)
				openTabMenu(tabs.value[tabs.value.indexOf(currentTab)]);
			}
		}
	}
}

/**
 * 탭 이동 위치
 */
function moveTabPosition() {
	let findMenu = systemStore.isOpenTabByUUID(systemStore.nowUUID);
	currentTab(findMenu);
	let activeElement = refTabWrapper.value?.querySelector(
		'#btn-' + (findMenu?.uuid ?? ''),
	);

	if (activeElement) {
		activeElement?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
	} else {
		setTimeout(() => {
			activeElement = refTabWrapper.value?.querySelector(
				'#btn-' + (findMenu?.uuid ?? ''),
			);
			activeElement?.scrollIntoView({ inline: 'center', behavior: 'smooth' });
		}, 1000);
	}
}

/**
 * 그리드가 변경된 탭이 있는지 검사
 * @param selectedTabNm 검사할 tab의 component 명
 **/
function isTabModified(selectedTabNm) {
	let foundTab = {};
	let isModified = false;

	// cached 탭 중 삭제 대상 탭 탐색
	props.cachedTabs.forEach((value, key) => {
		if (selectedTabNm === value?.type?.__name) {
			foundTab = value;
			return;
		}
	});

	// 삭제 대상 탭 탐색 성공
	if (commonUtils.gfn_isNotEmpty(foundTab)) {
		const gridRefs = foundTab?.component?.refs ?? [];

		Object.keys(gridRefs).forEach(key => {
			if (isModified) {
				return;
			}

			const gridRef = gridRefs[key];
			// refs에서 Grid의 ref 탐색
			if ('pid' in gridRef && gridRef.pid.startsWith('#aui-grid-wrap')) {
				// 변경 여부 확인
				isModified = gridRef?.isModified() ?? false;

				// 변경 내용 존재 CASE
			}
		});
	}
	return isModified;
}

/**
 * Expand Screen
 */
function onClickExpand() {
	refTabButton.value.classList.toggle('expand');
	window.dispatchEvent(new Event('resize'));
}

/**
 * Tab List DropDown
 * */
const isDrop = ref(false);
const refDropdown = ref(null);

function hiddenList() {
	if (isDrop.value) {
		isDrop.value = false;
	}
}

function showList() {
	refDropdown.value?.focus();
	isDrop.value = !isDrop.value;
}

function storageEventHandler() {
	const moveTab = systemStorage.get('moveTab');
	if (moveTab) {
		router.push({
			path: moveTab.path,
			hash: moveTab.menuId !== 'HOME' ? `#${moveTab.menuId}` : '',
			menuId: moveTab.menuId,
			state: { data: moveTab.params },
		});
	}
}

function reloadFxMenusEventHandler() {
	systemStore.setFxTabs();
}

onMounted(() => {
	window.addEventListener('storage', storageEventHandler);
	window.addEventListener('reloadFxMenus', reloadFxMenusEventHandler);

	// cloneTabs();
	moveTabPosition();
});

onBeforeUnmount(() => {
	window.removeEventListener('storage', storageEventHandler);
	window.removeEventListener('reloadFxMenus', reloadFxMenusEventHandler);
});

// 현재 탭을 닫는다.
const closeCurrentTab = () => {
	const currentTab = tabs.value?.find(it => it.current);
	if (currentTab) {
		closeTab(currentTab);
	}
};

const closeSelectTab = (menuId, isMove) => {
	let currentTab;
	if (menuId === 'current') {
		currentTab = tabs.value.find(data => data.current === true);
	} else {
		currentTab = tabs.value?.find(tab => tab.menuId === menuId);
	}

	if (currentTab) {
		if (isMove) {
			closeTab(currentTab);
		} else {
			let findIndex = -1;
			for (let i = 0; i < tabs.value.length; i++) {
				const tab = tabs.value[i];
				if (tab.uuid === currentTab.uuid) {
					findIndex = i;
					break;
				}
			}

			tabs.value.splice(findIndex, 1);
		}
	}
};

defineExpose({
	closeCurrentTab,
	closeSelectTab,
});
</script>

<style lang="scss" scoped>
.tab-wrap {
	position: relative;
	display: flex;
	justify-content: space-between;
	height: 500px;
	border: 1px solid var(--border-color);
	border-width: 1px 0;
	height: 34px; //간격수정
	button {
		width: 48px;
		height: 40px;
		border: 0;
		outline: 0;
		background-color: transparent;
		border-left: 1px solid #e5e5e5;
		padding: 0;
		&.tab-close {
			border-left: none;
		}
	}
	.tab-item-wrap {
		flex: 1;
		height: 33px; //간격수정
		overflow-x: hidden;
		overflow-y: hidden;
		> div {
			display: flex;
			height: 100%;
			.tab-item {
				position: relative;
				// min-width: 160px;
				height: 32px;
				line-height: 32px;
				border-left: 1px solid var(--maintab-border-right);
				text-align: center;
				cursor: pointer;
				// home tab
				&:first-child {
					min-width: 48px;
					width: 48px;
					border-left: 0;
					&:has(.on) {
						background-color: var(--maintab-first);
						&::after {
							background-color: var(--maintab-first-border);
						}
					}
					button {
						margin: 0 auto;
						background-color: transparent;
						border-radius: 0;
						border-left: none;
					}
					.tab-close {
						display: none;
					}
				}
				// tab name
				span {
					font-size: 15px;
					color: #777;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					display: block;
					cursor: pointer;
					padding: 0 20px;
				}
				&:last-child {
					border-right: 1px solid var(--maintab-border-right);
				}
				&:has(span) {
					padding: 0;
				}
				&:has(.on) {
					position: relative;
					border-color: var(--border-color);
					background-color: var(--maintab-on-bg);
					span {
						font-weight: bold;
						color: var(--btn-text);
					}
					& + .tab-item {
						border-left: 1px solid var(--border-color);
					}
					&::after {
						content: '';
						position: absolute;
						left: 0;
						bottom: -1px;
						width: 100%;
						height: 1px;
						background-color: var(--maintab-on);
					}
					&::before {
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translate(-50%, 0);
						content: '';
						width: 0;
						height: 0;
						border-top: 4px solid red;
						border-left: 4px solid transparent;
						border-right: 4px solid transparent;
					}
				}
				.tab-close {
					position: absolute;
					top: 0;
					right: 0;
					opacity: 0;
					width: 20px;
					height: 32px;
					padding-right: 0;
					&:hover {
						opacity: 1;
					}
				}
				button {
					height: 32px !important;
				}
			}
		}
	}
	.tab-icon-wrap {
		display: flex;
		button {
			border-left: 1px solid var(--user-section-border-color);
			border-radius: 0;
			height: 32px !important;
		}
	}
}
:root:has(.darkMode) {
	.tab-wrap {
		.tab-item-wrap {
			> div {
				.tab-item {
					&:has(.on) {
						span {
							font-weight: 400;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="scss">
//화면 확장
body:has(.expand) {
	.header-wrap,
	.aside-wrap {
		display: none !important;
	}
}
</style>
