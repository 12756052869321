<!--------------------------------------------------------------------
	파일   : CmCdPop.vue
	작성   : CJ Developer
	설명	 : [공통] 공통코드 조회 팝업 컴포넌트
---------------------------------------------------------------------->
<template>
	<FLayerPopup ref="refPopup" width="1000" height="700">
		<template #body>
			<div>
				<img
					alt="RemoteSprtGde1"
					src="/images/cm/remoteSprtGde/remoteSprtGde_1.png"
				/>
			</div>
			<div>
				<img
					alt="RemoteSprtGde2"
					src="/images/cm/remoteSprtGde/remoteSprtGde_2.png"
				/>
			</div>
			<div>
				<img
					alt="RemoteSprtGde3"
					src="/images/cm/remoteSprtGde/remoteSprtGde_3.png"
				/>
			</div>
			<div>
				<img
					alt="RemoteSprtGde4"
					src="/images/cm/remoteSprtGde/remoteSprtGde_4.png"
				/>
			</div>
			<div>
				<img
					alt="RemoteSprtGde5"
					src="/images/cm/remoteSprtGde/remoteSprtGde_5.png"
				/>
			</div>
		</template>
	</FLayerPopup>
</template>

<script setup>
/******************
 * Popup (변경 금지)
 ******************/
/*************팝업 검색 콤포넌트 필수 fnOpen,getShow,getCdNmMap 명  유지 필수 ************ */
const refPopup = shallowRef(null);

//팝업오픈
const fnOpen = async () => {
	refPopup.value.open();
};

function getShow() {
	return refPopup.value.getShow();
}

defineExpose({
	fnOpen,
	getShow,
});
</script>
