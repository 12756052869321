<!--------------------------------------------------------------------
	파일   : Layout.vue
	작성   : CJ Developer
	설명	 : [공통] LNB가 있는 레이아웃
---------------------------------------------------------------------->
<template>
	<Suspense>
		<LNBLayout :cached-tabs="cachedComponents">
			<div v-if="isVisible" class="page-wrap">
				<div class="page-wrap-inner">
					<RouterView v-slot="{ Component }">
						<KeepAlive ref="keepAliveRef" :include="cachedTabs">
							<component
								:is="Component"
								:key="$route.path + '#' + ($route.meta.menuId ?? '')"
							/>
						</KeepAlive>
					</RouterView>
				</div>
			</div>
		</LNBLayout>
	</Suspense>
</template>

<script setup>
import LNBLayout from '@/layout/LNBLayout.vue';
import useSystemStore from '@/stores/systemStore';
import axios from 'axios';
const keepAliveRef = ref(null);
const isVisible = ref(true);

const cachedTabs = computed(() => {
	// console.log(keepAliveRef.value?.$?.__v_cache);
	return useSystemStore().openedTabs.map(tab => {
		return tab.component;
	});
});

// Cached된 Component Map 관리
const cachedComponents = ref(new Map());

watch(cachedTabs, () => {
	console.log(keepAliveRef.value?.$?.__v_cache);
	cachedComponents.value = keepAliveRef.value?.$?.__v_cache ?? new Map();
});

const storeReload = async e => {
	const locale = e?.detail?.langCd ?? useSystemStore().g_locale ?? 'kr';

	// axios header 수정
	const acceptLanguage = { 'Accept-language': locale };
	axios.defaults.headers.get = acceptLanguage;
	axios.defaults.headers.post = acceptLanguage;
	axios.defaults.headers.put = acceptLanguage;
	axios.defaults.headers.delete = acceptLanguage;

	await useSystemStore().loadUser();
	await useSystemStore()
		.loadMenu()
		.then(async () => {
			const locale = useSystemStore().g_locale ?? 'kr';
			useSystemStore().setLocale(locale);
		});
};

onMounted(() => {
	window.addEventListener('storeReload', storeReload);
});

onBeforeUnmount(() => {
	window.removeEventListener('storeReload', storeReload);
});
</script>
