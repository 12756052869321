/**********************************************************************
 * 파일   : systemStore.js
 * 작성   : CJ Developer
 * 설명   : 사용자 정보와 메뉴 정보를 담고있는 스토어
 **********************************************************************/
import { defineStore } from 'pinia';

import _ from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import VueCookies from 'vue-cookies';

import axios from 'axios';
import i18n from '@fila/lib/lang';
import apiCall from '@/plugins/apiCall';
import { useLoading } from '@fila/lib/plugins/loader/loader';

import commonUtils from '@fila/lib/utils/commonUtils';
import {
	DEFAULT_LOCALE,
	MENU_ICON,
	DEFAULT_THEME,
	MENU_INFO,
} from '@fila/lib/utils/constCommon';

import systemStorage from '@fila/lib/shared/systemStorage';

import { deleteDatabase, bulkPutItems } from '@fila/lib/shared/indexedDB';
import { ssoLogout } from '@fila/lib/plugins/authConfig';

import useMenuStore from '@/stores/menuStore';

const loader = useLoading();
let fixedTabs = [
	{
		menuId: 'HOME',
		title: 'home',
		path: '/home',
		component: 'Home',
		uuid: uuidv4(),
	},
];
let myMenuList = [];

const initialData = {
	currentMenu: {},
	// Tab Moving
	path: '',
	menuId: '',
	params: {},
	// Menu
	openedMenus: [],
	openedTabs: fixedTabs,
	fixedTabs: fixedTabs,
	myMenuList: myMenuList,
	isFold: false,
	isOldMenu: true,
	nowUUID: '',
	isToggle: false,
	// User Select Auth
	userInqAuth: {},
	// User Widget
	widgets: [],
	componentKey: '',
	// User
	g_userId: '',
	g_userNm: '',
	g_empNo: '',
	g_empId: '',
	g_compCd: '',
	g_compNm: '',
	g_empCompCd: '',
	g_mailAddr: '',
	g_userLgnId: '',
	g_deptCd: '',
	g_deptNm: '',
	g_cctrCd: '',
	g_cctrNm: '',
	g_acunitCd: '',
	g_acunitNm: '',
	g_theme: DEFAULT_THEME,
	g_rslVal: Number(VueCookies.get('_rslVal') ?? 100), // 해상도
	g_tmznCd: '',
	g_timezone: '',
	g_timezone2: '',
	g_locale: VueCookies.get('_locale') ?? DEFAULT_LOCALE,
	g_langCd: VueCookies.get('_locale') ?? DEFAULT_LOCALE,
	g_natCd: '',
	g_natNm: '',
	g_dateFormatCd: '',
	g_dateFormat: 'yyyy-mm-dd',
	g_menuId: '',
	g_userSeCd: '',
	g_shopCd: '',
	g_shopNm: '',
	g_custCd: '',
	g_custNm: '',
	g_crncyCd: '', //회사별 화폐코드
	g_currency: '', //회사별 화폐명
	g_crncyFmtCd: '', //회사별 통화포맷코드
	g_currencyFormat: '', //회사별 통화포맷
	g_unitCrncyCd: '', //회계단위별 화폐코드
	g_unitCurrency: '', //회계단위별 화폐명
	g_unitCrncyFmtCd: '', //회계단위별 통화포맷코드
	g_unitCurrencyFormat: '', //회계단위별 통화포맷
	g_unitBizplcAggYn: '', //사업장집계여부
	g_rprsBrndCd: '',
	g_acunitList: [],
	g_compList: [],
	g_crncyList: [],
	g_timer: 30,
	g_buList: [],
	g_essList: [],
	// Etc
	locales: [],
	isAccessInfo: false,
	loginType: false,
	logDtm: '',
	ipAddr: '',
	cachedMenuData: {}, // 캐시된 메뉴 데이터
};

const useSystemStore = defineStore({
	id: 'systemStore',
	state: () => _.cloneDeep(initialData),
	getters: {},
	actions: {
		async load() {
			loader.show();
			await this.loadUser();
			this.loadSiteMapMenuList();
			await this.loadMenu();
			await this.loadUserInqAuth();
			await this.loadUserAunitList();
			await this.loadUserCompList();
			await this.loadWidget();
			await this.loadBuList();
			await this.loadEssList();
			loader.hide();
		},
		/**
		 * 사용자 정보 조회
		 */
		async loadUser() {
			// 로그인 상태 user 현행화
			if (!commonUtils.gfn_isEmpty(VueCookies.get('accessToken'))) {
				// 토큰만료 새로고침 화면 멈춤 현상으로 catch 적용
				const param = {};
				if (!commonUtils.gfn_isEmpty(VueCookies.get('_locale'))) {
					param['langCd'] = VueCookies.get('_locale');
				}
				await apiCall
					.get('CM_API/cm/global/common/main/user', param)
					.then(res => {
						if (res && res.data && res.data.data) {
							//this.g_user = Object.assign({}, res.data.data);

							const datas = res.data.data;
							this.g_userId = datas.userId;
							this.g_userNm = datas.userNm;
							this.g_empNo = datas.empNo;
							this.g_empId = datas.empId;
							this.g_userLgnId = datas.lgnId;
							this.g_compCd = datas.compCd;
							this.g_compNm = datas.compNm;
							this.g_empCompCd = datas.empCompCd;
							this.g_mailAddr = datas.emlAddr;
							this.g_deptCd = datas.deptCd;
							this.g_deptNm = datas.deptNm;
							this.g_shopCd = datas.shopCd;
							this.g_shopNm = datas.shopNm;
							this.g_custCd = datas.custCd;
							this.g_custNm = datas.custNm;
							this.g_cctrCd = datas.cctrCd;
							this.g_cctrNm = datas.cctrNm;
							this.g_acunitCd = datas.acunitCd;
							this.g_acunitNm = datas.acunitNm;
							this.g_theme = datas.theme;
							this.g_rslVal = Number(datas.rslVal ?? 100);
							this.g_tmznCd = datas.tmznCd;
							this.g_timezone = datas.tmznRmrk1;
							this.g_timezone2 = datas.tmznRmrk2;
							this.g_locale = datas.langCd;
							this.g_langCd = datas.langCd;
							this.g_natCd = datas.natCd;
							this.g_natNm = datas.natNm;
							this.g_userSeCd = datas.userSeCd;
							this.g_dateFormatCd = datas.dtFmtCd;
							this.g_dateFormat = datas.dtFmtNm ?? this.g_dateFormat;
							this.g_crncyCd = datas.crncyCd; //회사별 화폐코드
							this.g_currency = datas.crncyNm; //회사별 화폐명
							this.g_crncyFmtCd = datas.crncyFmtCd; //회사별 통화포맷코드
							this.g_currencyFormat = datas.crncyFmtNm; //회사별 통화포맷
							this.g_unitCrncyCd = datas.unitCrncyCd; //회계단위별 화폐코드
							this.g_unitCurrency = datas.unitCrncyNm; //회계단위별 화폐명
							this.g_unitCrncyFmtCd = datas.unitCrncyFmtCd; //회계단위별 통화포맷코드
							this.g_unitCurrencyFormat = datas.unitCrncyFmtNm; //회계단위별 통화포맷
							this.g_unitBizplcAggYn = datas.unitBizplcAggYn; //회계단위별 통화포맷
							this.g_rprsBrndCd = datas.rprsBrndCd;

							VueCookies.set('_theme', datas.theme ?? DEFAULT_THEME, {
								expires: '1y',
							});
						} else {
							this.logout();
						}
					})
					.catch(() => {
						loader.hide();
						this.logout();
					});

				apiCall.get('CM_API/cm/global/common/main/getLatestLogin').then(res => {
					this.logDtm = res.data.data[0].logDtm;
					this.ipAddr = res.data.data[0].ipAddr;
				});
			}
			const cmCds = await commonUtils.gfn_getMultiCmCd(['LANG_CD', 'CRNCY_CD']);

			this.locales = cmCds.LANG_CD ?? [];
			this.g_crncyList = cmCds.CRNCY_CD ?? [];
		},
		async loadFavorite() {
			// MyMenu 조회하는거 넣어줘

			this.myMenuList = await apiCall
				.get('CM_API/cm/global/common/main/favorite/list')
				.then(res => {
					return res?.data?.data;
				});
			return this.myMenuList;
		},
		/**
		 * 메뉴 정보 조회
		 */
		async loadMenu(isFromLogin = true) {
			const response = await apiCall
				.get('CM_API/cm/global/common/main/menulist')
				.then(res => res);
			// origin data
			var myMenu = createMyMenu();

			myMenu.forEach(item => {
				response.data.data.unshift(item); // 0 1 2
			});
			var checkedItem = await this.loadFavorite();
			var addMyMenu = [];
			var customMenu = null;

			response.data.data.forEach(menu => {
				checkedItem?.forEach(myMenu => {
					if (myMenu.menuId == menu.menuId) {
						customMenu = { ...menu };
						customMenu.uprMenuId = 'MY00003000';
						addMyMenu.push(customMenu);
					}
				});
			});

			await this.setMenuList(response.data.data);
			//indexDB에는 Crumb때문에 일반 메뉴들만 들어가야함. 따라서 DB Set 해준 다음에 즐겨찾기 메뉴 추가
			addMyMenu?.forEach(addMenu => {
				response.data.data.push(addMenu);
			});

			let customData = this.makeCustomData(response.data.data);

			// TO-DO 추후 제거
			const tempIcon = {
				MY00000000: 'aside-ico-24-my',
				CM00000000: 'aside-ico-24-admin',
				PN00000000: 'aside-ico-24-plan',
				PO00000000: 'aside-ico-24-purch',
				SA00000000: 'aside-ico-24-sales',
				SP00000000: 'aside-ico-24-pos',
				EI00000000: 'aside-ico-24-imexport-new',
				WM00000000: 'aside-ico-24-distrib',
				CR00000000: 'aside-ico-24-crm',
				CS00000000: 'aside-ico-24-center',
				EB00000000: 'aside-ico-24-online',
				FI00000000: 'aside-ico-24-finance',
				CO00000000: 'aside-ico-24-manage',
				HR00000000: 'aside-ico-24-hr',
				CP00000000: 'aside-ico-24-pol-new',
				ES00000000: 'aside-ico-24-ess',
				DW00000000: 'aside-ico-24-dashboard',
				BA00000000: 'aside-ico-24-workSupport',
			};

			customData.map(menu => {
				if (menu?.uprMenuId === null) {
					menu.menuIcon = tempIcon[menu?.menuId];
				}
			});

			if (isFromLogin) {
				new Promise(resolve => {
					const leafMenuList = [];
					// 메뉴 트리 형태 변경
					useMenuStore().menuTree = {
						root: true,
						children: Object.assign(
							[],
							this.mergeMenuTree(customData, leafMenuList),
						),
					};

					resolve(leafMenuList);
				}).then(res => {
					this.leafMenuList = res.filter(
						m =>
							m.urlNm.split('>')[0]?.trim() !==
							i18n?.i18n?.global?.t('LBL.CM.MY.001'),
					);
					// 고정 메뉴 조회 api call
					this.setFxTabs();
				});
			} else {
				const leafMenuList = [];
				// 메뉴 트리 형태 변경
				useMenuStore().menuTree = {
					root: true,
					children: Object.assign(
						[],
						this.mergeMenuTree(customData, leafMenuList),
					),
				};
				this.leafMenuList = leafMenuList.filter(
					m =>
						m.urlNm.split('>')[0]?.trim() !==
						i18n?.i18n?.global?.t('LBL.CM.MY.001'),
				);
				// 고정 메뉴 조회 api call
				await this.setFxTabs();
			}

			// 열린 메뉴 정보를 초기화한다.
			this.openedMenus = [];
		},
		loadSiteMapMenuList() {
			const sitemapTree = useMenuStore().sitemapTree;
			if (commonUtils.gfn_isEmpty(sitemapTree)) {
				apiCall.get('CM_API/cm/global/common/main/sitemap').then(res => {
					useMenuStore().sitemapTree = {
						root: true,
						children: Object.assign(
							[],
							this.mergeSiteMapMenuTree(res.data.data, null, 'uprMenuId'),
						),
					};
				});
			}
		},
		/**
		 * 사용자 조회 권한 조회
		 */
		async loadUserInqAuth() {
			const response = await apiCall
				.get('CM_API/cm/global/common/main/userInqAuth')
				.then(res => res);

			// 사용자 조회 권한 세팅
			this.userInqAuth = {
				depts: response.data.deptAcunitAuthList.data,
				brnds: response.data.brndList.data,
			};
		},
		/**
		 * 사용자 조회 권한 조회
		 */
		async loadUserAunitList() {
			const response = await apiCall
				.get('CM_API/cm/global/common/main/userAcunitList')
				.then(res => res);
			this.g_acunitList = response.data.data;
		},
		/**
		 * 사용자 조회 권한 조회
		 */
		async loadUserCompList() {
			const response = await apiCall
				.get('CM_API/cm/global/common/main/getCompListByAuth')
				.then(res => res);
			this.g_compList = response.data.data;
		},
		/**
		 * 사용자 위젯 정보 조회
		 */
		async loadWidget() {
			await apiCall
				.get('CM_API/cm/global/admin/cmmng/baseinfo/wdgtmng/customList')
				.then(res => {
					this.widgets = res.data.data;
				});
		},
		/**
		 * BU 정보 확인
		 */
		async loadBuList() {
			await apiCall.get('CM_API/cm/global/common/main/getBuList').then(res => {
				this.g_buList = res.data.data;
			});
		},
		/**
		 * ESS 정보 확인
		 */
		async loadEssList() {
			await apiCall.get('CM_API/cm/global/common/main/getEssList').then(res => {
				this.g_essList = res.data.data;
			});
		},
		// User
		async setLocale(locale) {
			this.g_locale = locale;
			this.g_langCd = locale;
			VueCookies.set('_locale', locale, { expires: '1y' });
			i18n.setLocale(locale);
			// axios header 수정
			const acceptLanguage = { 'Accept-language': locale };
			axios.defaults.headers.get = acceptLanguage;
			axios.defaults.headers.post = acceptLanguage;
			axios.defaults.headers.put = acceptLanguage;
			axios.defaults.headers.delete = acceptLanguage;

			loader.show();

			if (!commonUtils.gfn_isEmpty(VueCookies.get('accessToken'))) {
				// 로그인한 경우에는 메뉴정보 다시 로드
				await this.loadMenu(false);

				const tabsUpdatePromises = this.openedTabs.map(async tab => {
					if (tab?.menuId) {
						const menuInfo = await systemStorage.getMenuById(tab?.menuId);
						tab.title = menuInfo?.menuNm ?? 'home'; // 비동기적으로 타이틀 업데이트
					}
					return tab;
				});

				// 모든 비동기 작업이 완료될 때까지 기다림
				await Promise.all(tabsUpdatePromises).then(() => {});

				window.location.reload();
				loader.hide();
			} else {
				window.location.reload();
				loader.hide();
			}
		},
		setTheme(theme) {
			this.g_theme = theme;
			window.location.reload();
		},
		// reset() {
		// 	let locale = i18n.getLocale();
		// 	this.$reset();
		// 	// reset 시간 충돌
		// 	setTimeout(() => {
		// 		i18n.setLocale(locale);
		// 	}, 50);
		// },
		/**
		 * 로그인 시 받은 토큰 정보를 저장하는 함수
		 * @param {string} accessToken
		 * @param {string} refreshToken
		 */
		login(accessToken, refreshToken) {
			VueCookies.set('accessToken', accessToken);
			VueCookies.set('refreshToken', refreshToken);
		},
		/**
		 * 로그아웃 시 스토어의 정보를 초기화하는 함수
		 */
		logout() {
			const ssoYn = localStorage.getItem('ssoYn');
			const userSeCd = this.g_userSeCd;

			if (ssoYn === 'Y') {
				ssoLogout();
				this.onLogout();
			} else {
				this.onLogout();
				this.moveLogin(userSeCd);
			}
			localStorage.removeItem('ssoYn');
		},
		onLogout() {
			i18n.setLocale(VueCookies.get('_locale') ?? DEFAULT_LOCALE);

			Object.keys(initialData).map(key => {
				this[key] = initialData[key];
				if (key === 'openedTabs') {
					this.setFxTabs(true);
				}
			});
			//메뉴 스토어 초기화
			useMenuStore().clear();
			const keysToRemove = ['currentMenu', 'moveTab', 'opened'];

			keysToRemove.forEach(key => sessionStorage.removeItem(key));

			//sessionStorage.clear();
			VueCookies.remove('accessToken');
			VueCookies.remove('refreshToken');
		},
		moveLogin(userSeCd) {
			switch (userSeCd) {
				case '02':
					window.location.href = MENU_INFO.POS_LOGIN_PATH;
					break;
				case '03':
					window.location.href = MENU_INFO.POL_LOGIN_PATH;
					break;
				case '01':
				default:
					window.location.href = MENU_INFO.ON_LOGIN_PATH;
			}
		},
		sessionCheck() {
			if (!commonUtils.gfn_isEmpty(VueCookies.get('accessToken'))) {
				return true;
			} else {
				return false;
			}
		},
		// Menu
		/**
		 * 키-값으로 지정된 메뉴리스트 저장
		 * @param {array} menuList
		 */
		async setMenuList(menuList) {
			await deleteDatabase()
				.then(async () => {
					let menuById = [];
					menuList.forEach(menu => {
						let menuPath = menu.menuPathNm?.toLowerCase();
						const menuId = {
							menuIdKey: menu?.menuId,
							menuNmKey: menuPath,
							...menu,
						};
						menuById.push(menuId);
					});
					await bulkPutItems('menuList', menuById);
				})
				.catch(error => {
					console.error('Error deleting database in deleteDB function:', error);
				});
			loader.hide();
		},
		/**
		 * 이전 메뉴 셋팅
		 * @param {*} parentMenuId rootId
		 */
		setOldMenu(check) {
			this.isOldMenu = check;
		},
		setNowUUID(uuid) {
			this.nowUUID = uuid;
		},
		setComponentKey() {
			this.componentKey = uuidv4();
		},
		async setAcunitCd(acunitCd) {
			apiCall
				.post(
					'CM_API/cm/global/admin/sysmgt/func/userenvmng/save',
					{
						acunitCd,
					},
					{ loader: true },
				)
				.then(() => {
					this.loadUser().then(() => {
						window.location.reload();
					});
				});
		},
		/**
		 * 트리구조 메뉴 만들기
		 * @param {*} items api response row data
		 * @param {*} menuId
		 * @param {*} link
		 * @returns
		 */
		mergeMenuTree(
			items,
			leafMenuList,
			urlNm = [],
			menuId = null,
			link = 'uprMenuId',
		) {
			if (items.filter(item => item[link] === menuId).length === 0) {
				leafMenuList.push({
					...items.find(item => item?.menuId === menuId),
					urlNm: urlNm.slice(0, urlNm.length - 1).join(' > '),
				});
				return null;
			}
			return items
				.filter(item => item[link] === menuId)
				.map(item => {
					urlNm.push(item.menuNm);
					const children = this.mergeMenuTree(
						items,
						leafMenuList,
						urlNm,
						item?.menuId,
					);
					urlNm.pop();
					return {
						...item,
						children,
						/*.sort(
						function (a, b) {
							if (a.sortNo > b.sortNo) return 1;
							else if (a.sortNo < b.sortNo) return -1;
							else return 0;
						},
					),*/
					};
				});
		},
		/**
		 * sitemap 트리구조 메뉴 만들기
		 * @param {*} items api response row data
		 * @param {*} menuId
		 * @param {*} link
		 * @returns
		 */
		mergeSiteMapMenuTree(items, menuId = null, link = 'uprMenuId') {
			if (items.filter(item => item[link] === menuId).length === 0) {
				return null;
			}
			return items
				.filter(item => item[link] === menuId)
				.map(item => {
					const children = this.mergeSiteMapMenuTree(items, item?.menuId);
					return {
						...item,
						children,
					};
				});
		},

		/**
		 * 메뉴 url 검색
		 * router index 에서 사용
		 * @param {*} url menuPathNm
		 * @returns menuItem
		 */
		getSearchMenu(url) {
			const searchedMenu = this.leafMenuList.filter(
				menu => menu.menuPathNm.toLowerCase() === url.toLowerCase(),
			);

			return searchedMenu.length > 0 ? searchedMenu : null;
		},
		/**
		 * 메뉴 데이터 커스텀
		 * @param {*} data
		 * @returns
		 */
		makeCustomData(data) {
			const idColumn = 'rootMenuId';

			let customData = data.map(item => {
				if (item.menuSeCd === '01') {
					// 1. root id 추가. root 메뉴인 경우 초기 메뉴 닫혀있으며, 자식 메뉴는 초기에 열려잇어야함
					if (item.uprMenuId) {
						item.isOpen = true;
						item[idColumn] = this.findRootId(
							data,
							item?.menuId,
							item?.uprMenuId,
						);
					} else {
						item.isOpen = false;
						item[idColumn] = '';
					}
					return item;
				} else {
					return {};
				}
			});
			return customData;
		},
		/**
		 * rootId 찾기
		 * @param {*} menuList menu response 결과
		 * @param {*} menuId 메뉴ID
		 * @param {*} uprMenuId 상위ID
		 * @returns 최상위 rootId
		 */
		async findRootId(menuList, menuId, uprMenuId) {
			if (!uprMenuId) {
				return menuId;
			}

			let findItem = await systemStorage.getMenuById(uprMenuId);
			// let findItem = menuList.find(item => item.menuId === uprMenuId);
			if (findItem) {
				return this.findRootId(menuList, findItem?.menuId, findItem?.uprMenuId);
			}
		},
		setIcon(menuId) {
			return MENU_ICON.hasOwnProperty(menuId) ? MENU_ICON[menuId] : '';
		},
		/**
		 * 현재 탭 중에서 동일 메뉴 존재 시 반환
		 * @param {*} menuPathNm
		 * @returns
		 */
		isOpenTabByPath(menuPathNm) {
			let findTab = this.openedTabs.find(
				tab => tab.path === menuPathNm.toLowerCase(),
			);
			return findTab;
		},
		/**
		 * 현재 탭 중에서 동일 메뉴 존재 시 반환
		 * @param {*} menuId
		 * @returns
		 */
		isOpenTabByMenuId(menuId) {
			return this.openedTabs.find(tab => tab.menuId === menuId);
		},
		/**
		 * 현재 탭 중에서 path가 일치하는 목록 중에서 page가 일치하는 탭 객체 반환
		 * @param {Object(string path, string page, ...)} menuInfo path(=소문자만), page
		 * @returns
		 */
		isOpenTabByUUID(menuUUID) {
			let findTab = this.openedTabs.find(tab => tab.uuid === menuUUID);
			return findTab;
		},
		async setLeafMenuList() {
			const menuTree = useMenuStore().menuTree ?? {};
			const fixedTabIdList = this.fixedTabs.map(tab => tab?.menuId);

			const goChildren = (menu, leafMenuList, urlNm = []) => {
				if (commonUtils.gfn_isEmpty(menu?.children)) {
					leafMenuList.push({
						...menu,
						urlNm: urlNm.join(' > '),
						fxYn: fixedTabIdList.includes(menu?.menuId) ? 'Y' : 'N',
					});
					return;
				}
				if (commonUtils.gfn_isNotEmpty(menu?.menuNm)) {
					urlNm.push(menu.menuNm);
				}
				menu.children.map(children => {
					goChildren(children, leafMenuList, urlNm);
				});
				urlNm.pop();
			};

			const res = await new Promise(resolve => {
				const leafMenuList = [];
				goChildren(menuTree, leafMenuList);
				resolve(leafMenuList);
			}).then(res => res);

			this.leafMenuList = res.filter(
				m =>
					m.urlNm.split('>')[0]?.trim() !==
					i18n?.i18n?.global?.t('LBL.CM.MY.001'),
			);

			return res.filter(
				m =>
					m.urlNm.split('>')[0]?.trim() !==
					i18n?.i18n?.global?.t('LBL.CM.MY.001'),
			);
			// const leafMenuResponse = await apiCall
			// 	.get('CM_API/cm/global/common/main/leafMenulist')
			// 	.then(res => res);
			// this.leafMenuList = leafMenuResponse.data.data;
			// return leafMenuResponse.data.data;
		},
		// 메뉴 데이터 캐시에서 지정한 메뉴ID 또는 패스에 대한 메뉴 데이터를 얻는다.
		getCachedMenuData(menuIdOrPath) {
			return this.cachedMenuData.hasOwnProperty(menuIdOrPath)
				? this.cachedMenuData[menuIdOrPath]
				: null;
		},
		// 지정한 패스에 해당하는 메뉴 데이터를 캐시한다.(메뉴ID, PATH 두 가지 키로 캐싱한다)
		setCacheMenuData(path, menuData) {
			if (path && menuData) {
				this.cachedMenuData[menuData.menuId] = menuData;
				this.cachedMenuData[path] = menuData;
			}
		},
		// 고정 탭 세팅
		async setFxTabs(isLogOut = false) {
			if (!isLogOut) {
				// logout에서 호출되지 않은 경우
				await apiCall.get('CM_API/cm/global/common/main/fxmenus').then(res => {
					// 고정 탭 전처리 후 fixedTabs에 할당
					fixedTabs = res.data.data?.map(data => {
						const matchedTab = this.openedTabs.find(
							tab => tab?.menuId === data?.menuId,
						);
						if (commonUtils.gfn_isEmpty(matchedTab)) {
							return { ...data, component: null, uuid: uuidv4(), fxYn: 'Y' };
						} else {
							return { ...data, ...matchedTab, fxYn: 'Y' };
						}
					});

					const nonFixedTabList = fixedTabs.map(tab => tab?.menuId);
					const nonFixedTabs = this.openedTabs
						.filter(tab => !nonFixedTabList.includes(tab?.menuId))
						.map(nt => {
							return { ...nt, fxYn: 'N' };
						});

					this.openedTabs = [...fixedTabs, ...nonFixedTabs];
					this.fixedTabs = fixedTabs;

					const fixedTabIdList = fixedTabs.map(tab => tab?.menuId);

					this.leafMenuList = this.leafMenuList?.map(menu => {
						return {
							...menu,
							fxYn: fixedTabIdList.includes(menu?.menuId) ? 'Y' : 'N',
						};
					});
				});
			} else {
				this.openedTabs = this.fixedTabs;
				this.fixedTabs = this.fixedTabs;
			}
		},
	},
	persist: {
		enabled: true,
		strategies: [
			{
				key: 'opened',
				storage: sessionStorage,
				paths: ['openedMenus', 'openedTabs', 'fixedTabs', 'myMenuList'],
			},
			{
				key: 'currentMenu',
				storage: sessionStorage,
				paths: ['currentMenu'],
			},
			{
				key: 'moveTab',
				storage: sessionStorage,
				paths: ['path', 'menuId', 'params'],
			},
			{
				key: 'locales',
				storage: localStorage,
				paths: ['locales'],
			},
			{
				key: 'userInqAuth',
				storage: localStorage,
				paths: ['userInqAuth'],
			},
			{
				key: 'widgets',
				storage: localStorage,
				paths: ['widgets'],
			},
			{
				key: 'user',
				storage: localStorage,
				paths: [
					'g_userId',
					'g_userNm',
					'g_empNo',
					'g_empId',
					'g_compCd',
					'g_compNm',
					'g_empCompCd',
					'g_mailAddr',
					'g_userLgnId',
					'g_deptCd',
					'g_deptNm',
					'g_cctrCd',
					'g_cctrNm',
					'g_acunitCd',
					'g_acunitNm',
					'g_theme',
					'g_rslVal',
					'g_tmznCd',
					'g_timezone',
					'g_timezone2',
					'g_locale',
					'g_langCd',
					'g_natCd',
					'g_natNm',
					'g_dateFormat',
					'g_dateFormatCd',
					'g_menuId',
					'g_userSeCd',
					'g_shopCd',
					'g_shopNm',
					'g_custCd',
					'g_custNm',
					'g_crncyCd', //회사별 화폐코드
					'g_currency', //회사별 화폐명
					'g_crncyFmtCd', //회사별 통화포맷코드
					'g_currencyFormat', //회사별 통화포맷
					'g_unitCrncyCd', //회계단위별 화폐코드
					'g_unitCurrency', //회계단위별 화폐명
					'g_unitCrncyFmtCd', //회계단위별 통화포맷코드
					'g_unitCurrencyFormat', //회계단위별 통화포맷
					'g_unitBizplcAggYn',
					'g_rprsBrndCd',
					'g_acunitList',
					'g_compList',
					'g_crncyList',
					'g_timer',
					'logDtm',
					'ipAddr',
					'g_buList',
					'g_essList',
				],
			},
		],
	},
});

function createMyMenu() {
	// 자식 객체 생성
	const recentMenu = {
		menuId: 'MY00004000',
		menuNm: i18n?.i18n?.global?.t('LBL.CM.RECENTMENU.001') ?? 'test',
		menuPathNm: null,
		menuSeCd: '01',
		sortNo: '4',
		uprMenuId: 'MY00002000', // 부모 객체의 menuId와 연결
		authYn: 'Y',
		readAuthBtnMsg: 'BTN.CM.SEARCH.001',
		readAuthYn: 'Y',
		saveAuthBtnMsg: 'BTN.CM.SAVE.001',
		saveAuthYn: 'Y',
		isOpen: false,
	};
	// 자식 객체 생성
	const myMenu = {
		menuId: 'MY00003000',
		menuNm: i18n?.i18n?.global?.t('LBL.CM.MYMENU.001') ?? 'test',
		menuPathNm: null,
		menuSeCd: '01',
		sortNo: '3',
		uprMenuId: 'MY00001000', // 부모 객체의 menuId와 연결
		authYn: 'Y',
		readAuthBtnMsg: 'BTN.CM.SEARCH.001',
		readAuthYn: 'Y',
		saveAuthBtnMsg: 'BTN.CM.SAVE.001',
		saveAuthYn: 'Y',
		isOpen: false,
	};
	// 자식 객체 생성
	const uprRecentMenu = {
		menuId: 'MY00002000',
		menuNm: i18n?.i18n?.global?.t('LBL.CM.RECENTMENU.001') ?? 'test',
		menuPathNm: null,
		menuSeCd: '01',
		sortNo: '2',
		uprMenuId: 'MY00000000', // 부모 객체의 menuId와 연결
		authYn: 'Y',
		readAuthBtnMsg: 'BTN.CM.SEARCH.001',
		readAuthYn: 'Y',
		saveAuthBtnMsg: 'BTN.CM.SAVE.001',
		saveAuthYn: 'Y',
		isOpen: false,
	};
	// 자식 객체 생성
	const uprMyMenu = {
		menuId: 'MY00001000',
		menuNm: i18n?.i18n?.global?.t('LBL.CM.MYMENU.001') ?? 'test',
		menuPathNm: null,
		menuSeCd: '01',
		sortNo: '1',
		uprMenuId: 'MY00000000', // 부모 객체의 menuId와 연결
		authYn: 'Y',
		readAuthBtnMsg: 'BTN.CM.SEARCH.001',
		readAuthYn: 'Y',
		saveAuthBtnMsg: 'BTN.CM.SAVE.001',
		saveAuthYn: 'Y',
		isOpen: false,
	};
	// 부모 객체 생성
	const my = {
		menuId: 'MY00000000',
		menuNm: i18n?.i18n?.global?.t('LBL.CM.MY.001') ?? 'test',
		menuPathNm: null,
		menuSeCd: '01',
		sortNo: '0',
		uprMenuId: null,
		authYn: 'Y',
		readAuthBtnMsg: 'BTN.CM.SEARCH.001',
		readAuthYn: 'Y',
		saveAuthBtnMsg: 'BTN.CM.SAVE.001',
		saveAuthYn: 'Y',
		isOpen: false,
	};
	// 객체들을 객체로 묶어서 리턴
	return [recentMenu, myMenu, uprRecentMenu, uprMyMenu, my];
}

export default useSystemStore;
