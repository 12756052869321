const __pages_import_0__ = () => import("/src/views/POSLogin.vue");
const __pages_import_1__ = () => import("/src/views/POLLogin.vue");
const __pages_import_2__ = () => import("/src/views/OnLogin.vue");
const __pages_import_3__ = () => import("/src/views/NotFound.vue");
const __pages_import_4__ = () => import("/src/views/Logout.vue");
const __pages_import_5__ = () => import("/src/views/Login.vue");
const __pages_import_6__ = () => import("/src/views/Layout.vue");
const __pages_import_7__ = () => import("/src/views/IndexedDB.vue");
const __pages_import_8__ = () => import("/src/views/Home.vue");
const __pages_import_9__ = () => import("/src/views/Error.vue");
const __pages_import_10__ = () => import("/src/views/EMHrInfoRegAply.vue");
const __pages_import_11__ = () => import("/src/views/EMHrApntCnfm.vue");
const __pages_import_12__ = () => import("/src/views/AzureADLogin.vue");

const routes = [{"name":"POSLogin","path":"/poslogin","component":__pages_import_0__,"props":true},{"name":"POLLogin","path":"/pollogin","component":__pages_import_1__,"props":true},{"name":"OnLogin","path":"/onlogin","component":__pages_import_2__,"props":true},{"name":"NotFound","path":"/notfound","component":__pages_import_3__,"props":true},{"name":"Logout","path":"/logout","component":__pages_import_4__,"props":true},{"name":"Login","path":"/login","component":__pages_import_5__,"props":true},{"name":"Layout","path":"/layout","component":__pages_import_6__,"props":true},{"name":"IndexedDB","path":"/indexeddb","component":__pages_import_7__,"props":true},{"name":"Home","path":"/home","component":__pages_import_8__,"props":true},{"name":"Error","path":"/error","component":__pages_import_9__,"props":true},{"name":"EMHrInfoRegAply","path":"/emhrinforegaply","component":__pages_import_10__,"props":true},{"name":"EMHrApntCnfm","path":"/emhrapntcnfm","component":__pages_import_11__,"props":true},{"name":"AzureADLogin","path":"/azureadlogin","component":__pages_import_12__,"props":true}];

export default routes;