<!--------------------------------------------------------------------
    파일   : LoginPop04.vue
    작성   : CJ Developer
    설명   : 이메일 인증 팝업
---------------------------------------------------------------------->

<template>
	<p class="fs-14">
		{{ message1 }} <br />
		{{ message2 }}
	</p>
	<div class="logconwrap">
		<FInput
			id="inputValue"
			v-model="inputValue"
			:label="message4"
			name="inputValue"
			class="login"
			disabled
		/>
		<p class="fs-14">
			{{ message3 }}
		</p>
		<button class="bord" @click="onClickPrev">{{ t('LBL.CM.LGN.013') }}</button>
	</div>
</template>

<script setup>
const { t } = useI18n();

const message1 = ref('');
const message2 = ref('');
const message3 = ref('');
const message4 = ref('');

const inputValue = ref('');

const props = defineProps({
	emlAddrValue: {
		type: [String, null],
	},
	lgnIdValue: {
		type: [String, null],
	},
	cpnoValue: {
		type: [String, null],
	},
	certWaySeCdValue: {
		type: [String, null],
	},
});

const emit = defineEmits(['prevClicked']);
const emlAddr = computed({
	get() {
		return props.emlAddrValue;
	},
});
const lgnId = computed({
	get() {
		return props.lgnIdValue;
	},
});
const cpno = computed({
	get() {
		return props.cpnoValue;
	},
});
const certWaySeCd = computed({
	get() {
		return props.certWaySeCdValue;
	},
});

function onClickPrev() {
	emit('prevClicked');
}

onMounted(() => {
	if (certWaySeCd.value == '01') {
		message1.value = t('LBL.CM.LGN.004');
		message2.value = t('LBL.CM.LGN.005');
		message3.value = t('LBL.CM.LGN.006');
		message4.value = t('LBL.CM.EMLADDR.001');
		const email = emlAddr.value;
		if (!email) return '';

		// 이메일을 사용자 이름과 도메인으로 분리
		const [username, domain] = email.split('@');

		// 사용자 이름이 2자 이상인 경우, 첫 글자와 마지막 글자를 제외하고 마스킹 처리
		let maskedUsername = username;
		if (username.length > 2) {
			maskedUsername = `${username[0]}${'*'.repeat(username.length - 2)}${username[username.length - 1]}`;
		}

		// 마스킹 처리된 사용자 이름과 도메인을 조합하여 반환
		inputValue.value = `${maskedUsername}@${domain}`;
	} else if (certWaySeCd.value == '02') {
		message1.value = t('LBL.CM.LGN.007');
		message2.value = t('LBL.CM.LGN.008');
		message3.value = t('LBL.CM.LGN.009');
		message4.value = t('LBL.CM.MSGER.002');
		inputValue.value = lgnId.value;
	} else if (certWaySeCd.value == '03') {
		message1.value = t('LBL.CM.LGN.010');
		message2.value = t('LBL.CM.LGN.011');
		message3.value = t('LBL.CM.LGN.012');
		message4.value = t('LBL.CM.CPNO.002');
		const rawCpNo = cpno.value;
		if (!rawCpNo) return '';
		// '010-****-1111' 형태로 변환
		inputValue.value = rawCpNo.replace(
			/(\d{2,3})-?(\d{3,4})-?(\d{4})/,
			(match, p1, p2, p3) => `${p1}-${'*'.repeat(p2.length)}-${p3}`,
		);
	}
});
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
	}
}
.logconwrap {
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	margin-top: 6px;
	.login {
		width: 100%;
	}
	.bord {
		font-size: 14px;
		text-decoration: underline;
		margin-top: 6px;
	}
}
</style>
<style lang="scss">
.login {
	label {
		font-size: 14px;
		margin-bottom: 10px;
	}
	> .input-item {
		border-radius: 12px !important;
		> input {
			padding: 24px 16px 23px !important;
			border-radius: 12px;
			font-size: 16px;
		}
	}
}
</style>
