<!--------------------------------------------------------------------
	파일   : LNBLayout.vue
	작성   : CJ Developer
	설명	 : [공통] ERP 시스템의 LNB 레이아웃을 그리는 컴포넌트
---------------------------------------------------------------------->
<template>
	<div class="main-wrap">
		<div class="aside-wrap" :class="isFold ? 'off' : 'on'">
			<AsideMenu />
		</div>
		<div class="content-wrap">
			<MainHeader />
			<MainTabs ref="mainTabs" :cached-tabs="cachedTabs" />

			<!-- 메뉴 화면이 나타나는 영역 -->
			<slot />
		</div>
	</div>
</template>

<script setup>
import AsideMenu from '@/layout/LNB/AsideMenu.vue';
import MainHeader from '@/layout/LNB/MainHeader.vue';
import MainTabs from '@/layout/LNB/MainTabs.vue';

// import ErrorProvider from '@/layout/ErrorProvider.vue';

import useSystemStore from '@/stores/systemStore';
const mainTabs = ref();

const props = defineProps({
	cachedTabs: {
		type: Map,
		default: new Map(),
	},
});

const systemStore = useSystemStore();
const isFold = computed(() => systemStore.isFold);

const closeCurrentTab = () => {
	mainTabs.value?.closeCurrentTab();
};

const closeSelectTab = (menuId, isMove = true) => {
	mainTabs.value?.closeSelectTab(menuId, isMove);
};

// 현재 탭을 닫는 기능을 하위 컴포넌트에 제공한다.
provide('closeCurrentTab', closeCurrentTab);
provide('closeSelectTab', closeSelectTab);
</script>

<style lang="scss" scoped>
.aside-wrap {
	// transition: all ease 0.3s;
	&.on {
		flex: 0 0 240px;
	}
	&.off {
		flex: 0 0 64px;
		max-width: 64px;
		padding: 0;
		aside {
			max-width: 64px;
		}
		menu {
			ul {
				ul {
					display: none;
				}
				&.depth-1 {
					a {
						.menu-name,
						.ico-arrow {
							display: none;
						}
						color: transparent;
					}
				}
			}
		}
	}
}

.content-wrap {
	transition: all ease 0.3s;
	flex-grow: 1;
	min-width: calc(100% - 240px);
}
</style>
