<!--------------------------------------------------------------------
    파일   : SPInvnQuizRegPop.vue
    작성   : cjo73
    설명   : 재고퀴즈등록팝업
---------------------------------------------------------------------->
<template>
	<!-- 재고퀴즈 -->
	<FLayerPopup
		ref="refPopup"
		:show="showModal"
		:title="t('LBL.SA.INVNQUIZ.001')"
		width="800"
		height="530"
		@update-visible="updateVisible"
		@cancel="onClickCancel"
	>
		<template #header>
			<!-- 제출 -->
			<FButton primary icon="ico-16-check" @click="fnProcessSend">{{
				t('BTN.HR.COM.035')
			}}</FButton>
		</template>

		<template #body>
			<AUIGrid
				ref="refGrid"
				:data="gridData"
				:column-layout="columnLayout"
				class="header-none"
			/>
		</template>

		<template #footer></template>
	</FLayerPopup>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import FLayerPopup from '@fila/lib/components/FLayerPopup';
import AUIGrid from '@fila/lib/components/AUIGrid';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useMessage } from '@fila/lib/plugins/message/message';
const message = useMessage();
const { t } = useI18n();

const showModal = ref(false);
function updateVisible(visible) {
	showModal.value = visible;
}

/* 팝업 */
const refPopup = ref(null);

/* props */
const props = defineProps({
	inParam: {
		type: Object,
		default: new Object(),
	},
});

const searchParam = ref({
	brndCd: '',
	shopCd: '',
	strtDt: '',
	qstnCnt: '',
	qstnBaseCd: '',
});

const sendYn = ref(false);

// 그리드
const refGrid = ref(null);

const columnLayout = [
	{
		dataField: 'stylCd',
		headerText: t('LBL.SA.STYL.001'), // 스타일
		width: '140',
		disabled: true,
	},
	{
		dataField: 'stylNm',
		headerText: t('LBL.SA.STYLNM.001'), // 스타일명
		width: '200',
		disabled: true,
		align: 'left',
	},
	{
		dataField: 'clrCd',
		headerText: t('LBL.SA.CLRCD.001'), // 컬러
		disabled: true,
	},
	{
		dataField: 'clrNm',
		headerText: t('LBL.SA.CLRNM.001'), // 컬러명
		disabled: true,
		width: '170',
	},
	{
		dataField: 'sizeCd',
		headerText: t('LBL.SA.SIZE.001'), // 사이즈
		disabled: true,
		visible: false,
	},
	{
		dataField: 'rlQty',
		headerText: t('LBL.SA.RLQTY.001'), // 실물수량
		dataType: 'numeric',
		align: 'right',
		editRenderer: {
			type: 'InputEditRenderer',
			onlyNumeric: true, // Input 에서 숫자만
			maxlength: 9,
		},
	},
	{
		dataField: 'qty',
		headerText: t('LBL.SA.AVALINVN.001'), // 가용재고
		dataType: 'numeric',
		align: 'right',
		disabled: true,
		visible: false,
	},
];
const gridData = ref([]);

onBeforeMount(() => {
	fnSearch();
});

/**
 * 팝업오픈
 */
const fnOpen = async () => {
	refPopup.value.open();
};

/**
 * 검색영역에 PARAM 매핑
 */
const fnSetParam = () => {
	searchParam.value = { ...searchParam.value, ...props.inParam };
};

const fnSearch = () => {
	fnSetParam();
	apiCall
		.get(
			'SCM_KR_API/scm/kr/sp/shopmng/invnmng/invnqiuz/spinvnquizreg/getInvnQuizList',
			{
				...searchParam.value,
			},
			{
				loader: true,
			},
		)
		.then(res => {
			if (props.inParam.qstnBaseCd === 'SIZ') {
				refGrid.value.showColumnByDataField(['sizeCd']);
			} else {
				refGrid.value.hideColumnByDataField(['sizeCd']);
			}

			refGrid.value.setGridData(res.data.data);
		});
};

const fnProcessSend = () => {
	try {
		if (sendYn.value) {
			message.showAlert({
				message: t('MSG.SA.INVNQUIZREG.004'), // 이미 제출된 내역입니다.
			});
			throw new Error();
		}

		refGrid.value.getGridData().forEach((items, index) => {
			if (commonUtils.gfn_isEmpty(items.rlQty)) {
				message.showAlert({
					message: t('MSG.SA.INVNQUIZREG.001'), // 실물수량을 반드시 입력해주세요.
					onConfirm: () => {
						refGrid.value.setSelectionByIndex(index, 4);
					},
				});
				throw new Error();
			}
		});

		message.showConfirm({
			message: t('MSG.CM.FIX.001', [t('BTN.HR.COM.035')]), //{0} 하시겠습니까?, [제출]
			onConfirm: () => {
				sendYn.value = true;

				let saveParams = {
					brndCd: searchParam.value.brndCd,
					shopCd: searchParam.value.shopCd,
					qstnCnt: searchParam.value.qstnCnt,
					saveInvnQuizRegList: refGrid.value.getGridData(),
				};
				apiCall
					.post(
						'SCM_KR_API/scm/kr/sp/shopmng/invnmng/invnqiuz/spinvnquizreg/processSend',
						saveParams,
					)
					.then(() => {
						message.showAlert({
							message: t('MSG.CM.SUC.003'),
							// onConfirm: () => {
							// 	refPopup.value.close();
							// },
						});
					});
			},
		});
	} catch (e) {}
};

const onClickCancel = () => {
	if (!sendYn.value) {
		message.showAlert({
			message: t('MSG.SA.INVNQUIZREG.005'), // 먼저 재고퀴즈 내역을 입력해 제출하여주세요.
		});
		return false;
	}

	refPopup.value.close();
};
defineExpose({ fnOpen, fnSearch, fnProcessSend });
</script>
