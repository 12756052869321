<template>
	<div></div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import useSystemStore from '@/stores/systemStore';
import { MENU_INFO } from '@fila/lib/utils/constCommon';
import { msalInstance } from '@fila/lib/plugins/authConfig';
import apiCall from '@/plugins/apiCall';

const accessToken = ref('');
const isInitialized = ref(false);
const router = useRouter();
const compCd = ref('');
const login = () => {
	const loginRequest = {
		scopes: ['User.Read'],
	};
	msalInstance.loginRedirect(loginRequest);
};

const logout = () => {
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		const currentAccount = accounts[0];
		msalInstance.logoutRedirect({
			account: currentAccount,
			postLogoutRedirectUri: '/',
		});
		useSystemStore().logout();
	} else {
		console.error('로그인된 계정이 없습니다.');
	}
};

const acquireToken = async () => {
	const accounts = msalInstance.getAllAccounts();
	if (accounts.length > 0) {
		const request = {
			account: accounts[0],
			scopes: ['User.Read'],
			state: 'D002',
		};
		try {
			const response = await msalInstance.acquireTokenSilent(request);
			accessToken.value = response.accessToken;
			//VueCookies.set('ssoCompCd', response.state);
			window.sessionStorage.setItem('ssoCompCd', response.state);
			await useSystemStore().login(response.accessToken, '');
		} catch (error) {
			if (error.name === 'InteractionRequiredAuthError') {
				// 사용자 인터랙션이 필요한 경우, redirect 또는 popup을 통해 토큰을 요청
				const response = await msalInstance.acquireTokenRedirect(request);
				accessToken.value = response.accessToken;
				window.sessionStorage.setItem('ssoCompCd', response.state);

				await useSystemStore().login(response.accessToken, '');
			} else {
				console.error(error);
			}
		}
	}
};

onMounted(async () => {
	try {
		const tokenResponse = await msalInstance.handleRedirectPromise();
		if (tokenResponse) {
			const params = {
				lgnId: tokenResponse.account.username,
			};

			await apiCall
				.post('CM_API/cm/global/common/auth/getRprsCompCd', params)
				.then(res => {
					compCd.value = res.data.data.compCd;
				});
			await acquireToken();

			await apiCall.post('CM_API/cm/global/common/auth/ssoLogin').then(res => {
				if (!res.data.data) {
					logout();
				}
			});
			await useSystemStore().load();
			localStorage.setItem('ssoYn', 'Y');
			nextTick(() => {
				router.push({
					path: MENU_INFO.HOME_PATH,
				});
			});
		} else {
			const accounts = msalInstance.getAllAccounts();
			if (accounts.length === 0) {
				// 사용자가 로그인하지 않았을 경우 로그인 처리
				login();
			} else if (!useSystemStore().sessionCheck()) {
				login();
			}
		}
		isInitialized.value = true;
	} catch (error) {
		console.error('로그인 처리 중 오류 발생:', error);
	}
});
</script>
