<template>
	<aside>
		<!-- 기본 : logo-icon, POS : logo-icon pos, POL : logo-icon pol -->
		<a
			:class="{
				'logo-icon': true,
				pos: systemStore.g_userSeCd == '02',
				pol: systemStore.g_userSeCd == '03',
			}"
			@click="onClickMoveHome"
		/>
		<div class="aside">
			<!-- LNB -->
			<menu ref="refAsideMenu" class="aside-section">
				<ul
					v-for="(menu, index) in menus"
					:id="'menu-1-depth-' + menu.menuId"
					:key="menu.menuId"
					class="depth-1"
				>
					<li
						:class="{
							open: menu.isOpen && openedMenus.includes(menu),
							close: !menu.isOpen,
						}"
						@click="onClickOpenMenu(menu, index)"
						@mouseover.stop.prevent="isHover = menu.menuId"
						@mouseenter.stop.prevent="isHover = menu.menuId"
						@mouseleave.stop.prevent="isHover = ''"
					>
						<a>
							<FIcon
								:icon="
									menu.isOpen || isHover === menu.menuId
										? `${menu.menuIcon}-hover`
										: menu.menuIcon
								"
							/>
							<span class="menu-name">
								{{ menu.menuNm }}
							</span>
							<span class="ico-arrow"></span>
						</a>
						<p class="menu-name-hover">
							<span>
								{{ menu.menuNm }}
							</span>
						</p>
					</li>

					<ul v-if="menu.isOpen" class="depth-2">
						<li
							v-for="middle in menu.children"
							:id="'menu-2-depth-' + middle.menuId"
							:key="middle.menuId"
							:class="{
								open: openedMenus.includes(middle.menuId),
								close: !openedMenus.includes(middle.menuId),
								active: selectedMenus?.menuId === middle.menuId,
							}"
							@click.stop="
								$event =>
									commonUtils.gfn_isNotEmpty(middle?.children)
										? onClickFloatMenu($event, middle)
										: onClickMoveMenu(middle)
							"
						>
							<a>
								<span class="menu-name">{{ middle.menuNm }}</span>
								<span v-if="middle.children?.length" class="ico-plus"></span>
							</a>
						</li>
					</ul>
				</ul>
			</menu>
		</div>

		<!-- LNB Expand -->
		<div
			v-if="selectedMenus"
			v-click-outside="onClickFloatMenu"
			class="aside-float"
		>
			<!-- :style="{ width: deth3Width + 20 + 'px' }" -->
			<div class="af-top">
				<FButton @click="onClickExpandAll">{{ t('BTN.CM.LNB.001') }}</FButton>
				<FButton @click="onClickCollapseAll">{{ t('BTN.CM.LNB.002') }}</FButton>
				<!-- <div @click="logDivWidth()">계산</div> -->
			</div>

			<!-- 선택한 메뉴의 소메뉴가 없는 경우 -->
			<div v-if="!selectedMenus.children?.length" class="noData">
				메뉴가 존재하지<br />않습니다.
			</div>

			<ul id="depth3" class="depth-3">
				<!-- isLeaf는 소메뉴(3depth)가 바로 이동하는 메뉴일 때입니당 -->
				<li
					v-for="sub in selectedMenus.children"
					:key="sub.menuId"
					:class="{
						open: sub.isOpen,
						close: !sub.isOpen,
						isLeaf:
							sub?.children?.length === 0 ||
							commonUtils.gfn_isEmpty(sub?.children),
					}"
					@click.stop="
						sub?.children?.length ? onClickFloatOpen(sub) : onClickMoveMenu(sub)
					"
				>
					<a>
						<span class="menu-name">{{ sub.menuNm }}</span>
						<span v-if="sub?.children?.length" class="ico-arrow"></span>
					</a>

					<ul v-if="sub.isOpen && sub?.children?.length" class="depth-4">
						<li
							v-for="detail in sub.children"
							:key="detail.menuId"
							@click.stop="onClickMoveMenu(detail)"
						>
							<a>
								<span class="menu-name">{{ detail.menuNm }}</span>
							</a>
						</li>
					</ul>
				</li>
			</ul>
		</div>

		<div class="dim">딤영역</div>
	</aside>
</template>

<script setup>
import useSystemStore from '@/stores/systemStore';
import commonUtils from '@fila/lib/utils/commonUtils';
import useMenuStore from '@/stores/menuStore';
import tabUtils from '@fila/lib/utils/tabUtils';
import { MENU_INFO } from '@fila/lib/utils/constCommon';
import { nextTick } from 'vue';

const { t } = useI18n();
const router = useRouter();
const systemStore = useSystemStore();
const menuStore = useMenuStore();

const selectedMenus = ref(null); // 열려있는 중메뉴

const isHover = ref('');
const menus = ref(menuStore.menuTree.children); // 전체 메뉴
const openedMenus = ref(systemStore.openedMenus); // 열려있는 대메뉴

const refAsideMenu = ref(null);
let myMenuList = [];

// LNB를 여닫을 때마다 확인
watch(
	() => systemStore.isFold,
	newValue => {
		if (newValue) {
			menus.value.map(menu => {
				menu.isOpen = false;
			});
		} else {
			openedMenus.value.map(open => {
				open.isOpen = true;
			});
		}
	},
);

/**
 * 대메뉴 열기
 * @param {object} menu
 */
function onClickOpenMenu(menu, menuIdx) {
	if (systemStore.isFold) {
		// LNB 접혀있는 경우 펼치기
		systemStore.isFold = false;
	}

	let index = openedMenus.value.findIndex(open => open.menuId === menu.menuId);

	if (index >= 0) {
		// 열려있는 메뉴 클릭 시 닫힘
		menus.value[menuIdx].isOpen = false;
		openedMenus.value.splice(index, 1);
	} else {
		// 닫혀있는 메뉴 클릭 시 열림
		menus.value[menuIdx].isOpen = true;
		openedMenus.value.push(menu);

		let activeElement = refAsideMenu.value.querySelector(
			'#menu-1-depth-' + menu?.menuId,
		);

		if (activeElement) {
			nextTick(() => {
				activeElement?.scrollIntoView({ block: 'center', behavior: 'smooth' });
			});
		}
	}
}

/**
 * Floating Menu 열기/닫기
 * @param {object} menu
 */
function onClickFloatMenu(event, menu) {
	if (!menu || menu?.menuId === selectedMenus.value?.menuId) {
		selectedMenus.value = null;
	} else {
		selectedMenus.value = menu;
	}
}

/**
 * Floating Menu - 전체 메뉴 펼치기
 */
function onClickExpandAll() {
	selectedMenus.value.children.map(menu => {
		menu.isOpen = true;
	});
}

/**
 * Floating Menu - 전체 메뉴 닫기
 */
function onClickCollapseAll() {
	selectedMenus.value.children.map(menu => {
		menu.isOpen = false;
	});
}

/**
 * Floating Menu - 선택한 메뉴 열기/닫기
 * @param {object} menu
 * @param {number} menuIdx
 */
function onClickFloatOpen(menu) {
	menu.isOpen = !menu.isOpen;
}

/**
 * 로고 클릭 시 홈으로 이동
 */
function onClickMoveHome() {
	// 로고 클릭으로 새로고침 : cachedTabs/openedTabs 초기화
	// systemStore.openedTabs.splice(1);
	router.push({ path: MENU_INFO.HOME_PATH });
}

/**
 * 메뉴 이동
 * @param {object} menu
 */
async function onClickMoveMenu(menu) {
	await tabUtils.moveMenu(menu);
	selectedMenus.value = null;
	let childrenData;
	if (menus.value[0]?.children[1]?.children[0]?.children) {
		childrenData = [...menus.value[0].children[1].children[0].children];
	} else {
		childrenData = [];
	}

	if (commonUtils.gfn_isEmpty(childrenData)) {
		childrenData = [menu];
	} else {
		let index = childrenData.findIndex(item => item.menuId === menu.menuId);
		if (index !== -1) {
			childrenData.splice(index, 1);
		}
		childrenData.unshift(menu);
		if (childrenData.length > 10) {
			childrenData.pop();
		}
	}
	menus.value[0].children[1].children[0].children = childrenData;
}

async function reloadMyMenusEventHandler() {
	myMenuList = await useSystemStore().loadFavorite();
	menus.value[0].children[0].children[0].children = myMenuList;
}

onMounted(() => {
	window.addEventListener('reloadMymenu', reloadMyMenusEventHandler);
});

onBeforeUnmount(() => {
	window.removeEventListener('reloadMymenu', reloadMyMenusEventHandler);
});
</script>

<style lang="scss" scoped>
aside {
	//height: 100%;
	height: calc(100% - 67px);
	background: var(--aside-bg-gra-start);
}
:root:has(.light) {
	aside {
		background: #fff;
	}
}
aside:has(.aside-float) {
	.dim {
		opacity: 1;
		z-index: 3;
	}
}
.dim {
	position: absolute;
	top: 0;
	left: 240px;
	width: calc(100% - 240px);
	height: 100%;
	font-size: 0;
	background-color: var(--aside-dim);
	opacity: 0;
	z-index: -1;
	transition: all 0.3s;
}

.aside {
	color: #fff;
	position: relative;
	z-index: 1001;
	background: var(--aside-bg-gra-start);
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%; //zoom관련 수정
}
.logo-icon {
	position: relative;
	padding: 16px 20px 0;
	display: block;
	width: 100%;
	height: 67px;
	background: transparent url(/images/aside-fila-ci.svg) no-repeat top 16px left
		16px;
	&.pos {
		background: transparent url(/images/aside-fila-ci-pos.svg) no-repeat top
			16px left 21px;
	}
	&.pol {
		background: transparent url(/images/aside-fila-ci-pol.svg) no-repeat top
			18px left 11px;
		background-size: 215px;
	}
}
.aside-wrap {
	&.off {
		.logo-icon {
			width: 42px;
			&.pol {
				width: 40px;
			}
		}
	}
}
:root:has(.light) {
	.logo-icon {
		border-right: 1px solid #002053;
		background: #fff url(/images/aside-fila-ci-light.svg) no-repeat top 16px
			left 16px;
		&.pos {
			background: transparent url(/images/aside-fila-ci-pos-light.svg) no-repeat
				top 16px left 21px;
		}
		&.pol {
			background: transparent url(/images/aside-fila-ci-pol-light.svg) no-repeat
				top 18px left 11px;
			background-size: 215px;
		}
	}
	.aside-wrap {
		&.off {
			.logo-icon {
				border-right: none;
				&:after {
					content: '';
					position: absolute;
					width: 1px;
					height: 67px;
					right: -22px;
					top: 0;
					background-color: #002053;
				}
				&.pol {
					&:after {
						right: -24px;
					}
				}
			}
		}
	}
}
:root:has(.darkMode) {
	.logo-icon {
		background: var(--aside-bg-gra-start) url(/images/aside-fila-ci-dark.svg)
			no-repeat top 16px left 16px;
		&.pos {
			background: transparent url(/images/aside-fila-ci-pos-dark.svg) no-repeat
				top 16px left 21px;
		}
		&.pol {
			background: transparent url(/images/aside-fila-ci-pol-dark.svg) no-repeat
				top 18px left 11px;
			background-size: 215px;
		}
	}
	.aside-wrap {
		&.off {
			.logo-icon {
			}
		}
	}
}
.aside-float {
	position: fixed;
	top: 45px;
	left: 240px;
	background-color: var(--background);
	color: var(--basic-text);
	width: fit-content;
	height: calc(100% - 45px);
	padding: 20px;
	z-index: 1000;
	.af-top {
		position: absolute;
		z-index: 1001;
		button {
			width: 100px;
		}
	}
	.depth-3 {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		flex-direction: row;
		writing-mode: vertical-lr;
		flex-wrap: wrap;
		height: 100%;
		width: fit-content;
		padding: 42px 20px 20px 20px;
		background-color: var(--background);
		box-shadow: 10px 0 20px 0 rgba(0, 0, 0, 0.2);
		transition: all 0.3s;
		> li {
			font-size: 13px;
			font-weight: 800;
			color: var(--heading-text);
			margin-top: 20px;
			writing-mode: initial;
			width: 220px;
			> a {
				width: 220px;
				position: relative;
				padding-left: 10px;
				padding-right: 30px;
				cursor: pointer;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				line-height: 1.5;
				&:before {
					content: '-';
					position: absolute;
					left: 0;
				}
				> span:first-child {
					position: relative;
					display: block;
					width: 100%;
					&:before,
					&:after {
						content: '';
						position: absolute;
						top: 9px;
						width: 6px;
						height: 0.11em;
						border-radius: 1px;
						background-color: var(--basic-text);
						transition: all 0.3s;
					}
					&:before {
						right: -10px;
						transform: rotate(-40deg);
					}
					&:after {
						right: -14px;
						transform: rotate(40deg);
					}
				}
			}
			&.close {
				> a {
					> span:first-child {
						&:before {
							transform: rotate(40deg);
						}
						&:after {
							transform: rotate(-40deg);
						}
					}
				}
			}

			&.isLeaf {
				> a {
					> span:first-child {
						&:before,
						&:after {
							display: none;
						}
					}
				}
			}
		}
	}
	.depth-4 {
		> li {
			font-size: 13px;
			font-weight: 400;
			color: var(--basic-text-gray);
			padding: 7px 0;
			&:first-child {
				padding-top: 15px;
			}
			> a {
				width: 220px;
				padding-left: 10px;
				padding-right: 20px;
				text-overflow: ellipsis;
				overflow: hidden;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				line-height: 1.4;
				cursor: pointer;
				transition: all 0.3s;
				&:hover,
				&:focus {
					color: var(--aside-menu-depth4-hover);
				}
			}
		}
	}
}
.af-top {
	display: flex;
	gap: 5px;
}
.aside-section {
	position: relative;
	top: 0;
	height: 100vh;
	ul {
		li {
			a {
				padding: 0 20px;
				display: flex;
				align-items: center;
				width: 100%;
				.menu-name {
					// overflow: hidden;
					// white-space: nowrap;
					// text-overflow: ellipsis;
					// max-height: 21px;
					min-height: 21px;
					width: 118px;
					color: var(--menu-name);
					transition: all 0.3s;
				}
				.ico-arrow {
					margin-left: auto;
					display: block;
					width: 12px;
					height: 12px;
					background-size: 12px 12px;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url(/images/aside-ico-arrow-down.svg);
					opacity: 0.5;
				}
			}
			&.close,
			&.open {
				.ico-arrow {
					transform: rotate(180deg);
					transition: all ease 0.3s;
				}
			}
			&.close {
				.ico-arrow {
					transform: rotate(360deg);
				}
			}
			&.open,
			&:hover {
				a {
					.menu-name {
						color: var(--menu-name-on);
					}
				}
				.ico-arrow {
					opacity: 1;
				}
			}
		}
		&.depth-1 {
			> li {
				font-size: 15px;
				font-weight: bold;
				cursor: pointer;
				padding: 18px 0;
				position: relative;
				a {
					> img {
						max-width: none;
						margin-right: 20px;
						flex: none;
					}
				}
				// 메뉴 접혔을때 메뉴명 툴팁스타일
				.menu-name-hover {
					position: absolute;
					font-size: 12px;
					font-weight: 400;
					width: auto;
					height: 26px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					left: 30px;
					top: -1px;
					opacity: 0;
					transform: scaleX(0.3);
					transform-origin: left;
					transition: all 0.3s;
					> span {
						color: #ffffff;
						background-color: rgba(0, 0, 0, 0.7);
						display: flex;
						align-items: center;
						justify-content: center;
						height: 26px;
						border-radius: 13px;
						padding: 0 6px;
						white-space: nowrap;
					}
				}
			}
			&:last-child {
				padding-bottom: 30px;
			}
		}
		.depth-2 {
			background-color: var(--aside-menu-depth-bg);
			li {
				font-size: 14px;
				font-weight: 400;
				color: rgba(255, 255, 255, 0.7);
				&.active {
					color: rgba(255, 255, 255, 1);
				}
				.menu-name {
					// min-height: 36px;
					// max-height: 36px;
					// line-height: 36px;
					width: 150px;
				}
				> a {
					padding-right: 9px;
					padding-left: 22px;
					min-height: 36px;
					position: relative;
					cursor: pointer;
					transition: all 0.3s;
					&:before {
						content: '';
						position: absolute;
						left: 10px;
						width: 3px;
						height: 3px;
						background-color: #fff;
						border-radius: 1px;
						top: calc(50% - 1px);
					}
				}
			}
		}
		.depth-2 {
			padding: 14px 0;
			border-top: 1px solid var(--aside-menu-depth-border);
			border-bottom: 1px solid var(--aside-menu-depth-border);
			.ico-plus {
				margin-left: auto;
				display: block;
				width: 12px;
				height: 12px;
				background-size: 12px 12px;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url(/images/aside-ico-plus.svg);
			}
			> li {
				padding: 0 10px;
				&.active {
					.ico-plus {
						background-image: url(/images/aside-ico-minus.svg);
					}
					> a {
						background-color: rgba(255, 255, 255, 0.1);
						border-radius: 4px;
						> span {
							color: #fff;
						}
					}
				}
			}
		}
	}
}

:root:has(.light) {
	.aside {
		color: #002053;
		position: relative;
		z-index: 1001;
		background-image: linear-gradient(to bottom, #fff, #fff 71%, #fff);
		overflow-x: hidden;
		overflow-y: auto;
		border-right: 1px solid #002053;
	}
	.aside-section {
		ul {
			li {
				a {
					.ico-arrow {
						background-image: url(/images/aside-ico-arrow-down-light.svg);
					}
				}
			}
			.depth-2 {
				background-color: #f4f5f6;
				border-top: 1px solid #002053;
				border-bottom: 1px solid #002053;
				li {
					color: #002053;
					&.active {
						a {
							background-color: #181b56;
						}
						.ico-plus {
							background-image: url(/images/aside-ico-minus.svg);
						}
					}
				}
				.close {
					> a {
						&:before {
							background-color: #fff;
						}
					}
					.ico-plus {
						background-image: url(/images/aside-ico-plus-light.svg);
					}
				}
			}
		}
	}
}

.noData {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 15px;
	font-weight: 500;
	color: var(--basic-text);
}

.aside-wrap {
	&.off {
		.aside {
			overflow: inherit;
		}
		.aside-section {
			ul.depth-1 {
				height: 60px;
				display: flex;
				align-items: center;
				> li {
					padding: 0;
					a {
						padding: 0 0 0 20px;
					}
					&:hover {
						// 메뉴 접혔을때 메뉴명 툴팁스타일
						.menu-name-hover {
							opacity: 1;
							transform: scaleX(1);
							left: 50px;
						}
					}
					.menu-name,
					.ico-arrow {
						display: none;
					}
				}
				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
}
:root:has(.darkMode) {
	.aside-wrap {
		.aside-section {
			ul.depth-1 {
				> li {
					// 메뉴 접혔을때 메뉴명 툴팁스타일
					.menu-name-hover {
						> span {
							color: #111111;
							background-color: rgba(0255, 255, 255, 0.7);
							font-weight: 600;
						}
					}
				}
			}
		}
	}
}
</style>
