<!--------------------------------------------------------------------
	파일   : EMHrApntCnfm.vue
	작성   : CJ Developer
	설명	 : 인사발령확인
---------------------------------------------------------------------->
<template>
	<div class="psonappo">
		<div>
			<div class="con-area">
				<p class="fs-13 fc-blue">
					<!-- 인사발령 여부를 확인합니다. -->
					{{ t('MSG.HR.COM.001') }}
				</p>
				<p class="fs-13">
					<!-- 본인의 회사, 이름, 생년월일, 성별, 사번을 입력해주세요. 해당 정보를
					모르시는 경우엔 인사담당자분께 문의하시기 바랍니다. -->
					{{ t('MSG.HR.COM.002') }}
				</p>
			</div>
			<FForm ref="searchRef" v-model="searchModel" form-only>
				<FFormRow is-grid-cols="1">
					<FFormCol>
						<div class="login-lang">
							<h5>Language</h5>
							<UserSetting mode="locale" from="login" />
						</div>
					</FFormCol>
					<FFormCol>
						<!-- 회사 -->
						<FSelect
							id="compCd"
							v-model="searchModel.compCd"
							v-validate:required
							:label="t('LBL.CM.COMPANY.001')"
							:options="cmCdList.COMP_CD"
							empty="SELECT"
						/>
					</FFormCol>
					<FFormCol>
						<!-- 이름 -->
						<FInput
							id="empNm"
							v-model="searchModel.empNm"
							v-validate:required
							:label="t('LBL.HR.EMP.024')"
							max-length="50"
						/>
					</FFormCol>
					<FFormCol>
						<!-- 생년월일 -->
						<FDate
							id="brdt"
							v-model="searchModel.brdt"
							v-validate:required
							:label="t('LBL.HR.EMP.020')"
						/>
					</FFormCol>
					<FFormCol>
						<!-- 성별 -->
						<FSelect
							id="gndrSeCd"
							v-model="searchModel.gndrSeCd"
							v-validate:required
							:label="t('LBL.HR.EMP.021')"
							:options="cmCdList.GNDR_SE_CD"
							empty="SELECT"
						/>
					</FFormCol>
					<FFormCol>
						<!-- 사번 -->
						<FInput
							id="empNo"
							v-model="searchModel.empNo"
							v-validate:required
							:label="t('LBL.HR.COM.073')"
							max-length="10"
						/>
					</FFormCol>
				</FFormRow>
			</FForm>
			<div class="con-area">
				<p class="fs-13 fc-blue">
					<!-- 인증에 성공했습니다. -->
				</p>
			</div>
			<div class="btn-area">
				<FButton @click="fn_onClickCertReq">
					<!-- 인증요청 -->
					{{ t('LBL.HR.COM.037') }}
				</FButton>
				<FButton primary :disabled="toggle" @click="fn_onClickCnfm">
					<!-- 확인 -->
					{{ t('LBL.HR.COM.060') }}
				</FButton>
			</div>
		</div>
	</div>
</template>

<script setup>
import VueCookies from 'vue-cookies';
import { DEFAULT_LOCALE } from '@fila/lib/utils/constCommon';
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useMessage } from '@fila/lib/plugins/message/message';
import UserSetting from '@/layout/header/UserSetting.vue';

const router = useRouter();
const route = useRoute();

const { t } = useI18n();
const message = useMessage();
const toggle = ref(true); // 인증요청에 따른 버튼 제어
const cmCdList = ref([]);
let APP_KEY = '';
let TEMP_KEY = '';

/* 조회조건 */
const searchRef = ref(null);
// const searchModel = ref({
// 	compCd: 'D001',
// 	empNm: '윤윤수',
// 	empNo: '1910000',
// 	brdt: '1946-09-09',
// 	gndrSeCd: 'M',
// });

const searchModel = ref({
	compCd: '',
	empNm: '',
	empNo: '',
	brdt: '',
	gndrSeCd: '',
});

/**
 * 인증요청 클릭
 */
async function fn_onClickCertReq() {
	if (!searchRef.value.validate()) return;
	// APP KEY => 공통코드: SYS_APP_KEY, 공통상세코드: 02

	if (commonUtils.gfn_isEmpty(APP_KEY)) {
		APP_KEY =
			(await commonUtils.gfn_getCmCd('SYS_APP_KEY', '02'))[0]?.rmrk1 ?? '';
	}

	apiCall
		.get(
			'HR_API/hr/global/public/getCertReq',
			{
				...searchModel.value,
			},
			{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
		)
		.then(res => {
			let existGbn = res.data.data.EXIST;
			console.log('existGbn ===> {}', existGbn);

			if (existGbn == 'S') {
				message.showAlert({
					message: t('MSG.HR.COM.052'), // 인증에 성공했습니다.
				});

				toggle.value = false;
			} else if (existGbn == 'X') {
				// 신청대상자가 아닙니다.
				message.showAlert({
					message: t('MSG.HR.ERR.032'),
				});
				toggle.value = true;
			} else {
				// 데이터가 존재하지 않습니다.
				message.showAlert({
					message: t('MSG.CM.ERR.008'),
				});
				toggle.value = true;
			}
		});
}

/**
 * 확인 클릭
 */
function fn_onClickCnfm() {
	apiCall
		.get('HR_API/hr/global/public/getEmp', {
			...searchModel.value,
		})
		.then(res => {
			console.log(res);
			let data = res.data.data.EMP;
			console.log(data.hrBuCd);

			let param = {};
			param.langCd = VueCookies.get('_locale') ?? DEFAULT_LOCALE;
			param.compCd = searchModel.value.compCd;
			param.empNo = searchModel.value.empNo;
			param.tempKey = TEMP_KEY;
			param.hrBuCd = data.hrBuCd;

			router.push({
				name: 'emhrinforegaply',
				state: { data: param },
			});
		});

	// console.log('param >> ' + JSON.stringify(param));

	// 인사정보등록신청(외부접속) 페이지로 링크 >> 회사, 사번 파라미터 가지고 )
	// commonUtils.gfn_moveTabMenuByUrl('/emhrinforegaply', param);

	// router.push({
	// 	path: '/emhrinforegaply',
	// 	query: param,
	// });
}

onBeforeMount(async () => {
	cmCdList.value = await commonUtils.gfn_getMultiCmCd(
		[
			'COMP_CD', // 회사코드
			'GNDR_SE_CD', // 성별구분코드
		],
		{ compCd: 'D001' },
	);
	TEMP_KEY = route.query.TEMP_KEY;
});
</script>

<style lang="scss" scoped>
.psonappo {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	> div {
		margin-top: -5%;
		width: 400px;
		padding: 20px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 3px 3px 8px 6px rgba(0, 0, 0, 0.1);
	}
	.con-area {
		padding: 10px 0;
	}
	.btn-area {
		display: flex;
		justify-content: center;
		gap: 6px;
	}
	p {
		color: var(--basic-text);
	}
	.fc-blue {
		color: var(--color-primary);
		font-weight: 600;
	}
}
</style>
