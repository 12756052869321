<template>
	<div></div>
</template>

<script setup>
import VueCookies from 'vue-cookies';

onMounted(() => {
	document.cookie =
		'accessToken=; path=/; domain=dev-erp.filadev.com; expires=Thu, 01 Jan 1970 00:00:00 GMT';
	document.cookie =
		'refreshToken=; path=/; domain=dev-erp.filadev.com; expires=Thu, 01 Jan 1970 00:00:00 GMT';
});
</script>
