import { createRouter, createWebHistory } from 'vue-router';
import routes from '~pages';
import { v4 as uuidv4 } from 'uuid';

import commonUtils from '@fila/lib/utils/commonUtils';
import { MENU_INFO } from '@fila/lib/utils/constCommon';

import Error from '@/views/Error.vue';
import Login from '@/views/Login.vue';
import AzureADLogin from '@/views/AzureADLogin.vue';
import POLLogin from '@/views/POLLogin.vue';
import POSLogin from '@/views/POSLogin.vue';
import NotFound from '@/views/NotFound.vue';
import OnLogin from '@/views/OnLogin.vue';
import SSOLogout from '@/views/Logout.vue';
import EMHrApntCnfm from '@/views/EMHrApntCnfm.vue';
import EMHrInfoRegAply from '@/views/EMHrInfoRegAply.vue';

import systemStorage from '@fila/lib/shared/systemStorage';
import useSystemStore from '@/stores/systemStore';

import { useAuthPop } from '@/plugins/authPop/authPop';

const authPop = useAuthPop();

const cm = await import('@fila/cm/route')
	.then(res => res.default)
	.catch(e => {
		console.log('cm route not loaded', e);
		return [];
	});

const fcm = await import('@fila/fcm/route')
	.then(res => res.default)
	.catch(e => {
		console.log('fcm route not loaded', e);
		return [];
	});

const hr = await import('@fila/hr/route')
	.then(res => res.default)
	.catch(e => {
		console.log('hr route not loaded', e);
		return [];
	});

const scm = await import('@fila/scm/route')
	.then(res => res.default)
	.catch(e => {
		console.log('scm route not loaded', e);
		return [];
	});

const scmKr = await import('@fila/scm-kr/route')
	.then(res => res.default)
	.catch(e => {
		console.log('scm-kr route not loaded', e);
		return [];
	});

const posKr = await import('@fila/pos-kr/route')
	.then(res => res.default)
	.catch(e => {
		console.log('pos-kr route not loaded', e);
		return [];
	});

const crmKr = await import('@fila/crm-kr/route')
	.then(res => res.default)
	.catch(e => {
		console.log('crm-kr route not loaded', e);
		return [];
	});

/**
 * 커스텀 Route
 */
export const customRoute = [
	{
		path: '/',
		name: 'sh',
		redirect: 'login',
		meta: { auth: true, layout: true },
		children: [
			...routes.filter(
				route =>
					route.path !== '/login' &&
					!route.path.startsWith('/samples') &&
					route.path !== '/login-pos' &&
					route.path !== '/login-pol' &&
					!route.path.startsWith('/em'),
			),
			...routes.filter(route => {
				if (route.path.startsWith('/samples')) {
					return { ...route, meta: { auth: false, layout: true } };
				}
			}),
			...cm,
			...fcm,
			...hr,
			...scmKr,
			...scm,
			...posKr,
			...crmKr,
		],
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		beforeEnter: (to, from, next) => {
			return requireAuth(to, from, next);
		},
	},
	{
		path: '/emhrapntcnfm',
		name: 'emhrapntcnfm',
		component: EMHrApntCnfm,
		meta: { auth: false },
	},
	{
		path: '/emhrinforegaply',
		name: 'emhrinforegaply',
		component: EMHrInfoRegAply,
		meta: { auth: false },
	},
	{
		path: '/ssoLogout',
		name: 'ssoLogout',
		component: SSOLogout,
		meta: { auth: false },
	},
	{
		path: '/error',
		name: 'error',
		component: Error,
		meta: { auth: false },
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'notFound',
		component: NotFound,
		meta: { auth: false },
	},
	{
		path: '/samples',
		name: 'sample',
		meta: { auth: false },
		children: [
			...routes.filter(
				route =>
					route.path.startsWith('/samples') && !route.path.includes('layout'),
			),
		],
	},
	{
		path: '/login-ad',
		name: 'login-ad',
		component: AzureADLogin,
		beforeEnter: (to, from, next) => {
			return requireAuth(to, from, next);
		},
	},
	{
		path: '/login-pol',
		name: 'login-pol',
		component: POLLogin,
		beforeEnter: (to, from, next) => {
			return requireAuth(to, from, next);
		},
	},
	{
		path: '/login-pos',
		name: 'login-pos',
		component: POSLogin,
		beforeEnter: (to, from, next) => {
			return requireAuth(to, from, next);
		},
	},
	{
		path: '/login-temp',
		name: 'login-temp',
		component: OnLogin,
		beforeEnter: (to, from, next) => {
			return requireAuth(to, from, next);
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes: customRoute,
});

// router.onError(e => {
// 	console.log('화면 에러 발생으로 인한 router error', e);
// 	router.push({ name: 'error', query: { type: 'error' } });
// });
router.onError(e => {
	if (
		e.message.includes('Failed to fetch dynamically imported module') ||
		e.message.includes('Importing a module script failed')
	) {
		if (!e.to?.fullPath) {
			console.log('ttest == 111');
			window.location.reload();
		} else {
			console.log('ttest == 222');
			window.location = e.to.fullPath;
		}
	} else {
		console.log('화면 에러 발생으로 인한 router error', e);
		router.push({ name: 'error', query: { type: 'error' } });
	}
});

function removeMultipleCSS() {
	// 'https://stg-erp.filadev.com/'로 시작하고, 'lib'나 '/assets/style'로 끝나는 경로는 제외
	const cssLinks = document.querySelectorAll(
		`link[href*="${
			import.meta.env.VITE_URL
		}"]:not([href*="lib"]):not([href="/assets/style"])[href*="/assets/style"]`,
	);

	// 찾은 모든 링크 태그를 제거
	cssLinks.forEach(linkTag => {
		if (linkTag) {
			linkTag.disabled = true;
		}
	});
}

function enableCSSForModule(moduleName, subModule) {
	// 예: /scm/kr/assets/style 또는 /scm/assets/style 등을 활성화
	let cssLink;
	if (subModule === 'global' || moduleName === 'cm') {
		subModule = '';
	}
	if (subModule) {
		// subModule이 있는 경우 예: /scm/kr
		cssLink = document.querySelector(
			`link[href*="${
				import.meta.env.VITE_URL
			}/${moduleName}/${subModule}/assets/style"]`,
		);
	} else {
		// subModule이 없는 경우 예: /scm
		cssLink = document.querySelector(
			`link[href*="${import.meta.env.VITE_URL}/${moduleName}/assets/style"]`,
		);
	}

	if (cssLink) {
		cssLink.disabled = false; // 해당 모듈의 CSS 다시 활성화
	}
}

router.beforeEach(async (to, from, next) => {
	removeMultipleCSS();

	const pathSegments = to.fullPath.split('/').filter(Boolean); // 빈 문자열 필터링
	const moduleName = pathSegments[0];
	const subModule = pathSegments[1];
	enableCSSForModule(moduleName, subModule);

	useSystemStore().setComponentKey();

	// 1. 권한 인증이 필요한 경우
	if (to.meta.auth) {
		if (!useSystemStore().sessionCheck()) {
			// 1-1. 세션이 유효하지않은 경우
			useSystemStore().logout();
		}
		// to.hash = '';

		// 1-2. 탭 추가
		// 캐시(스토어)에 메뉴 데이터가 있다면 이를 사용하고 없다면 indexedDB에서 읽는다.
		const isHome = to.path === MENU_INFO.HOME_PATH;

		// URL의 hash property로 부터 메뉴 ID를 찾는다.
		const menuId = to.hash?.match(/^#[A-Z]+[0-9]+$/)
			? to.hash.substring(1)
			: null;
		// 메뉴 ID가 있다면 메뉴ID로 찾고, 없다면 패스로 찾는다.
		const getMenu = isHome
			? null
			: getCachedMenuData(to.path, menuId) ||
				(await getMenuData(to.path, menuId));

		if (getMenu?.rnd2CertYn == 'Y') {
			try {
				await authPop.showAuthPop({
					onConfirm: () => {
						handleMenu(to, next, getMenu, isHome, menuId);
					},
					onCancel: () => {
						if (from.path == '/') {
							return next(MENU_INFO.HOME_PATH);
						} else {
							next(false);
						}
					}, // add next(false) to cancel navigation
				});
			} catch (error) {
				console.error('authPop error:', error);
				next(false);
			}
		} else {
			handleMenu(to, next, getMenu, isHome, menuId);
		}
	} else {
		next();
	}
});

function handleMenu(to, next, getMenu, isHome, menuId) {
	if (!isHome && commonUtils.gfn_isEmpty(getMenu)) {
		return next({ name: MENU_INFO.NOT_FOUND_NAME });
	} else {
		// 열린 탭을 찾는다.
		const openedTab = menuId
			? useSystemStore().isOpenTabByMenuId(menuId)
			: useSystemStore().isOpenTabByPath(to.path);

		if (commonUtils.gfn_isEmpty(openedTab)) {
			addTabMenu({ ...to, uuid: uuidv4(), current: true, fxYn: 'N' }, getMenu);
		} else if (!useSystemStore().isOldMenu) {
			addTabMenu({ ...to, uuid: uuidv4(), fxYn: 'N' }, getMenu);
			useSystemStore().setOldMenu(true);
		} else {
			useSystemStore().openedTabs = useSystemStore().openedTabs.map(_tab => {
				if (
					(menuId && _tab.menuId === menuId) ||
					(!menuId && _tab.path === to?.fullPath)
				) {
					if (commonUtils.gfn_isEmpty(_tab?.component)) {
						return { ..._tab, current: true, component: to.name };
					} else {
						return { ..._tab, current: true };
					}
				} else {
					return { ..._tab, current: false };
				}
			});
			useSystemStore().setNowUUID(openedTab.uuid);
		}

		to.meta = { ...to.meta, ...getMenu };

		useSystemStore().g_menuId = getMenu?.menuId ?? 'HOME';
		useSystemStore().currentMenu = getMenu;
	}
	next();
}

// 캐시에서 메뉴 데이터를 가져온다.
function getCachedMenuData(path, menuId) {
	return useSystemStore().getCachedMenuData(menuId || path);
}

/**
 * 메뉴 트리 데이터 가져오기
 * @param {*} path web url path 정보
 * @returns
 */
async function getMenuData(path, menuId) {
	try {
		const menuData = menuId
			? await systemStorage.getMenuById(menuId)
			: await systemStorage.getMenuByUrl(path);
		// 찾은 메뉴 데이터를 캐시한다.
		useSystemStore().setCacheMenuData(path, menuData);
		return menuData;
	} catch (e) {
		console.log('getMenuData', e);
		return null;
	}
}

function requireAuth(to, from, next) {
	if (useSystemStore().sessionCheck()) {
		return next(MENU_INFO.HOME_PATH);
	}
	return next();
}

/**
 * 탭 메뉴 추가
 * @param {*} menuInfo title(=메뉴명), path(=소문자), name(=component), page 정보
 * @param {*} menuData menuId, menuNm, menuPathNm(=대문자 포함) 외 정보
 */
function addTabMenu(menuInfo, menuData) {
	const systemStore = useSystemStore();

	if (menuInfo.path === MENU_INFO.HOME_PATH) {
		return;
	}

	systemStore.setNowUUID(menuInfo.uuid);
	systemStore.openedTabs.push({
		menuId: menuData.menuId,
		title: menuData.menuNm,
		path: menuInfo.path,
		component: menuInfo.name,
		current: menuInfo.current ?? true,
		uuid: menuInfo.uuid,
		fxYn: 'N',
	});
}

export default router;
