<template>
	<div class="message">
		<div class="message-container">
			<h3>{{ authTitle }}</h3>
			<div class="logconwrap">
				<div class="logcon">
					<!-- 회사 -->
					<label for="compNm">{{
						`${i18n.i18n.global.t('LBL.CM.COMPANY.001')}`
					}}</label>
					<FInput
						id="compNm"
						v-model="compNm"
						no-label
						name="compNm"
						disabled
					/>
				</div>
				<div class="logcon">
					<!-- 아이디 -->
					<label for="lgnId">{{
						`${i18n.i18n.global.t('LBL.CM.ID.001')}`
					}}</label>
					<FInput id="lgnId" v-model="lgnId" no-label name="lgnId" disabled />
				</div>
				<div class="logcon">
					<!-- 이름 -->
					<label for="userNm">{{
						`${i18n.i18n.global.t('LBL.CM.NM.002')}`
					}}</label>
					<FInput
						id="userNm"
						v-model="userNm"
						no-label
						name="userNm"
						disabled
					/>
				</div>
				<div class="logcon">
					<!-- 인증번호 -->
					<label for="pswd">{{
						`${i18n.i18n.global.t('LBL.CR.CERTNO.001')}`
					}}</label>
					<FInput
						id="pswd"
						v-model="pswd"
						no-label
						name="pswd"
						type="password"
						@keypress.enter.prevent="onClickConfirm"
					/>
				</div>
			</div>
			<p class="txt-noti">※ {{ `${i18n.i18n.global.t('MSG.CM.CERT.001')}` }}</p>
			<div class="message-buttons">
				<FButton
					ref="refConfirm"
					primary
					:disabled="isDisabled"
					icon="ico-16-check"
					@click.stop.prevent="onClickConfirm"
				>
					{{ i18n.i18n.global.t('BTN.CM.CNFM.001') }}
				</FButton>
				<FIcon icon="ico-24-closePop" @click.stop.prevent="onClickCancel" />
				<!-- <FButton
					text-only
					:disabled="isDisabled"
					@click.stop.prevent="onClickCancel"
				>
					{{ i18n.i18n.global.t('BTN.CM.CNCL.001') }}
				</FButton> -->
			</div>
		</div>
	</div>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import { useMessage } from '@fila/lib/plugins/message/message';
import useSystemStore from '@/stores/systemStore';
import FButton from '@fila/lib/components/FButton';
import FInput from '@fila/lib/components/FInput';
import FIcon from '@fila/lib/components/FIcon';

const authTitle = ref('Certification');
const lgnId = ref(useSystemStore().g_userLgnId);
const userNm = ref(useSystemStore().g_userNm);
const pswd = ref('');
const compNm = ref(useSystemStore().g_compNm);
const message = useMessage();

const props = defineProps({
	i18n: {
		type: Object,
	},
	confirm: {
		type: String,
	},
	cancel: {
		type: String,
	},
	onCancel: {
		type: [Promise, Function],
	},
	onConfirm: {
		type: [Promise, Function],
	},
});

const emit = defineEmits(['finishConfirm', 'finishCancel']);

const isDisabled = ref(false);

/**
 * confirm 클릭 시 실행하는 함수
 */
async function onClickConfirm() {
	isDisabled.value = true;

	const res = await apiCall.post('CM_API/cm/global/common/auth/chkPswd', {
		pswd: pswd.value,
	});
	if (res.data.data) {
		new Promise(resolve => {
			props.onConfirm();
			resolve(true);
		}).then(() => {
			emit('finishConfirm');
		});
	} else {
		message.showAlert({
			message: props.i18n.i18n.global.t('MSG.CM.ERR.074'),
		});
		isDisabled.value = false;
	}
}

/**
 * cancel 클릭 시 실행하는 함수
 */
async function onClickCancel() {
	isDisabled.value = true;

	new Promise(resolve => {
		props.onCancel();
		resolve(false);
	}).then(() => {
		emit('finishCancel');
	});
}

const refConfirm = ref(null);
onMounted(() => {
	refConfirm.value.focus();
});
</script>

<style lang="scss" scoped>
.message {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;

	&-container {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 320px;
		min-height: 180px;
		padding: 22px 24px 24px 24px;
		border-radius: 16px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		background-color: var(--modal-bg);
		> h3 {
			line-height: 28px;
		}
	}

	&-content {
		text-align: center;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		p {
			text-align: center;
			font-size: 14px;
			color: var(--basic-text);
		}
	}

	&-buttons {
		position: absolute;
		top: 22px;
		right: 24px;
		display: flex;
		flex: none;
		gap: 8px;
		justify-content: center;
		align-items: center;
		img {
			transition: all 0.3s;
			cursor: pointer;
			&:hover {
				transform: rotate(90deg);
			}
		}
	}
	animation: fadeIn 0.2s linear;
}

.logconwrap {
	display: flex;
	flex-direction: column;
	align-items: start;
	gap: 10px;
	margin-top: 20px;
	margin-bottom: 12px;
	.logcon {
		display: flex;
		align-items: center;
		gap: 6px;
		width: 100%;
		> label {
			width: 110px;
			text-align: left;
		}
		> .input-box {
			width: 100%;
		}
	}
}

.txt-noti {
	color: #e1002b;
	font-size: 13px;
}
</style>
