<!--------------------------------------------------------------------
	파일   : Alert.vue
	작성   : CJ Developer
	설명	 : [공통] 메인헤더 내 알람을 나타내는 컴포넌트
---------------------------------------------------------------------->
<template>
	<div
		class="alert-item"
		:class="{ read: rcvYn === 'Y' }"
		@click="onClickAlarm"
	>
		<FIcon :icon="alrtIconNm ?? 'ico-32-volume'" />
		<div>
			<strong>{{ alrtTtlNm }}</strong>
			<p>{{ alrtCntn }}</p>
			<div>
				<em>
					{{
						dateUtils.setFormat(alrtSendDttm, commonUtils.gfn_getDateFormat())
					}}
				</em>
				<span>|</span>
				<em>{{ sendUserNm }}({{ sendDeptNm }})</em>
			</div>
		</div>
		<FButton
			text
			icon="ico-16-trashcan"
			class="btn-del-trash"
			@click.stop="onClickDelete"
		/>
	</div>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import dateUtils from '@fila/lib/utils/dateUtils';
import commonUtils from '@fila/lib/utils/commonUtils';

const props = defineProps({
	seq: {
		type: [Number, null],
	},
	alrtTmpltCd: {
		type: [String, null],
	},
	alrtTtlNm: {
		type: [String, null],
	},
	alrtCntn: {
		type: [String, null],
	},
	alrtIconNm: {
		type: [String, null],
		default: 'ico-32-volume',
	},
	consMenuId: {
		type: [String, null],
	},
	sendUserNm: {
		type: [String, null],
	},
	sendDeptNm: {
		type: [String, null],
	},
	alrtSendDttm: {
		type: [String, null],
	},
	callCntn: {
		type: [String, Object, null],
	},
	rcvYn: {
		type: [String, null],
	},
	delYn: {
		type: [String, null],
	},
});

const emit = defineEmits(['update:rcvYn', 'update:delYn']);

/*****************
 * Function
 *****************/
function onClickAlarm() {
	apiCall
		.post('CM_API/cm/global/admin/comfunc/func/alrt/read', {
			seq: props.seq,
			alrtTmpltCd: props.alrtTmpltCd,
		})
		.then(() => {
			commonUtils.gfn_moveTabMenuById(
				props.consMenuId,
				JSON.parse(props.callCntn),
			);
			emit('update:rcvYn');
		});
}

function onClickDelete() {
	apiCall
		.post('CM_API/cm/global/admin/comfunc/func/alrt/delete', {
			seq: props.seq,
			alrtTmpltCd: props.alrtTmpltCd,
		})
		.then(() => {
			emit('update:delYn');
		});
}
</script>

<style lang="scss" scoped>
.alert-item {
	position: relative;
	display: flex;
	gap: 12px;
	padding: 15px;
	background-color: var(--alarm-block-background);
	border-radius: 12px;
	&:before {
		position: absolute;
		top: 8px;
		left: 8px;
		content: '';
		display: block;
		width: 6px;
		height: 6px;
		background-color: var(--color-secondary);
		border-radius: 100%;
	}
	&.read {
		&:before {
			display: none;
		}
		div {
			color: var(--read-text-color);
		}
	}
	&.none {
		justify-content: center;
		align-items: center;
		height: 100%;
		font-size: 14px;
		font-weight: 500;
		color: var(--basic-text);
		text-align: center;
		background-color: transparent;
		&:before {
			display: none;
		}
	}
	> img {
		flex-shrink: 0;
		margin-top: 3px;
	}
	> div {
		display: grid;
		color: var(--heading-text);
		line-height: 1;
		cursor: pointer;
		strong {
			display: block;
			font-weight: 600;
		}
		p {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-weight: 500;
			margin: 8px 0 16px 0;
		}
		> div {
			display: flex;
			align-items: center;
			color: var(--gray-999-text);
			font-weight: 500;
			em {
				font-style: normal;
			}
			span {
				padding: 0 8px;
				color: var(--finput-border);
			}
		}
	}
	> button {
		flex: none;
		width: 16px;
		height: 16px;
		min-width: auto;
	}
	.btn-del-trash {
		position: absolute;
		right: 6px;
		top: 14px;
	}
}
</style>
