<!--------------------------------------------------------------------
	파일   : CMSiteMapPop.vue
	작성   : moon95
	설명	 : 사이트맵 팝업
---------------------------------------------------------------------->
<template>
	<FLayerPopup ref="refSiteMapPop" title="사이트맵" width="1024" height="640">
		<FButton text icon="ico-32-sitemap" class="size50" />
		<template #body>
			<FTabs site-map show-more>
				<FTab
					v-for="menu in menuTree?.children"
					:key="menu?.menuId"
					:title="menu?.menuNm"
				>
					<div class="siteMapWrap">
						<ul class="siteMap depth1">
							<!-- 1 Depth Menu -->
							<li
								v-for="menuDepth1 in menu?.children"
								:key="menuDepth1?.menuId"
							>
								<a
									:class="
										(commonUtils.gfn_isEmpty(menuDepth1?.children)
											? 'leaf'
											: '') + (menuDepth1?.authYn === 'N' ? ' disabled' : '')
									"
									@click="moveMenuTab(menuDepth3)"
									>{{ menuDepth1?.menuNm }}</a
								>
								<ul class="depth2">
									<!-- 2 Depth Menu -->
									<li
										v-for="menuDepth2 in menuDepth1?.children"
										:key="menuDepth2?.menuId"
									>
										<a
											:class="
												(commonUtils.gfn_isEmpty(menuDepth2?.children)
													? 'leaf'
													: '') +
												(menuDepth2?.authYn === 'N' ? ' disabled' : '')
											"
											@click="moveMenuTab(menuDepth2)"
											>{{ menuDepth2?.menuNm }}</a
										>
										<ul class="depth3">
											<!-- 3 Depth Menu -->
											<li
												v-for="menuDepth3 in menuDepth2?.children"
												:key="menuDepth3?.menuId"
											>
												<a
													v-if="menuDepth3?.fxYn !== 'Y'"
													:class="menuDepth3?.authYn === 'N' ? 'disabled' : ''"
													@click="moveMenuTab(menuDepth3)"
													>{{ menuDepth3?.menuNm }}</a
												>
												<div v-else class="pinList">
													<a
														:class="
															menuDepth3?.authYn === 'N' ? 'disabled' : ''
														"
														@click="moveMenuTab(menuDepth3)"
														>{{ menuDepth3?.menuNm }}</a
													>
													<FPin on disabled />
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</div>
				</FTab>
			</FTabs>
		</template>
	</FLayerPopup>
</template>
<script setup>
import FLayerPopup from '@fila/lib/components/FLayerPopup'; /* 모듈에서 */
import useMenuStore from '@/stores/menuStore';
import tabUtils from '@fila/lib/utils/tabUtils';
import commonUtils from '@fila/lib/utils/commonUtils';

const refSiteMapPop = ref(null);
const sitemapTree = useMenuStore()?.sitemapTree;
const menuTree = {
	children:
		sitemapTree?.children?.filter(item =>
			commonUtils.gfn_isEmpty(item?.menuPathNm),
		) ?? [],
};

function moveMenuTab(menu) {
	if (menu?.authYn !== 'Y' || commonUtils.gfn_isNotEmpty(menu?.children)) {
		// 권한 없는 메뉴 접근 방지
		return;
	}
	refSiteMapPop.value.close();

	tabUtils.moveMenu(menu);
}
</script>

<style lang="scss" scoped>
// 사이트맵 스타일

// 더보기버튼
.siteMapMore {
	background-color: var(--flag-blue);
	height: 48px;
	width: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 0 8px 8px 0;
}
// 메뉴스타일
.siteMapWrap {
	// border: 2px solid palevioletred;
	height: 100%;
	overflow-y: auto;
	> p {
		position: relative;
		font-size: 13px;
		display: flex;
		&:before {
			content: '!';
			border: 1px solid #e5e5e5;
			color: var(--color-primary);
			border-radius: 50%;
			width: 19px;
			height: 19px;
			line-height: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-right: 5px;
		}
	}
}
.depth1 {
	display: flex;
	flex-direction: column;
	gap: 30px;
	> li {
		> a {
			font-size: 16px;
			font-weight: 600;
			line-height: 1;
			color: #0752c9;
		}
	}
}
.depth2 {
	padding-top: 10px;
	> li {
		> a {
			font-size: 14px;
			position: relative;
			font-weight: 500;
			&:before {
				content: '';
				display: inline-block;
				width: 3px;
				height: 3px;
				background-color: #333;
				border-radius: 50%;
				margin-bottom: 3px;
				margin-right: 6px;
			}
		}
		&:not(:first-child) {
			margin-top: 12px;
		}
	}
}
.depth3 {
	display: flex;
	flex-wrap: wrap;
	// gap: 6px;
	padding-top: 3px;
	padding-left: 9px;
	> li {
		flex: none;
		margin-right: 32px;
		> a {
			font-size: 13px;
			color: #999;

			&:hover,
			&:focus {
				cursor: pointer !important;
			}

			&.disabled {
				color: #ddd !important;
				cursor: not-allowed !important;
			}
		}
	}
}
.leaf {
	&:hover,
	&:focus {
		cursor: pointer !important;
	}

	&.disabled {
		color: #ddd !important;
		cursor: not-allowed !important;
	}
}
.siteMap {
	margin-top: 24px;
	li {
		&.lock {
			background: url(/images/ico-16-lock.svg) no-repeat center right;
			padding-right: 20px;
		}
	}
}
.pinList {
	display: flex;
	gap: 3px;
	align-items: center;
	height: 24px;
	.btn-pin {
		transform: scale(0.8);
	}
	> a {
		font-size: 13px;
		color: #999;
	}
}
:root:has(.darkMode) {
	// 더보기버튼
	.siteMapMore {
		background-color: #495f84;
	}
	// 메뉴스타일
	.siteMapWrap {
		> p {
			color: #fff;
			font-weight: 400;
			&:before {
				color: #fff;
				border-color: #555;
			}
		}
	}
	.depth1 {
		> li {
			> a {
				color: #2f79ee;
			}
		}
	}
	.depth2 {
		> li {
			> a {
				color: #fff;
				&:before {
					background-color: #fff;
				}
			}
		}
	}
	.depth3 {
		> li {
			> a {
				color: #ddd;

				&:hover,
				&:focus {
					cursor: pointer !important;
				}

				&.disabled {
					color: #cccccc !important;
					cursor: not-allowed !important;
				}
			}
		}
	}
	.leaf {
		&:hover,
		&:focus {
			cursor: pointer !important;
		}

		&.disabled {
			color: #cccccc !important;
			cursor: not-allowed !important;
		}
	}
	.siteMap {
		li {
			&.lock {
				background: url(/images/ico-16-lock-dark.svg) no-repeat center right;
			}
		}
	}
	.pinList {
		> a {
			color: #ddd;
		}
	}
}
</style>
