<template>
	<div></div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import useSystemStore from '@/stores/systemStore';
import { msalInstance, ssoLogout } from '@fila/lib/plugins/authConfig';
import { acquireToken } from '@/plugins/loginConfig';
import apiCall from '@/plugins/apiCall';
import { useMessage } from '@fila/lib/plugins/message/message';

const message = useMessage();
const router = useRouter();

const isInitialized = ref(false);
const compCd = ref('');

const login = () => {
	const loginRequest = {
		scopes: ['User.Read'],
	};
	msalInstance.loginRedirect(loginRequest);
};

onMounted(async () => {
	try {
		const tokenResponse = await msalInstance.handleRedirectPromise();
		if (tokenResponse) {
			const params = {
				lgnId: tokenResponse.account.username,
			};

			await apiCall
				.post('CM_API/cm/global/common/auth/getRprsCompCd', params)
				.then(res => {
					compCd.value = res.data.data.compCd;
				});
			await acquireToken(compCd.value, router);
		} else {
			const accounts = msalInstance.getAllAccounts();
			if (accounts.length === 0) {
				// 사용자가 로그인하지 않았을 경우 로그인 처리
				login();
			} else if (!useSystemStore().sessionCheck()) {
				login();
			}
		}
		isInitialized.value = true;
	} catch (error) {
		message.showAlert({
			message: '로그인 처리 중 오류 발생:',
			onConfirm: () => {
				ssoLogout();
			},
		});
	}
});
</script>
