<template>
	<div class="header-wrap">
		<div class="menu-section">
			<FButton
				text
				icon="ico-32-menuOn"
				class="size50 menuOn"
				@click="onClickAside"
			/>
			<FButton
				text
				icon="ico-32-menuOff"
				class="size50 menuOff"
				@click="onClickAside"
			/>
			<SiteMapPop />
			<SearchMenu />
		</div>

		<div class="user-section">
			<ul>
				<li>
					<div style="margin-right: 10px">
						<FLayerPopup height="800">
							<FButton primary>
								{{ `Profile(임시)` }}
							</FButton>

							<template #header>
								<h2>profile</h2>
							</template>

							<template #body>
								<table>
									<!-- Table Header -->
									<tr>
										<th
											v-for="head in headers"
											:key="head['field']"
											:style="{
												width: 'auto',
											}"
										>
											{{ head['label'] }}
										</th>
									</tr>
									<tr v-for="(row, idx) in rows" :key="idx">
										<td
											:key="row['divCd']"
											:style="{
												textAlign: 'left',
												width: 'auto',
											}"
										>
											{{ row.divCd }}
										</td>
										<td
											:key="row['divNm']"
											:style="{
												textAlign: 'left',
												width: 'auto',
											}"
										>
											{{ row.divNm }}
										</td>
										<td
											:key="row['divCd'] + row['value']"
											:style="{
												textAlign: 'left',
												width: 'auto',
											}"
										>
											{{ row.value }}
										</td>
									</tr>
								</table>
							</template>
						</FLayerPopup>
					</div>
				</li>
				<li>
					<AlertList />
				</li>
				<!-- <li>
					<FButton
						text
						icon="ico-32-alarm"
						class="size50"
						@click="onClickAlarmToggle"
					/>
				</li> -->
				<li>
					<!-- <span class="user-company">{{ userCompCd }}</span> -->
					<FSelect
						v-model="userCompCd"
						no-label
						:options="compCdList"
						label-field="dtlCdNm"
						value-field="dtlCd"
						class="user-company"
						:read-only="compSelectReadOnly"
						is-user-company
						click-only
					/>
				</li>

				<li>
					<!-- 사용자 정보 -->
					<UserInfo :user="user" />
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import useSystemStore from '@/stores/systemStore';
import useMenuStore from '@/stores/menuStore';
import { useMessage } from '@fila/lib/plugins/message/message';

import { MENU_INFO } from '@fila/lib/utils/constCommon';
import { acquireToken } from '@/plugins/loginConfig';
import SearchMenu from '@/layout/header/SearchMenu.vue';
import UserInfo from '@/layout/header/UserInfo.vue';
import AlertList from '@/layout/header/AlertList.vue';
import SiteMapPop from '@/components/popup/SiteMapPop.vue';

import { useLoading } from '@fila/lib/plugins/loader/loader';

const loader = useLoading();

const menus = ref([]);
const systemStore = useSystemStore();
const router = useRouter();

const message = useMessage();
const menuStore = useMenuStore();
const rows = ref([]);
const adminCompanies = ref([]);

const headers = [
	{ label: '구분', field: 'divCd' },
	{ label: '구분명', field: 'divNm' },
	{ label: '상세', field: 'value' },
];

/**********
 * LNB
 **********/
function onClickAside() {
	systemStore.isFold = !systemStore.isFold;

	const timer = setInterval(() => {
		window.dispatchEvent(new Event('resize'));
	}, 0);

	setTimeout(() => {
		clearInterval(timer);
	}, 300);
}

/**********
 * Header
 **********/
const user = commonUtils.gfn_getUserInfo();
const userCompCd = ref(user?.g_compCd);
const compCdList = ref([]);
const compSelectReadOnly = ref(false);

watch(userCompCd, async (newValue, oldValue) => {
	if (commonUtils.gfn_isNotEmpty(newValue)) {
		const isAdminCompany = adminCompanies.value.includes(newValue);

		if (isAdminCompany) {
			systemStore.g_compCd = newValue;
			window.location.reload();
		} else {
			loader.show();
			login(newValue, oldValue);
		}
	}
});

const login = async (newValue, oldValue) => {
	const ssoYn = localStorage.getItem('ssoYn');
	if (commonUtils.gfn_isEmpty(ssoYn)) {
		systemStore.onLogout();
		let param = {
			username: user?.g_userLgnId,
			password: 'FilaERP1!', // local 재로그인 임시 하드코딩
			compCd: newValue,
			userSeCd: user?.g_userSeCd,
		};

		//param.password = hex.stringify(sha512(param.password));

		apiCall
			.post('CM_API/cm/global/common/auth/login', param)
			.then(async res => {
				switch (res.data.statusCode) {
					case 10:
						loader.hide();
						await message.showAlert({ message: res.data.statusMessage });
						userCompCd.value = oldValue;
						return;
					default:
						break;
				}
				const langCd = useSystemStore().g_langCd;

				localStorage.clear();

				useSystemStore().g_langCd = langCd;
				const token = res.data.data;

				await systemStore.login(token.accessToken, token.refreshToken);
				await systemStore.load();

				nextTick(async () => {
					await router.push({
						path: MENU_INFO.HOME_PATH,
						// hash: '#' + uuidv4()
					});
					window.location.reload();
					loader.hide();
				});
			})
			.catch(() => {
				loader.hide();
			});
	} else {
		await acquireToken(newValue, router);
		loader.hide();
	}
};

/**********
 * SiteMap
 **********/
function onClickSiteMap() {}

function cloneMenus() {
	menus.value = menuStore.menuTree.children;
}

const setupTable = () => {
	const tempTable = [];
	Object.keys(user).forEach(key => {
		tempTable.push({
			divCd: key,
			divNm: userInfoMapper[key],
			value: user[key],
		});
	});

	rows.value = tempTable;
};

const userInfoMapper = {
	g_userId: '사용자ID',
	g_userNm: '사용자명',
	g_empNo: '사원번호',
	g_empId: '사원ID',
	g_compCd: '회사코드',
	g_compNm: '회사명',
	g_empCompCd: '회사코드(HR)',
	g_mailAddr: '메일주소',
	g_userLgnId: '사용자로그인ID',
	g_deptCd: '부서코드',
	g_deptNm: '부서명',
	g_natNm: '국가명',
	g_cctrCd: '코스트센터코드',
	g_cctrNm: '코스트센터명',
	g_acunitCd: '회계단위코드',
	g_acunitNm: '회계단위명',
	g_theme: '스킨테마',
	g_tmznCd: '타임존코드',
	g_timezone: '타임존',
	g_timezone2: '타임존2',
	g_locale: 'locale',
	g_langCd: '언어코드',
	g_natCd: '국가코드',
	g_natNm: '국가명',
	g_dateFormatCd: '날짜형식코드',
	g_dateFormat: '날짜형식',
	g_menuId: '메뉴ID',
	g_userSeCd: '사용자구분코드',
	g_shopCd: '매장코드',
	g_shopNm: '매장명',
	g_custCd: '거래처코드',
	g_custNm: '거래처명',
	g_crncyCd: '회사별 화폐코드',
	g_currency: '회사별 화폐명',
	g_crncyFmtCd: '회사별 화폐형식코드',
	g_currencyFormat: '회사별 화폐형식',
	g_unitCrncyCd: '회계단위별 화폐코드',
	g_unitCurrency: '회계단위별 화폐명',
	g_unitCrncyFmtCd: '회계단위별 화폐형식코드',
	g_unitCurrencyFormat: '회계단위별 화폐형식',
	g_unitBizplcAggYn: '사업장집계여부',
	g_rprsBrndCd: '브랜드코드',
	g_acunitList: '회계단위 리스트',
	g_compList: '회사 리스트',
	g_buList: 'BU List',
	g_essList: 'ESS List',
};

onMounted(() => {
	cloneMenus();
	setupTable();
});

onBeforeMount(() => {
	apiCall.get('CM_API/cm/global/common/main/getCompListByAuth').then(res => {
		compCdList.value = res.data.data;

		// role이 'admin'인 회사만 필터링하여 저장
		adminCompanies.value = res.data.data
			.filter(item => item.compRole === 'ADMIN')
			.map(item => item.dtlCd);

		if (commonUtils.gfn_isEmpty(res.data.data)) {
			compSelectReadOnly.value = true;
			return;
		}
		if (
			res.data.data?.length == 1 &&
			res.data.data?.dtlCd === userCompCd.value
		) {
			compSelectReadOnly.value = true;
			return;
		}
		compSelectReadOnly.value = false;
	});
});
</script>

<style lang="scss" scoped>
.header-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--header-wrap-bg);
	height: 45px;
	padding-left: 10px;
	> .menu-section {
		display: flex;
		align-items: center;
		button {
			height: 45px !important;
		}
	}

	.user-section {
		position: relative;
		ul {
			display: flex;
			align-items: center;
			li {
				min-width: 52px;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-left: 1px solid var(--user-section-border-color);
				color: var(--btn-text);
				font-size: 13px;
				font-weight: bold;
				span {
					padding: 0 24px;
				}
				&:first-child {
					border-left: none;
				}
				button {
					font-weight: 500;
				}
			}
		}

		.user-alarm {
			position: absolute;
			top: 60px;
			right: 273px;
			padding: 20px;
			box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
			border: solid 1px #002053;
			border-radius: 6px;
			background-color: #fff;
			z-index: 2;
			display: flex;
			flex-direction: column;
			gap: 12px;

			h5 {
				font-size: 13px;
			}
			&::before {
				position: absolute;
				top: -6px;
				right: 40px;
				content: '';
				width: 10px;
				height: 10px;
				border-top: 1px solid #002053;
				border-left: 1px solid #002053;
				transform: rotate(45deg);
				background-color: #fff;
			}
			&.hide {
				display: none;
			}
		}
	}
}
:root:has(.darkMode) {
	.header-wrap {
		.user-section {
			ul {
				li {
					font-weight: 400;
				}
			}
		}
	}
}

body:has(.aside-wrap.on) {
	.menuOn {
		display: flex;
	}
	.menuOff {
		display: none;
	}
}
body:has(.aside-wrap.off) {
	.menuOn {
		display: none;
	}
	.menuOff {
		display: flex;
	}
}

table {
	table-layout: fixed;
	border-top: 2px solid #002053;
	border-bottom: 1px solid #002053;
	width: 100%;
	tr {
		th,
		td {
			border-right: 1px solid #e5e5e5;
			&:last-child {
				border-right: 0;
			}
		}
		th {
			font-size: 13px;
			color: #111;
			padding: 6px 8px;
			border-bottom: 1px solid #002053;
			background-color: #fff;
		}
		td {
			font-size: 13px;
			color: #555;
			padding: 8px;
			background-color: #fff;
			border-top: 1px solid #e5e5e5;
		}
		&:first-child {
			td {
				border-top: 0;
			}
		}
		&:hover {
			td {
				background-color: #eff4fb;
			}
		}
	}
}
</style>
<style lang="scss">
.user-company {
	width: 128px;
}
/* 다크모드 */
:root:has(.darkMode) {
	.fSelect.user-company {
		input {
			background-color: #141617;
		}
	}
}
</style>
