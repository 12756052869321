import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import { createApp } from 'vue';
import VueCookies from 'vue-cookies';

import App from '@/App.vue';
import './style.css';

import router from '@/routes';
import axios from '@fila/lib/plugins/axios';

// 다국어
import i18n from '@fila/lib/lang';

// Component And Directive
import component from '@fila/lib/plugins/component';
import directive from '@fila/lib/plugins/directive';
import { useMessage } from '@fila/lib/plugins/message/message';

const app = createApp(App);
const title = import.meta.env.VITE_APP_TITLE;
document.getElementById('viteAppTitle').innerText = title;

const message = useMessage();

//전역 에러 핸들러 설정
app.config.errorHandler = (err, vm, info) => {
	console.log('화면 ERROR: ', err.toString());
	message.showAlert({
		message: '화면 ERROR: ' + err.toString(),
	});
};

// 플러그인 사용
app
	.use(createPinia().use(piniaPersist))
	.use(axios)
	.use(router)
	.use(i18n)
	.use(VueCookies, {
		expireTimes: '30d',
		secure: true,
	})
	// 컴포넌트와 디렉티브 사용
	.use(component)
	.use(directive)
	.mount('#app');
