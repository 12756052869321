<!--------------------------------------------------------------------
	파일   : FUserSetitng.vue
	작성   : CJ Developer
	설명	 : [공통] 아이콘으로 표현된 라디오 컴포넌트로 언어/테마 선택에 사용
---------------------------------------------------------------------->
<template>
	<div>
		<FRadio
			:id="mode"
			v-model="selected"
			no-label
			class="user-items"
			:options="options"
			@change="event => onChange(event.target.value)"
		/>
	</div>
</template>

<script setup>
import useSystemStore from '@/stores/systemStore';
import commonUtils from '@fila/lib/utils/commonUtils';
import VueCookies from 'vue-cookies';
import { DEFAULT_LOCALE, DEFAULT_THEME } from '@fila/lib/utils/constCommon';
import apiCall from '@/plugins/apiCall';

const props = defineProps({
	mode: {
		type: String,
		default: 'locale',
		validator: value => {
			return ['locale', 'theme'].includes(value);
		},
	},
	from: {
		type: String,
	},
});

// 연결데이터
const selected =
	props.mode === 'locale'
		? ref(
				VueCookies.get('_locale') ??
					useSystemStore()?.g_locale ??
					DEFAULT_LOCALE,
			)
		: ref(
				VueCookies.get('_theme') ?? useSystemStore()?.g_theme ?? DEFAULT_THEME,
			);

const options = ref([]);

function onChange(newValue) {
	selected.value = newValue;
	switch (props.mode) {
		case 'locale':
			VueCookies.set('_locale', newValue, { expires: '1y' });
			if (props.from !== 'login') {
				apiCall
					.post('CM_API/cm/global/admin/sysmgt/func/userenvmng/save', {
						langCd: newValue,
					})
					.then(() => {
						useSystemStore().setLocale(newValue);
					});
			} else {
				useSystemStore().setLocale(newValue);
			}
			break;
		case 'theme':
			apiCall
				.post('CM_API/cm/global/admin/sysmgt/func/userenvmng/save', {
					themeCd: newValue,
				})
				.then(() => {
					VueCookies.set('_theme', newValue, { expires: '1y' });
					useSystemStore().setTheme(newValue);
				});
			break;
	}
}

onBeforeMount(async () => {
	switch (props.mode) {
		case 'locale':
			options.value = await commonUtils.gfn_getCmCd('LANG_CD').then(res => {
				return res.map(locale => {
					locale.dtlCdNm = '';
					locale.dtlCd = locale.dtlCd;
					return locale;
				});
			});
			break;
		case 'theme':
			options.value = [
				{ dtlCdNm: '', dtlCd: 'default' },
				{ dtlCdNm: '', dtlCd: 'light' },
				{ dtlCdNm: '', dtlCd: 'dark' },
			];
			break;
	}
});
</script>
