<!--------------------------------------------------------------------
	파일   : AlertList.vue
	작성   : CJ Developer
	설명	 : [공통] 메인헤더 내 알림을 나타내는 컴포넌트
---------------------------------------------------------------------->
<template>
	<div v-click-outside="onClickOutside">
		<div class="alarm-wrap" @click.prevent="onClickAlarm">
			<FButton
				v-show="newAlert"
				text
				icon="ico-32-alarm-on"
				class="size50"
				:class="{
					open: isShow,
				}"
			/>
			<FButton
				v-show="!newAlert"
				text
				icon="ico-32-alarm"
				class="size50"
				:class="{
					open: isShow,
				}"
			/>
		</div>

		<div v-show="isShow" class="alarm-content">
			<h5>
				{{ t('LBL.CM.ALRT.001') }}
				(
				<span>{{ alertCount }}</span>
				)
			</h5>
			<ul class="alarm-list">
				<Alert
					v-for="(alert, index) in alertList"
					v-show="alert.delYn == 'N'"
					:key="alert.seq"
					:seq="alert.seq"
					:alrt-tmplt-cd="alert.alrtTmpltCd"
					:alrt-ttl-nm="alert.alrtTtlNm"
					:alrt-cntn="alert.alrtCntn"
					:alrt-icon-nm="alert.alrtIconNm"
					:cons-menu-id="alert.consMenuId"
					:send-user-nm="alert.sendUserNm"
					:send-dept-nm="alert.sendDeptNm"
					:alrt-send-dttm="alert.alrtSendDttm"
					:call-cntn="alert.callCntn"
					:rcv-yn="alert.rcvYn"
					@update:rcv-yn="onRead(index)"
					@update:del-yn="onDelete(index)"
				/>
				<li v-if="alertList.length === 0" class="none">
					{{ t('MSG.CM.NOALRT.001') }}
				</li>
			</ul>
			<div class="alarm-btn">
				<FButton v-if="alertCount > 0" text @click="onClickReadAllAlert">
					{{ t('BTN.CM.ALLALRTREAD.001') }}
				</FButton>
				<FButton text icon="ico-16-dark-reset" @click="onResetAlert" />
			</div>
		</div>
	</div>
</template>

<script setup>
import { isEqual } from 'lodash-es';
import apiCall from '@/plugins/apiCall';
import useSystemStore from '@/stores/systemStore';
import commonUtils from '@fila/lib/utils/commonUtils';

import { useLoading } from '@fila/lib/plugins/loader/loader';

import Alert from '@/layout/header/Alert.vue';

const { t } = useI18n();
const loader = useLoading();

const isShow = ref(false);
const userInfo = commonUtils?.gfn_getUserInfo();
const acunitCd = ref(userInfo?.g_acunitCd);

const newAlert = ref(false);
const alertList = ref([]);
const alertCount = computed(
	() => alertList.value?.filter(alert => alert.delYn === 'N')?.length,
);

function onRead(index) {
	alertList.value[index].rcvYn = 'Y';
	onClickOutside();
}

function onDelete(index) {
	alertList.value[index].delYn = 'Y';
}

function getAlertList() {
	apiCall.get('CM_API/cm/global/admin/comfunc/func/alrt/search').then(res => {
		// For New Arrival Check
		let firstSeq = null;
		if (alertList.value?.length > 0) {
			firstSeq = alertList.value[0].seq;
		}

		alertList.value = res.data.data;

		if (firstSeq && !isEqual(firstSeq, alertList.value[0]?.seq)) {
			newAlert.value = true;
		}
	});
}

function onClickReadAllAlert() {
	apiCall
		.post(
			'CM_API/cm/global/admin/comfunc/func/alrt/readAll',
			alertList.value.filter(alert => alert.rcvYn === 'N'),
		)
		.then(res => {
			alertList.value = res.data.data;
		});
}

function onResetAlert() {
	getAlertList();
}

/**
 * 회계단위 변경 로직
 * */
watch(acunitCd, value => {
	loader.show();
	useSystemStore()
		.setAcunitCd(value)
		.then(() => {
			loader.hide();
		})
		.catch(() => {
			loader.hide();
		});
});

/**
 * 사용자 정보 컴포넌트를 화면에 나타내는 함수
 */
function onClickAlarm() {
	isShow.value = !isShow.value;
	newAlert.value = false;
}

/**
 * 외부 클릭 시 컴포넌트를 숨기는 함수
 */
function onClickOutside() {
	isShow.value = false;
}

onBeforeMount(() => {
	getAlertList();
});
</script>

<style lang="scss" scoped>
.alarm-wrap {
	min-width: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--btn-text);
	font-size: 13px;
	font-weight: 700;
	button {
		height: 45px !important;
	}
}
.alarm-content {
	position: absolute;
	top: 54px;
	right: 10px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px var(--user-pop-over-border-color);
	border-radius: 6px;
	background-color: var(--alarm-background);
	z-index: 4;
	display: flex;
	flex-direction: column;
	width: 364px;
	height: 514px;
	padding: 20px 0 14px 0;
	&::before {
		position: absolute;
		top: -6px;
		left: 65px;
		content: '';
		width: 10px;
		height: 10px;
		border-top: 1px solid var(--user-pop-over-border-color);
		border-left: 1px solid var(--user-pop-over-border-color);
		transform: rotate(45deg);
		background-color: var(--main-background);
	}
	h5 {
		font-size: 16px;
		margin-bottom: 10px;
		color: var(--heading-text);
		padding-left: 35px;
		span {
			color: var(--color-secondary);
		}
	}
}
.alarm-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
	overflow-y: auto;
	padding: 0 20px;
	flex: 1;
	.none {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
.alarm-btn {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 14px;
	padding: 0 35px;
	height: 28px;
	button:last-child {
		position: absolute;
		right: 35px;
	}
}
</style>
<style lang="scss">
.alarm-btn {
	button {
		min-width: auto !important;
		font-size: 14px !important;
		font-weight: 500;
		text-decoration: underline;
		background-color: transparent !important;
		color: var(--all-read-text-color) !important;
	}
}
:root {
	--color-secondary: #e31837;
	--read-text-color: #aaa;
	--all-read-text-color: #002965;
	--gray-999-text: #999;
	--alarm-background: #f4f4f6;
	--alarm-block-background: #fff;
	&:has(.darkMode) {
		--color-secondary: #ff6380;
		--read-text-color: #777;
		--all-read-text-color: #6a84ae;
		--gray-999-text: #999;
		--alarm-background: #2d2f32;
		--alarm-block-background: #444;
	}
}
</style>
