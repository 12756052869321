<template>
	<div :class="{ cn: lang === 'cn' }">
		<TimerPop id="timerPop" ref="timerPop" />
		<Layout v-if="route.meta.layout" />
		<RouterView v-else />
	</div>

	<component :is="invnQuizRegPop" />
	<component :is="vsitlnfrMngmPop" />
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';
import VueCookies from 'vue-cookies';
import Layout from '@/views/Layout.vue';
import useSystemStore from '@/stores/systemStore';
import TimerPop from '@/components/popup/TimerPop.vue';
import SPInvnQuizRegPop from '@/components/popup/SPInvnQuizRegPop.vue';
import SPVsitlnfrMngmPop from '@fila/lib/components/popup/sa/SPVsitlnfrMngmPop';
import usePopup from '@fila/lib/utils/usePopup';
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { msalInstance } from '@fila/lib/plugins/authConfig';

const route = useRoute();
const lang = computed(() => useSystemStore().g_langCd);
const timer = computed(() => useSystemStore().g_timer);
const timerPop = ref(null);
const invnQuizRegPop = ref(null);
const vsitlnfrMngmPop = ref(null);
const smsAgreCnt = ref(0);

let smsAgreIntervalId = null;
let worker = null;

const excludedUrls = [
	'/login-temp',
	'/login-pol',
	'/login-pos',
	'/emhrinforegaply',
	'/emhrapntcnfm',
	'/error',
];

const getEffectiveTimerValue = () => {
	return timer.value != null && timer.value !== 0 ? timer.value : 30; // g_timer가 null일 경우 기본값 30분
};

const handleUserActivity = () => {
	if (worker) {
		worker.postMessage({
			type: 'reset',
			initialTime: getEffectiveTimerValue(),
		});
	}
};

const handleAccessTokenRefresh = async () => {
	const ssoYn = localStorage.getItem('ssoYn');

	if (commonUtils.gfn_isEmpty(ssoYn)) {
		const param = {
			accessToken: VueCookies.get('accessToken'),
			refreshToken: VueCookies.get('refreshToken'),
		};
		apiCall
			.post('CM_API/cm/global/common/auth/refreshtoken', param)
			.then(async res => {
				const token = res.data.data;
				await useSystemStore().login(token.accessToken, token.refreshToken);
				if (worker) {
					worker.postMessage({
						type: 'resetTokenTimer',
					});
				}
			});
	} else {
		const accounts = msalInstance.getAllAccounts();
		if (accounts.length > 0) {
			const request = {
				account: accounts[0],
				scopes: [`api://${import.meta.env.VITE_AZURE_SCOPES}/api.access`],
			};
			try {
				const response = await msalInstance.acquireTokenSilent(request);
				await useSystemStore().login(response.accessToken, '');
			} catch (error) {
				if (error.name === 'InteractionRequiredAuthError') {
					// 사용자 인터랙션이 필요한 경우, redirect 또는 popup을 통해 토큰을 요청
					const response = await msalInstance.acquireTokenRedirect(request);
					await useSystemStore().login(response.accessToken, '');
				} else {
					console.error(error);
				}
			}
		}
	}
};

/* 매장 재고퀴즈 대상 여부 */
const getQuizTrgtInfo = async () => {
	await apiCall
		.get(
			'SCM_KR_API/scm/kr/sp/shopmng/invnmng/invnqiuz/spinvnquizreg/getQuizTrgtInfo',
		)
		.then(res => {
			if (commonUtils.gfn_isNotEmpty(res.data.data)) {
				openQuizPop(res.data.data);
			}
		});
};

/* 매장 재고퀴즈 팝업 */
const openQuizPop = data => {
	let __popupComp = null;
	let __callback = () => {};
	let __props = {};

	__popupComp = SPInvnQuizRegPop;

	__props = {
		height: '600',
		width: '1000',
		inParam: {
			brndCd: data.brndCd,
			shopCd: data.shopCd,
			strtDt: data.strtDt,
			qstnCnt: data.qstnCnt,
			qstnBaseCd: data.qstnBaseCd,
		},
	};

	invnQuizRegPop.value = usePopup(__popupComp, __props, __callback);

	nextTick(() => {
		invnQuizRegPop.value.open();
	});
};

/* 문자 발신정보 관리 위임 동의 여부 */
const getSmsAgreYn = async () => {
	if (useSystemStore().g_userSeCd !== '02') {
		return false;
	}

	await apiCall
		.get(
			'SCM_KR_API/scm/kr/sa/salemng/baseinfo/etcmng/spvsitlnfrmngm/getSmsAgreYn',
		)
		.then(res => {
			if (commonUtils.gfn_nvl(res.data.data.smsAgreYn, '').trim() === 'N') {
				smsAgreCnt.value++;
				openAgrePop();
			}
		});
};

/* 문자 발신정보 관리 위임 동의 팝업 */
const openAgrePop = () => {
	let __popupComp = null;
	let __callback = () => {};
	let __props = {};

	__popupComp = SPVsitlnfrMngmPop;

	vsitlnfrMngmPop.value = usePopup(__popupComp, __props, __callback);

	nextTick(() => {
		vsitlnfrMngmPop.value.open();
	});
};

onMounted(() => {
	worker = new Worker(new URL('@/plugins/timerWorker.js', import.meta.url));
	worker.postMessage({ type: 'start', initialTime: getEffectiveTimerValue() });
	worker.onmessage = event => {
		if (!excludedUrls.includes(route.path)) {
			if (event.data.type === 'timeout' && useSystemStore().sessionCheck()) {
				timerPop.value.fnOpen();
			} else if (event.data.type === 'refreshAccessToken') {
				handleAccessTokenRefresh();
			}
		}
	};
	window.addEventListener('mousemove', handleUserActivity);
	window.addEventListener('keydown', handleUserActivity);

	smsAgreIntervalId = setInterval(() => {
		getSmsAgreYn();
	}, 3600000); // 1 hour = 3600000 milliseconds
});

onUnmounted(() => {
	if (worker) {
		worker.postMessage({ type: 'stop' });
		worker.terminate();
	}
	//window.removeEventListener('mousemove', handleUserActivity);
	window.removeEventListener('keydown', handleUserActivity);
});

onBeforeUnmount(() => {
	clearInterval(smsAgreIntervalId);
});

watch(
	() => route.name,
	value => {
		if (
			value === 'Home' &&
			useSystemStore().sessionCheck() &&
			useSystemStore().g_userSeCd === '02'
		) {
			getQuizTrgtInfo();
			if (smsAgreCnt.value === 0) getSmsAgreYn();
		}
	},
);
</script>

<style src="./assets/scss/layout.scss"></style>
<style src="./assets/scss/common.scss"></style>
<style src="./assets/font/pretendard/pretendard.css"></style>
<style src="./assets/font/notosanssc/notosanssc.css"></style>
