/**********************************************************************
 * 파일   : apiCall.js
 * 작성   : CJ Developer
 * 설명   : API 호출 시 사용하는 공통 함수
 **********************************************************************/
import axios from 'axios';
import commonUtils from '@fila/lib/utils/commonUtils';

export const API_URL = Object.freeze({
	CM_API: import.meta.env.VITE_API_CM,
	FCM_API: import.meta.env.VITE_API_FCM,
	HR_API: import.meta.env.VITE_API_HR,
	SCM_API: import.meta.env.VITE_API_SCM,
	CRM_KR_API: import.meta.env.VITE_API_CRM_KR,
	POS_KR_API: import.meta.env.VITE_API_POS_KR,
	SCM_KR_API: import.meta.env.VITE_API_SCM_KR,
	LTX_API: import.meta.env.VITE_API_LTX,
});

class apiCall {
	/**
	 * 호출 URL을 타겟 시스템에 맞게 변경
	 * @param {string} url
	 * @returns
	 */
	static changeURL(url) {
		let target = url.split('/')[0];
		if (commonUtils.gfn_isEmpty(API_URL[target])) {
			return url;
		}
		return url.replace(target, API_URL[target]);
	}

	/**
	 * GET 호출
	 * @param {string} url
	 * @param {object} params
	 * @returns
	 */
	static get(url, params, config = { loader: false }) {
		let targetURL = this.changeURL(url);
		return axios.get(targetURL, {
			params,
			...config,
			// 배열 param 직렬화
			paramsSerializer: params => {
				return Object.keys(params)
					.map(key => {
						const value = params[key];
						if (Array.isArray(value)) {
							return value
								.filter(item => item !== null && item !== '') // null이나 빈 문자열 제외
								.map(item => `${key}=${encodeURIComponent(item)}`) // 인코딩 추가
								.join('&');
						} else {
							if (value === null || value === '') return ''; // null이나 빈 값은 제외
							return `${key}=${encodeURIComponent(value)}`; // 인코딩 추가
						}
					})
					.filter(part => part !== '') // 빈 문자열 제외
					.join('&');
			},
		});
	}

	/**
	 * POST 호출
	 * @param {string} url
	 * @param {object} params
	 * @returns
	 */
	static post(url, params, config = { loader: false }) {
		let targetURL = this.changeURL(url);
		return axios.post(targetURL, params, { ...config });
	}

	/**
	 * 파일다운로드(POST) 호출
	 * @param {string} url
	 * @param {object} params
	 * @returns
	 */
	static fileDownload(url, params, config = { loader: false }) {
		let targetURL = this.changeURL(url);

		return axios.post(
			targetURL,
			{ contentType: 'application/x-www-form-urlencoded' },
			{ params, responseType: 'blob' },
			config,
		);
	}

	/**
	 * 파일다운로드(POST) 호출
	 * @param {string} url
	 * @param {object} params
	 * @returns
	 */
	static getFileDownload(url, params, config = { loader: true }) {
		let targetURL = this.changeURL(url);

		return axios.get(
			targetURL,
			{
				contentType: 'application/x-www-form-urlencoded',
				params,
				responseType: 'blob',
			},
			config,
		);
	}
}

export default apiCall;
