<!--------------------------------------------------------------------
    파일   : LoginLtermCnfm.vue
    작성   : CJ Developer
    설명   : 장기 인증 등록 팝업
---------------------------------------------------------------------->

<template>
	<!-- 장기 인증 등록 팝업 내용 -->
	<p class="fs-16">{{ t('MSG.CM.LTERMCERT.001') }}</p>
	<div class="grayBox">
		<span class="fs-14">{{ t('LBL.CM.LTERMCERT.002') }}</span>
		<FRadio
			id="selectRadio"
			v-model="selectRadio"
			:options="optionsRadio"
			:name="selRadio"
			no-label
			class="login"
		/>
	</div>
	<p class="fs-14">
		※ 장기인증 등록 시 3개월동안 추가 인증없이 사용 가능합니다.
	</p>
</template>

<script setup>
const emit = defineEmits(['update:ltermChkValue']);

const { t } = useI18n();

const props = defineProps({
	ltermChkValue: {
		type: [String, null],
	},
});

const selectRadio = computed({
	get() {
		return props.ltermChkValue;
	},
	set(value) {
		emit('update:ltermChkValue', value);
	},
});

const optionsRadio = [
	{
		dtlCdNm: t('LBL.CM.YES.001'),
		dtlCd: 'Y',
	},
	{
		dtlCdNm: t('LBL.CM.NO.002'),
		dtlCd: 'N',
	},
];
</script>

<style lang="scss" scoped>
p {
	color: var(--basic-text-gray);
	&.fs-14 {
		font-size: 14px;
	}
}
.grayBox {
	background-color: #f4f5f6;
	padding: 24px;
	.fs-14 {
		display: block;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 8px;
	}
}
</style>
