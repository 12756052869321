<!--------------------------------------------------------------------
	파일   : UserInfo.vue
	작성   : CJ Developer
	설명	 : [공통] 메인헤더 내 사용자 정보를 나타내는 컴포넌트
---------------------------------------------------------------------->
<template>
	<div v-click-outside="onClickOutside">
		<div class="user-profile" @click.prevent="onClickUserProfile">
			<div class="image">
				<img :src="userThumbnail" :alt="userInfo?.g_userNm" />
			</div>
			<span class="user-name">
				{{ userInfo?.g_userNm }}
			</span>

			<FButton
				text
				icon="ico-16-topArrow"
				class="size50"
				:class="{
					open: isShow,
				}"
			/>
		</div>

		<div v-show="isShow" class="user-pop-over">
			<div class="user-corp">
				<h5 style="width: 100%">
					{{ `${t('LBL.FI.ACUNITCD.001')}` }}
				</h5>
				<FSelect
					id="acunitCd"
					v-model="acunitCd"
					:options="acunitList"
					label-field="acunitNm"
					value-field="acunitCd"
					:disabled="acunitDisable"
					no-label
				/>
			</div>
			<div class="user-lmz-set">
				<div class="user-lang">
					<h5>{{ `${t('LBL.CM.LANGUAGE.001')}` }}</h5>
					<UserSetting mode="locale" />
				</div>
				<div class="user-mode">
					<h5>{{ `${t('LBL.CM.THEME.001')}` }}</h5>
					<UserSetting mode="theme" />
				</div>
			</div>
			<div class="user-setting">
				<div @click="onClickUserEnvSetting">
					<h5 class="menu-link-style">
						{{ `${t('LBL.CM.USERBPREFERENCES.01 ')}` }}
					</h5>
					<FButton text icon="ico-16-set" class="size16" />
				</div>
			</div>

			<FButton class="w-full mt-[10px]" primary @click.prevent="onClickLogout">
				{{ `${t('LBL.CM.LOGOUT.001')}` }}
			</FButton>
		</div>
	</div>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useLoading } from '@fila/lib/plugins/loader/loader';

import useSystemStore from '@/stores/systemStore';
import UserSetting from '@/layout/header/UserSetting.vue';

const loader = useLoading();

const isShow = ref(false);
const userInfo = commonUtils?.gfn_getUserInfo();
const acunitCd = ref(userInfo?.g_acunitCd);
const acunitList = ref([]);
const acunitDisable = ref(false);

const { t } = useI18n();

/**
 * 회계단위 변경 로직
 * */
watch(acunitCd, value => {
	loader.show();
	useSystemStore()
		.setAcunitCd(value)
		.then(() => {
			loader.hide();
		})
		.catch(() => {
			loader.hide();
		});
});

/**
 * 사용자 정보 컴포넌트를 화면에 나타내는 함수
 */
function onClickUserProfile() {
	isShow.value = !isShow.value;
}

/**
 * 외부 클릭 시 컴포넌트를 숨기는 함수
 */
function onClickOutside() {
	isShow.value = false;
}

/************************
 * Thumbnail
 ************************/
const userThumbnail = ref(null);
const defaultThumbnail =
	useSystemStore().g_theme === 'dark'
		? `${import.meta.env.VITE_URL}/images/ico-32-profile-dark.svg`
		: `${import.meta.env.VITE_URL}/images/ico-32-profile.svg`;

/**
 * 로그아웃
 */
//const router = useRouter();

async function onClickLogout() {
	useSystemStore().logout();
}

const onClickUserEnvSetting = () => {
	commonUtils.gfn_moveTabMenuById('CM00051000');
	isShow.value = false;
};

onBeforeMount(() => {
	apiCall
		.get('CM_API/cm/global/common/main/acunits', { langCd: userInfo?.g_langCd })
		.then(res => {
			acunitDisable.value =
				commonUtils.gfn_isEmpty(res.data.data) ||
				(res.data.data?.length === 1 &&
					userInfo?.g_acunitCd === res.data.data[0].acunitCd)
					? true
					: false;
			acunitList.value = commonUtils.gfn_isEmpty(res.data.data)
				? [{ acunitCd: userInfo?.g_acunitCd, acunitNm: userInfo?.g_acunitNm }]
				: res.data.data?.filter(item =>
						commonUtils.gfn_isNotEmpty(item?.acunitCd),
				  );
		})
		.catch(e => {
			console.error(e);
		});

	switch (userInfo?.g_userSeCd) {
		case '01':
			apiCall
				.getFileDownload('LTX_API/ltx/global/public/hr/thumbnail', {
					compCd: userInfo?.g_compCd,
					empNo: userInfo?.g_empNo,
				})
				.then(res => {
					if (res.data.size) {
						userThumbnail.value = window.URL.createObjectURL(
							new Blob([res.data]),
						);
					} else {
						userThumbnail.value = defaultThumbnail;
					}
				});
			break;
		default:
		case '02':
		case '03':
			userThumbnail.value = defaultThumbnail;
			break;
	}
});
</script>

<style lang="scss" scoped>
.user-profile {
	padding-left: 16px;
	min-width: 52px;
	display: flex;
	align-items: center;
	justify-content: center;
	// border-left: 1px solid var(--user-section-border-color);
	color: var(--btn-text);
	font-size: 13px;
	font-weight: 700;
	span {
		padding: 0;
	}
	.image {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: url(/images/ico-32-profile.svg) no-repeat center;
		margin-right: 16px;
		overflow: hidden;
	}
	button {
		height: 45px !important;
		background-color: transparent;
	}
}
:root:has(.darkMode) {
	.user-profile {
		.image {
			background: url(/images/ico-32-profile-dark.svg) no-repeat center;
		}
	}
}
.user-pop-over {
	position: absolute;
	top: 54px;
	right: 10px;
	padding: 20px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px var(--user-pop-over-border-color);
	border-radius: 6px;
	background-color: var(--user-pop-over-bg-color);
	z-index: 4;
	display: flex;
	flex-direction: column;
	gap: 24px;
	width: 266px;
	.primary {
		height: 40px;
		font-size: 14px;
		font-weight: bold;
	}
	h5 {
		font-size: 13px;
		line-height: 1;
		width: 100px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		word-break: break-all;
	}
	&::before {
		position: absolute;
		top: -6px;
		right: 40px;
		content: '';
		width: 10px;
		height: 10px;
		border-top: 1px solid var(--user-pop-over-border-color);
		border-left: 1px solid var(--user-pop-over-border-color);
		transform: rotate(45deg);
		background-color: var(--user-pop-over-bg-color);
	}
	.user-corp {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.user-setting {
		display: flex;
		flex-direction: column;
		gap: 26px;
		> div {
			display: flex;
			justify-content: space-between;
			height: 15px;
			h5 {
				width: 180px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
	.user-lang,
	.user-mode {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: inherit;
	}
}
:root:has(.darkMode) {
	.user-profile {
		span {
			font-weight: 400;
		}
		button {
			background-color: transparent;
		}
	}
}
.user-zoom-control {
	display: flex;
	align-items: center;
	justify-content: space-between;
	> div {
		display: flex;
		align-items: center;
		gap: 5px;
		width: 120px;
		Button {
			&.btn-zoom {
				border: 1px solid var(--zoombtnborder);
				width: 20px;
				min-width: 20px;
				height: 20px;
				margin-bottom: 2px;
			}
		}
		p {
			font-size: 13px;
			font-weight: 800;
			color: var(--heading-text);
			margin-right: 4px;
		}
	}
}
.user-lmz-set {
	border-bottom: 1px solid var(--finput-border);
	padding-bottom: 20px;
	> div:not(:first-child) {
		margin-top: 20px;
	}
}
</style>
<style lang="scss">
:root {
	--zoombtnborder: #ccc;
}
:root:has(.darkMode) {
	--zoombtnborder: #444;
}
.fSelect {
	&.fw-bold {
		input {
			font-weight: 700;
		}
	}
}
</style>
<style lang="scss">
.user-profile {
	button {
		img {
			transition: all 0.3s;
		}
		&.open {
			img {
				transform: rotate(180deg);
			}
		}
	}
}
.user-corp {
	.fSelect {
		input {
			font-weight: 500 !important;
		}
	}
}
/* 라디오버튼 간격 수정 : 2024-06-07 */
.user-mode,
.user-lang {
	.fRadio-box.user-items {
		.fradio-box-r {
			.fRadio {
				margin: 0;
			}
		}
	}
}
</style>
