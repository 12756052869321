<!--------------------------------------------------------------------
	파일   : EMHrInfoRegAply.vue
	작성   : CJ Developer
	설명	 : 인사정보등록신청(외부접속)
---------------------------------------------------------------------->
<template>
	<div class="emhrinforegaply-wrap">
		<div class="page-title">
			<div class="h1">
				<!-- 인사정보등록신청 -->
				{{ t('LBL.HR.COM.161') }}
			</div>
			<FlexBox class="gap-x-[16px] ml-auto">
				<FButton text icon="ico-16-black-reset" @click="onClickReset">
					<!-- 초기화 -->
					{{ t('BTN.CM.RESET.001') }}
				</FButton>
				<FButton text icon="ico-16-black-search" @click="onClickSearch">
					<!-- 조회 -->
					{{ t('BTN.CM.SEARCH.001') }}
				</FButton>
				<FButton text icon="ico-16-black-save" @click="onClickSave">
					<!-- 저장 -->
					{{ t('BTN.CM.SAVE.001') }}
				</FButton>
				<FButton text icon="ico-16-complete" @click="onClickAply">
					<!-- 신청 -->
					{{ t('BTN.HR.COM.009') }}
				</FButton>
				<!-- <FButton text icon="ico-16-complete" @click="onClickTest">
					테스트
				</FButton> -->
			</FlexBox>
		</div>
		<FTabs v-model="currentInfo" scroll>
			<FTab
				:title="t('LBL.HR.COM.036')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<div class="content-scroll">
					<FGridCol>
						<!-- 기본정보 -->
						<FTitle level="4" :name="t('LBL.HR.COM.102')" />
						<FForm
							id="hrInfoForm"
							ref="refHrInfoForm"
							v-model="formObj"
							:init-value="initObj"
							form-only
						>
							<FFormRow is-grid-cols="8">
								<FFormCol>
									<FlexBox class="gap-[4px]">
										<!-- 회사 -->
										<FInput
											v-model="formObj.compNm"
											:label="t('LBL.CM.COMPANY.001')"
											disabled
										/>
									</FlexBox>
								</FFormCol>
								<FFormCol class="col-span-2">
									<FlexBox class="gap-[4px]">
										<!-- 사원 -->
										<FInput
											id="empNo"
											v-model="formObj.empNo"
											:label="t('LBL.HR.EMP.001')"
											disabled
										/>
										<FInput
											id="empNm"
											v-model="formObj.empNm"
											no-label
											disabled
										/>
									</FlexBox>
								</FFormCol>
								<FFormCol>
									<!-- 외부메일 -->
									<FInput
										id="otcoEmlAddr"
										v-model="formObj.otcoEmlAddr"
										:label="t('LBL.HR.EMP.023')"
										disabled
									/>
								</FFormCol>
								<FFormCol class="col-span-2">
									<FlexBox class="gap-[4px]">
										<!-- 휴대폰번호 -->
										<FSelect
											id="cpnoNatCd"
											v-model="formObj.cpnoNatCd"
											v-validate:required
											:options="phonNatCdList"
											:label="t('LBL.HR.COM.063')"
											label-field="natCdNm"
											value-field="natCd"
											empty="SELECT"
										/>
										<FInput
											id="cpno"
											v-model="formObj.cpno"
											v-validate:required
											:label="t('LBL.HR.COM.063')"
											no-label
											@input="handleInputChange('cpno')"
										/>
									</FlexBox>
								</FFormCol>
								<FFormCol class="col-span-2">
									<FlexBox class="gap-[4px]">
										<!-- 사내메일 -->
										<FInput
											id="incoEmlId"
											v-model="formObj.incoEmlId"
											v-validate:required
											:label="t('LBL.HR.EMP.018')"
											max-length="80"
											@input="handleInputChange('incoEmlId')"
										/>
										<FInput
											id="incoEmlAddr"
											v-model="formObj.incoEmlAddr"
											hidden
										/>
										<FInput v-model="incoEmlDomain.HR_BU_DMN_CD" no-label disabled />
									</FlexBox>
								</FFormCol>
								<FFormCol class="col-span-2">
									<FlexBox class="gap-[4px]">
										<!-- 영문명(이름) -->
										<FInput
											id="engEmpFnm"
											v-model="formObj.engEmpFnm"
											v-validate:required
											:label="t('LBL.HR.EMP.075')"
											max-length="50"
											@input="handleInputChange('engEmpFnm')"
										/>
										<!-- 영문명(성) -->
										<FInput
											id="engEmpLnm"
											v-model="formObj.engEmpLnm"
											v-validate:required
											max-length="50"
											:label="t('LBL.HR.EMP.075')"
											no-label
											@input="handleInputChange('engEmpLnm')"
										/>
									</FlexBox>
								</FFormCol>
								<FFormCol>
									<!-- 사원명(한자) -->
									<FInput
										id="empCcNm"
										v-model="formObj.empCcNm"
										max-length="50"
										:label="t('LBL.HR.EMP.005')"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 닉네임(이메일 표시이름) -->
									<FInput
										id="emlMrkNm"
										v-model="formObj.emlMrkNm"
										v-validate:required
										:label="t('LBL.HR.EMP.034')"
										max-length="50"
										@input="handleInputChange('emlMrkNm')"
									/>
								</FFormCol>
								<FFormCol class="col-span-2">
									<FlexBox class="gap-[4px]">
										<!-- 비상연락처 -->
										<FSelect
											id="ectcNatCd"
											v-model="formObj.ectcNatCd"
											v-validate:required
											:options="phonNatCdList"
											:label="t('LBL.HR.COM.019')"
											label-field="natCdNm"
											value-field="natCd"
											empty="SELECT"
										/>
										<FInput
											id="ectcTelno"
											v-model="formObj.ectcTelno"
											v-validate:number
											v-validate:required
											:label="t('LBL.HR.COM.019')"
											max-length="30"
											no-label
											@input="handleInputChange('ectcTelno')"
										/>
									</FlexBox>
								</FFormCol>
								<FFormCol>
									<!-- 비상연락처관계 -->
									<FSelect
										id="ectcRelCd"
										v-model="formObj.ectcRelCd"
										:options="fmlyRelCdList"
										:label="t('LBL.HR.COM.020')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 진행상태 -->
									<FInput
										id="hrAplySttsNm"
										v-model="formObj.hrAplySttsNm"
										:label="t('LBL.HR.COM.044')"
										disabled
									/>
								</FFormCol>
								<FFormCol class="col-span-2">
									<!-- 반려사유 -->
									<FInput
										id="snbkRsn"
										v-model="formObj.snbkRsn"
										:label="t('LBL.HR.COM.014')"
										disabled
									/>
								</FFormCol>
								<FFormCol>
									<label>{{ t('LBL.HR.COM.025') }}</label>
									<FButton @click="onClickUploadPop">
										 {{ t('LBL.HR.COM.025') }}
									</FButton>
								</FFormCol>
							</FFormRow>
						</FForm>
					</FGridCol>
					<FGridCol>
						<!-- 병역 -->
						<FTitle level="4" :name="t('LBL.HR.MILS.005')" />
						<FForm
							id="milsForm"
							ref="refMilsForm"
							v-model="formObj"
							:init-value="initObj"
							form-only
						>
							<FFormRow is-grid-cols="8">
								<FFormCol>
									<!-- 병역구분 -->
									<FSelect
										id="milsSeCd"
										v-model="formObj.milsSeCd"
										v-validate:required
										:options="cmCdList.MILS_SE_CD"
										:label="t('LBL.HR.MILS.006')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 군별 -->
									<FSelect
										id="milbCd"
										v-model="formObj.milbCd"
										:options="cmCdList.MILB_CD"
										:label="t('LBL.HR.MILS.003')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 병과 -->
									<FSelect
										id="mosCd"
										v-model="formObj.mosCd"
										:options="cmCdList.MOS_CD"
										:label="t('LBL.HR.MILS.004')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 계급 -->
									<FSelect
										id="dcrkCd"
										v-model="formObj.dcrkCd"
										:options="cmCdList.DCRK_CD"
										:label="t('LBL.HR.MILS.001')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 군번 -->
									<FInput
										id="milsn"
										v-model="formObj.milsn"
										:label="t('LBL.HR.MILS.002')"
										max-length="50"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 입대일자 -->
									<FDate
										id="enlDt"
										v-model="formObj.enlDt"
										:label="t('LBL.HR.DATE.015')"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 제대일자 -->
									<FDate
										id="lvamDt"
										v-model="formObj.lvamDt"
										:label="t('LBL.HR.DATE.021')"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 미필사유 -->
									<FTextarea
										id="incmplRsn"
										v-model="formObj.incmplRsn"
										:label="t('LBL.HR.MILS.007')"
										:rows="1"
										max-length="2000"
									/>
								</FFormCol>
							</FFormRow>
						</FForm>
					</FGridCol>

					<FGridCol>
						<!-- 장애/보훈 -->
						<FTitle
							level="4"
							:name="`${t('LBL.HR.DSBL.001')}/${t('LBL.HR.RWPT.001')}`"
						/>
						<FForm
							id="dsblForm"
							ref="refDsblForm"
							v-model="formObj"
							:init-value="initObj"
							form-only
						>
							<FFormRow is-grid-cols="8">
								<FFormCol>
									<!-- 장애구분 -->
									<FSelect
										id="dsblSeCd"
										v-model="formObj.dsblSeCd"
										v-validate:required
										:options="cmCdList.DSBL_SE_CD"
										:label="t('LBL.HR.DSBL.003')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 장애등급 -->
									<FSelect
										id="dsblGrdCd"
										v-model="formObj.dsblGrdCd"
										:options="cmCdList.DSBL_GRD_CD"
										:label="t('LBL.HR.DSBL.004')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 장애유형 -->
									<FSelect
										id="dsblTpCd"
										v-model="formObj.dsblTpCd"
										:options="cmCdList.DSBL_TP_CD"
										:label="t('LBL.HR.DSBL.005')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 장애경중증구분 -->
									<FSelect
										id="dsblMlsvCd"
										v-model="formObj.dsblMlsvCd"
										:options="cmCdList.DSBL_MLSV_CD"
										:label="t('LBL.HR.DSBL.002')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 장애인정일자 -->
									<FDate
										id="dsblRcgnDt"
										v-model="formObj.dsblRcgnDt"
										:label="t('LBL.HR.DATE.018')"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 보훈 -->
									<FSelect
										id="rwptSeCd"
										v-model="formObj.rwptSeCd"
										v-validate:required
										:options="cmCdList.RWPT_SE_CD"
										:label="t('LBL.HR.RWPT.001')"
										empty="SELECT"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 보훈번호 -->
									<FInput
										id="rwptNo"
										v-model="formObj.rwptNo"
										:label="t('LBL.HR.RWPT.003')"
										max-length="20"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 유공자명 -->
									<FInput
										id="mrtpNm"
										v-model="formObj.mrtpNm"
										:label="t('LBL.HR.RWPT.005')"
										max-length="50"
									/>
								</FFormCol>
								<FFormCol>
									<!-- 유공자관계 -->
									<FSelect
										id="fmlyRelCd"
										v-model="formObj.fmlyRelCd"
										:options="cmCdList.FMLY_REL_CD"
										:label="t('LBL.HR.RWPT.004')"
										empty="SELECT"
									/>
								</FFormCol>
							</FFormRow>
						</FForm>
					</FGridCol>
				</div>
			</FTab>
			<FTab
				:title="t('LBL.CM.ADDR.001')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 주소 -->
				<AUIGrid
					ref="gridAddr"
					:title="t('LBL.CM.ADDR.001')"
					:data="gridAddrData"
					:total="gridAddrTCnt"
					:column-layout="gridAddrCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridAddr')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridAddr')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EMP.008')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 경력 -->
				<AUIGrid
					ref="gridCrer"
					:title="t('LBL.HR.EMP.008')"
					:data="gridCrerData"
					:total="gridCrerTCnt"
					:column-layout="gridCrerCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridCrer')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridCrer')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EMP.030')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 학력 -->
				<AUIGrid
					ref="gridAcbg"
					:title="t('LBL.HR.EMP.030')"
					:data="gridAcbgData"
					:total="gridAcbgTCnt"
					:column-layout="gridAcbgCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridAcbg')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridAcbg')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EXAM.007')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 자격면허 -->
				<AUIGrid
					ref="gridQlfcLic"
					:title="t('LBL.HR.EXAM.007')"
					:data="gridQlfcLicData"
					:total="gridQlfcLicTCnt"
					:column-layout="gridQlfcLicCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridQlfcLic')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridQlfcLic')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EXAM.004')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 어학 -->
				<AUIGrid
					ref="gridLangst"
					:title="t('LBL.HR.EXAM.004')"
					:data="gridLangstData"
					:total="gridLangstTCnt"
					:column-layout="gridLangstCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridLangst')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridLangst')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EMP.007')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 가족 -->
				<AUIGrid
					ref="gridFmly"
					:title="t('LBL.HR.EMP.007')"
					:data="gridFmlyData"
					:total="gridFmlyTCnt"
					:column-layout="gridFmlyCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridFmly')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridFmly')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
			<FTab
				:title="t('LBL.HR.EMP.009')"
				:on-enter="onEnterTab"
				:on-leave="onLeaveTab"
			>
				<!-- 계좌 -->
				<AUIGrid
					ref="gridBacnt"
					:title="t('LBL.HR.EMP.009')"
					:data="gridBacntData"
					:total="gridBacntTCnt"
					:column-layout="gridBacntCols"
					:grid-props="gridDtlProps"
					class="grid-height"
				>
					<template #total></template>
					<template #button>
						<FButton icon="ico-16-rowadd" @click="addRow('gridBacnt')">
							{{ `${t('BTN.CM.ADDROW.001')}` }}
						</FButton>
						<FButton icon="ico-16-rowdel" @click="delRow('gridBacnt')">
							{{ `${t('BTN.CM.DELETEROW.001')}` }}
						</FButton>
					</template>
				</AUIGrid>
			</FTab>
		</FTabs>
	</div>

	<!-- Popup -->
	<component :is="popUpInstance" />

	<!-- 파일 업로드 PopUp -->
	<!--  -->
	<FUploadPop
		v-show="false"
		ref="refUploadPicPop"
		v-model="formObj.fileGrpNo"
		:on-confirm="onConfirmPhoto"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		:ext="dndExt"
		external
	/>

	<!-- 가족 -->
	<FUploadPop
		v-show="false"
		ref="refFmlyUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmFmly"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>

	<!-- 자격/면허 -->
	<FUploadPop
		v-show="false"
		ref="refQlfcLicUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmQlfcLic"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>

	<!-- 어학 -->
	<FUploadPop
		v-show="false"
		ref="refLangstUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmLangst"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>

	<!-- 계좌 -->
	<FUploadPop
		v-show="false"
		ref="refBacntUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmBacnt"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>

	<!-- 경력 -->
	<FUploadPop
		v-show="false"
		ref="refCrerUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmCrer"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>

	<!-- 학력 -->
	<FUploadPop
		v-show="false"
		ref="refAcbgUploadPop"
		v-model="currFileGrpNo"
		:max-cnt="maxCnt"
		mode="popup"
		:on-confirm="onConfirmAcbg"
		:comp-cd="commonUtils.gfn_getRouteParam().compCd"
		external
	/>
</template>

<script setup>
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useMessage } from '@fila/lib/plugins/message/message';
import commonCalendar from '@fila/lib/utils/commonCalendar';
import dateUtils from '@fila/lib/utils/dateUtils';
import usePopup from '@fila/lib/utils/usePopup';
import AddressPop from '@fila/lib/components/popup/cm/AddressPop'; // [공통]우편번호

const { t } = useI18n();
const message = useMessage();
const popUpInstance = shallowRef(null);
const cmCdList = ref([]);
const fmlyRelCdList = ref([]);
const phonNatCdList = ref([]);
/* 이메일 도메인 정보 */
const hrBuEmlDmnList = ref([]);
const incoEmlDomain = ref({
	HR_BU_DMN_CD: '',
});
let TEMP_KEY = '';
/*******
 * 파일업로드
 *******/
// 파일 업로드를 위한 ref
const currFileGrpNo = ref(null);
const maxCnt = computed(() => {
	return 5;
});
const refFmlyUploadPop = ref(null);
const refQlfcLicUploadPop = ref(null);
const refLangstUploadPop = ref(null);
const refBacntUploadPop = ref(null);
const refCrerUploadPop = ref(null);
const refAcbgUploadPop = ref(null);
const refUploadPicPop = ref(null);
const dndExt = ref('png|jpg');

/*******
 * 탭
 *******/

/* FTabs 변수 */
const currentIndex = 0;
const currentDisable = ref([]);
const currentInfo = ref({
	currentIndex: currentIndex,
	currentDisable: currentDisable,
});

const TAB_MAP = {
	PINFO: 0, // 인적사항
	ADDR: 1, // 주소
	CRER: 2, // 경력
	ACBG: 3, // 학력
	QLFC_LIC: 4, // 자격면허
	LANGST: 5, // 어학
	FMLY: 6, // 가족
	BACNT: 7, // 계좌
};

/**
 * 탭이동
 */
function moveTab(index) {
	//이동하고자 하는 탭으로 강제 이동
	currentInfo.value.currentIndex = index;
}

async function onEnterTab() {
}

/**
 * 사원번호가 있어야만 탭 옮길 수 있도록..
 */
function onLeaveTab() {
	// index => 이동하고자 하는 탭 번호
	// tabIndex => 현재 탭 번호
	// const tabIndex = currentInfo.value.currentIndex;
	// // 인사신청상태코드 가 등록(01) 이 아니면
	// if (formObj.value.hrAplySttsCd != '01') {
	// 	message.showAlert({
	// 		// 사원 {0}을(를) 먼저 저장해 주세요
	// 		message: t('MSG.HR.COM.015', [t('LBL.HR.EMP.001')]),
	// 	});
	// 	return false; // 탭 이동 불가
	// } else {
	// 	return true; // 탭 이동 허용
	// }
	return true;
}

/*******
 * 폼
 *******/

const refHrInfoForm = ref(null); // 기본정보
const refMilsForm = ref(null); // 병역
const refDsblForm = ref(null); // 장애/보훈

// 초기화를 위한 객체 변수
let initObj = {
	emlMrkNm: '',
};

// form 데이터 바인딩 및 초기 데이터 설정을 위한 ref
const formObj = ref(initObj);
const isReadOnly = ref(false);

/**********************************************************
 * 그리드
 **********************************************************/

// 주소 Addr
const gridAddr = ref(null);
const gridAddrTCnt = ref(0);
const gridAddrCols = ref([
	{
		dataField: 'addrKdCd',
		headerText: t('LBL.CM.SE.001'), // 구분
		width: 150,
		align: 'left',
		required: true,
		addEdit: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.ADDR_KD_CD;
			},
		},
	},

	{
		dataField: 'zipcd',
		headerText: t('LBL.HR.COM.068'), // 우편번호
		width: 150,
		renderer: {
			type: 'Popup',
			/**
			 * 팝업 동적 생성 및 props, callback 지정 => 분리 가능
			 * */
			popupFunction: (rowIndex, value, item, dataField, props) => {
				openGridPopup(rowIndex, value, item, dataField, props);
			},
		},
	},
	{
		dataField: 'addr',
		headerText: t('LBL.CM.ADDR.001'), // 주소
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 500,
		},
	},
	{
		dataField: 'dtlAddr',
		headerText: t('LBL.CM.DTLADDRESS.001'), // 상세주소
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
]);
const gridAddrData = ref([]);

// 경력 Crer
const gridCrer = ref(null);
const gridCrerTCnt = ref(0);
const gridCrerCols = ref([
	{
		dataField: 'encoDt',
		headerText: t('LBL.HR.DATE.016'), // 입사일자
		width: 120,
		dataType: 'date',
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'rsgnDt',
		headerText: t('LBL.HR.DATE.026'), // 퇴사일자
		width: 120,
		dataType: 'date',
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'compNm',
		headerText: t('LBL.HR.COMP.001'), // 회사명
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'jbgrdNm',
		headerText: t('LBL.HR.JOB.005'), // 직급
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 50,
		},
	},
	{
		dataField: 'jbttlNm',
		headerText: t('LBL.HR.JOB.015'), // 직책
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 50,
		},
	},
	{
		dataField: 'emplShpCd',
		headerText: t('LBL.HR.EMP.012'), // 고용형태
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.EMPL_SHP_CD;
			},
		},
	},
	{
		dataField: 'dutyNm',
		headerText: t('LBL.HR.JOB.011'), // 직무
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 50,
		},
	},
	{
		dataField: 'tskDtlCntn',
		headerText: t('LBL.HR.COM.106'), // 상세업무
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 2000,
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refCrerUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
	{
		dataField: 'rmrk',
		headerText: t('LBL.HR.COM.072'), // 비고
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 4000,
		},
	},
]);
const gridCrerData = ref([]);

// 학력 Acbg
const gridAcbg = ref(null);
const gridAcbgTCnt = ref(0);
const gridAcbgCols = ref([
	{
		dataField: 'acbgCd',
		headerText: t('LBL.HR.EMP.030'), // 학력
		width: 120,
		align: 'left',
		required: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.ACBG_CD;
			},
		},
	},
	{
		dataField: 'acdgCd',
		headerText: t('LBL.HR.EMP.076'), // 학위
		width: 120,
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.ACDG_CD;
			},
		},
	},
	{
		dataField: 'schlNm',
		headerText: t('LBL.HR.EMP.045'), // 학교명
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'mjrNm',
		headerText: t('LBL.HR.EMP.042'), // 전공
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'mnrNm',
		headerText: t('LBL.HR.EMP.077'), // 부전공
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'campusTpCd',
		headerText: t('LBL.HR.EMP.040'), // 본/분교
		width: 120,
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.CAMPUS_TP_CD;
			},
		},
	},
	{
		dataField: 'campusLocCntn',
		headerText: t('LBL.HR.COM.103'), // 위치
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'entrYm',
		headerText: t('LBL.HR.EMP.041'), // 입학년월
		width: 120,
		dataType: 'date',
		...commonCalendar.getCalendarRender('ym'),
		renderer: {
			...commonCalendar.getCalendarRender('ym').renderer,
		},
	},
	{
		dataField: 'grdtYm',
		headerText: t('LBL.HR.EMP.044'), // 졸업년월
		width: 120,
		dataType: 'date',
		...commonCalendar.getCalendarRender('ym'),
		renderer: {
			...commonCalendar.getCalendarRender('ym').renderer,
		},
	},
	{
		dataField: 'grdtSeCd',
		headerText: t('LBL.HR.EMP.043'), // 졸업구분
		width: 120,
		align: 'left',
		required: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.GRDT_SE_CD;
			},
		},
	},
	{
		dataField: 'fnlAcbgYn',
		headerText: t('LBL.HR.EMP.047'), // 최종학교여부
		renderer: {
			type: 'Checkbox',
			showLabel: false,
			editable: true,
			checkValue: 'Y',
			unCheckValue: 'N',
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refAcbgUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
]);
const gridAcbgData = ref([]);

// 자격면허 QlfcLic
const gridQlfcLic = ref(null);
const gridQlfcLicTCnt = ref(0);
const gridQlfcLicCols = ref([
	{
		dataField: 'qlfcLicKdNm',
		headerText: t('LBL.HR.EXAM.008'), // 자격종목
		align: 'left',
		required: true,
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
		},
	},
	{
		dataField: 'qlfcLicGrdNm',
		headerText: t('LBL.HR.EXAM.006'), // 자격등급
		align: 'left',
		required: true,
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
		},
	},
	{
		dataField: 'pbltInstNm',
		headerText: t('LBL.HR.EXAM.001'), // 발행처
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'acqsDt',
		headerText: t('LBL.HR.DATE.030'), // 취득일자
		width: 120,
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'vldtDt',
		headerText: t('LBL.HR.DATE.014'), // 유효일자
		width: 120,
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refQlfcLicUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
	{
		dataField: 'rmrk',
		headerText: t('LBL.HR.COM.072'), // 비고
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 4000,
		},
	},
]);
const gridQlfcLicData = ref([]);

// 어학 Langst
const gridLangst = ref(null);
const gridLangstTCnt = ref(0);
const gridLangstCols = ref([
	{
		dataField: 'frglKdCd',
		headerText: t('LBL.HR.EXAM.005'), // 외국어종류
		align: 'left',
		required: true,
		addEdit: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.FRGL_KD_CD;
			},
		},
	},
	{
		dataField: 'langstExamCd',
		headerText: t('LBL.HR.EXAM.003'), // 시험명
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: (rowIndex, columnIndex, item) => {
				return cmCdList.value.LANGST_EXAM_CD.filter(
					langstExam => langstExam.rmrk1 == item.frglKdCd,
				);
			},
		},
	},
	{
		dataField: 'examScr',
		headerText: t('LBL.HR.COM.040'), // 점수
		align: 'right',
		dataType: 'numeric',
		formatString: '###.#####',
		editRenderer: {
			type: 'InputEditRenderer',
			regExp: '^[0-9.]*$',
			textAlign: 'right',
			maxlength: 15,
		},
	},
	{
		dataField: 'langstGrdCd',
		headerText: t('LBL.HR.COM.010'), // 등급
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: (rowIndex, columnIndex, item) => {
				return cmCdList.value.LANGST_GRD_CD.filter(
					langstGrd => langstGrd.rmrk1 == item.langstExamCd,
				);
			},
		},
	},
	{
		dataField: 'acqsDt',
		headerText: t('LBL.HR.DATE.030'), // 취득일자
		width: 120,
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'vldtDt',
		headerText: t('LBL.HR.DATE.014'), // 유효일자
		width: 120,
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'implInstNm',
		headerText: t('LBL.HR.EXAM.002'), // 시행처
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refLangstUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
	{
		dataField: 'rmrk',
		headerText: t('LBL.HR.COM.072'), // 비고
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 4000,
		},
	},
]);
const gridLangstData = ref([]);

// 가족 Fmly
const gridFmly = ref(null);
const gridFmlyTCnt = ref(0);
const gridFmlyCols = ref([
	{
		dataField: 'fmlyRelCd',
		headerText: t('LBL.HR.EMP.013'), // 관계
		width: 120,
		align: 'left',
		required: true,
		addEdit: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.FMLY_REL_CD.filter(e => e.dtlCd !== '50'); // "본인" 제외
			},
		},
	},
	{
		dataField: 'fmlyNm',
		headerText: t('LBL.HR.EMP.024'), // 이름
		width: 130,
		align: 'left',
		required: true,
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 50,
		},
	},
	{
		dataField: 'pinKdCd',
		headerText: t('LBL.HR.EMP.065'), // 개인식별번호종류
		width: 150,
		align: 'left',
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.PIN_KD_CD;
			},
			disabledFunction: function(rowIndex, columnIndex, value, item) {
				if (item.pinBtnEnable !== 'N') {
					return true;
				} else {
					return false;
				}
			},
		},
	},
	{
		dataField: 'pin',
		headerText: t('LBL.HR.EMP.026'), // 개인식별번호
		align: 'left',
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'IconRenderer',
			iconPosition: 'aisleRight', // 아이콘 위치
			iconWidth: 16,
			iconHeight: 16,
			iconFunction: function(rowIndex, columnIndex, value, item) {
				if (item.pinBtnEnable !== 'N') {
					return '/images/ico-16-search.svg';
				} else {
					return '';
				}
			},
			disabledFunction: function(rowIndex, columnIndex, value, item) {
				if (item.pinBtnEnable !== 'N') {
					return true;
				} else {
					return false;
				}
			},
			editable: false,
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				fn_onClickPinNo({
					pinGbn: 'fmly',
					compCd: event.item.compCd,
					empNo: event.item.empNo,
					fmlyRelCd: event.item.fmlyRelCd,
					fmlySn: event.item.fmlySn,
				});
			},
		},
	},
	{
		dataField: 'telnoNatCd',
		headerText: t('LBL.HR.EMP.073'), // 전화번호국가코드
		width: 150,
		renderer: {
			type: 'Dropdown',
			keyField: 'natCd',
			valueField: 'natCdNm',
			listFunction: () => {
				return phonNatCdList.value;
			},
		},
	},
	{
		dataField: 'telno',
		headerText: t('LBL.HR.EMP.022'), // 연락처
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 30,
		},
	},
	{
		dataField: 'brdt',
		headerText: t('LBL.HR.EMP.020'), // 생년월일
		width: 120,
		...commonCalendar.getCalendarRender('ymd'),
		renderer: {
			...commonCalendar.getCalendarRender('ymd').renderer,
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refFmlyUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
]);
const gridFmlyData = ref([]);

// 계좌 Bacnt
const gridBacnt = ref(null);
const gridBacntTCnt = ref(0);
const gridBacntCols = ref([
	{
		dataField: 'salBacntKdCd',
		headerText: t('LBL.HR.EMP.010'), // 계좌구분
		width: 150,
		align: 'left',
		required: true,
		addEdit: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.SAL_BACNT_KD_CD;
			},
		},
	},
	{
		dataField: 'bankCd',
		headerText: t('LBL.HR.COM.033'), // 은행
		width: 200,
		align: 'left',
		required: true,
		renderer: {
			type: 'Dropdown',
			listFunction: () => {
				return cmCdList.value.BANK_CD;
			},
		},
	},
	{
		dataField: 'acno',
		headerText: t('LBL.HR.EMP.011'), // 계좌번호
		align: 'left',
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'IconRenderer',
			iconPosition: 'aisleRight', // 아이콘 위치
			iconWidth: 16,
			iconHeight: 16,
			iconFunction: function(rowIndex, columnIndex, value, item) {
				if (item.pinBtnEnable !== 'N') {
					return '/images/ico-16-search.svg';
				} else {
					return '';
				}
			},
			disabledFunction: function(rowIndex, columnIndex, value, item) {
				if (item.pinBtnEnable !== 'N') {
					return true;
				} else {
					return false;
				}
			},
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				fn_onClickPinNo({
					pinGbn: 'bacnt',
					compCd: event.item.compCd,
					empNo: event.item.empNo,
					salBacntKdCd: event.item.salBacntKdCd,
				});
			},
		},
	},
	{
		dataField: 'dpstrNm',
		headerText: t('LBL.HR.EMP.062'), // 예금주
		width: 150,
		align: 'left',
		required: true,
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'swiftCd',
		headerText: t('LBL.HR.EMP.063'), // SWIFT코드
		tooltip: t('MSG.HR.COM.016'),
		width: 150,
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 20,
		},
	},
	{
		dataField: 'bankAddr',
		headerText: t('LBL.HR.COM.152'), // 은행주소
		tooltip: t('MSG.HR.COM.016'),
		align: 'left',
		editRenderer: {
			type: 'InputEditRenderer',
			textAlign: 'left',
			maxlength: 100,
		},
	},
	{
		dataField: 'fileGrpNo',
		headerText: t('LBL.HR.COM.100'), // 첨부파일
		width: 80,
		editable: false,
		required: true,
		renderer: {
			//팝업 연결시 iconrenderer 추가.
			type: 'ButtonRenderer',
			onClick: function(event) {
				//아이콘 클릭시 팝업 호출.
				currFileGrpNo.value = event.item?.fileGrpNo ?? null;
				nextTick(() => {
					refBacntUploadPop.value.onClickOpen();
				});
			},
			labelText: t('LBL.HR.EVLT.172'), //파일첨부
		},
	},
]);
const gridBacntData = ref([]);
const gridDtlProps = ref({});

/**********************************************************
 * Button
 **********************************************************/

/* 조회조건 */
const searchModel = ref({
	langCd: '',
	compCd: '',
	empNo: '',
	hrBuCd: '',
});

/**
 * [초기화]버튼 클릭 시 실행하는 함수
 */
async function onClickReset() {
	onClickSearch();
}

/**
 * [조회]버튼 클릭 시 실행하는 함수
 */
async function onClickSearch() {
	// Confirm 창 발생. confirm - true, cancel - false 반환
	let result = await message.isEdited([
		gridAddr.value,
		gridCrer.value,
		gridAcbg.value,
		gridQlfcLic.value,
		gridLangst.value,
		gridFmly.value,
		gridBacnt.value,
	]);

	if (result) {
		getHrInfoRegAplyList();
	} else {
		return;
	}
}

/**
 * 탭페이지 데이터 초기화
 */
function tabInit() {
	moveTab(TAB_MAP.PINFO); // 인적사항탭으로 이동

	formObj.value = {}; // 선택한 Row 정보 초기화
	gridAddr.value.clearGridData(); // 주소
	gridCrer.value.clearGridData(); // 경력
	gridAcbg.value.clearGridData(); // 학력
	gridQlfcLic.value.clearGridData(); // 자격면허
	gridLangst.value.clearGridData(); // 어학
	gridFmly.value.clearGridData(); // 가족
	gridBacnt.value.clearGridData(); // 계좌

	gridAddrTCnt.value = 0;
	gridCrerTCnt.value = 0;
	gridAcbgTCnt.value = 0;
	gridQlfcLicTCnt.value = 0;
	gridLangstTCnt.value = 0;
	gridFmlyTCnt.value = 0;
	gridBacntTCnt.value = 0;
}

/**
 * 인사정보등록신청(외부접속) 인적사항탭 조회
 **/
async function getHrInfoRegAplyList() {
	apiCall
		.get(
			'HR_API/hr/global/public/getEmp',
			{
				...searchModel.value,
			},
			{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
		)
		.then(res => {
			// 데이터가 없을때 바인딩 에러 안나도록
			if (res.data.data.EMP != null) {
				initObj = res.data.data.EMP;
				formObj.value = initObj;

				// 인사신청상태코드 없거나 등록(01) 이거나 반려(04) 일때만
				if (
					formObj.value.hrAplySttsCd == '01' ||
					formObj.value.hrAplySttsCd == '04' ||
					commonUtils.gfn_isEmpty(formObj.value.hrAplySttsCd)
				) {
					fn_formDisalbe(false);
					isReadOnly.value = false; // 첨부파일 등록못하도록
					// gridDtlProps.value = { editable: false };
				} else {
					fn_formDisalbe(true);
					isReadOnly.value = true;
					// gridDtlProps.value = { editable: true };
				}
				if (commonUtils.gfn_isEmpty(formObj.value)) {
					tabInit();
				} else {
					getTabSch();
				}
			}
		});
}

/**
 * 나머지 탭 조회
 **/
async function getTabSch() {
	apiCall
		.get(
			'HR_API/hr/global/public/listDtl',
			{
				...searchModel.value,
			},
			{ loader: true, headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
		)
		.then(res => {
			gridAddrData.value = res.data.data.ADDR; // 주소
			gridAddrTCnt.value = res.data.data.ADDR.length;
			gridCrerData.value = res.data.data.CRER; // 경력
			gridCrerTCnt.value = res.data.data.CRER.length;
			gridAcbgData.value = res.data.data.ACBG; // 학력
			gridAcbgTCnt.value = res.data.data.ACBG.length;
			gridQlfcLicData.value = res.data.data.QLFCLIC; // 자격면허
			gridQlfcLicTCnt.value = res.data.data.QLFCLIC.length;
			gridLangstData.value = res.data.data.LANGST; // 어학
			gridLangstTCnt.value = res.data.data.LANGST.length;
			gridFmlyData.value = res.data.data.FMLY; // 가족
			gridFmlyTCnt.value = res.data.data.FMLY.length;
			gridBacntData.value = res.data.data.BACNT; // 계좌
			gridBacntTCnt.value = res.data.data.BACNT.length;
		});
}

/**
 * 폼의 입력 방식
 */
const handleInputChange = fieldName => {
	// 영문숫자만, 공통 Util 화면 참조 [콤마가있는 숫자형식, 숫자만, 특수문자금지, 영문숫자만]
	if (
		fieldName === 'engEmpFnm' ||
		fieldName === 'engEmpLnm' ||
		fieldName === 'emlMrkNm'
	) {
		formObj.value[fieldName] = alphaValidator(formObj.value[fieldName]);
	} else if (fieldName === 'incoEmlId') {
		formObj.value[fieldName] = emailValidator(formObj.value[fieldName]);
	} else if (fieldName === 'cpno' || fieldName === 'ectcTelno') {
		formObj.value[fieldName] = commonUtils.gfn_allowOnlyNumbers(
			formObj.value[fieldName],
		);
	}
};

/**
 * 영문자와 숫자만 입력되도록 변환
 *
 * @method alphaValidator
 * @param {string} input 입력 문자열
 * @returns {string} 영문자와 숫자, 공백만 포함된 문자열
 */
function alphaValidator(input) {
	if (commonUtils.gfn_isEmpty(input)) {
		return input;
	}
	// 영문자와 숫자 이외의 문자를 제거하고 영문자와 숫자와 공백 남깁니다.
	let result = input.replace(/[^a-zA-Z0-9 ]/g, '');

	return result;
}

/**
 * 영문자와 숫자, 특수문자(.(콤마), _(언더스코어))만 입력되도록 변환
 *
 * @method emailValidator
 * @param {string} input 입력 문자열
 * @returns {string} 영문자와 숫자, 공백만 포함된 문자열
 */
function emailValidator(input) {
	if (commonUtils.gfn_isEmpty(input)) {
		return input;
	}
	// 영문자와 숫자 이외의 문자를 제거하고 영문자와 숫자와 공백 남깁니다.
	let result = input.replace(/[^a-zA-Z0-9._@%+-]/g, '');

	return result;
}

/**
 * 변경사항 체크
 */
function chkChange() {
	//변경사항 체크
	if (
		refHrInfoForm.value.isModified() ||
		refMilsForm.value.isModified() ||
		refDsblForm.value.isModified() ||
		gridAddr.value.isModified() ||
		gridCrer.value.isModified() ||
		gridAcbg.value.isModified() ||
		gridQlfcLic.value.isModified() ||
		gridLangst.value.isModified() ||
		gridFmly.value.isModified() ||
		gridBacnt.value.isModified()
	) {
		return true;
	} else {
		return false;
	}
}

function onClickUpload() {
	// 파일이름 = 회사_사번 << 중복되면 안됨
	if (
		commonUtils.gfn_isEmpty(formObj.value.compCd) ||
		commonUtils.gfn_isEmpty(formObj.value.empNo)
	) {
		message.showAlert({
			// 사원 {0}을(를) 먼저 저장해 주세요
			message: t('MSG.HR.COM.015', [t('LBL.HR.EMP.001')]),
		});
		return false;
	}

	thumbnail.value.fileNm = formObj.value.compCd + '_' + formObj.value.empNo;
	refUploadPicPop.value.onClickOpen();
}

/**
 * [저장]버튼 클릭 시 실행하는 함수
 */
async function onClickSave() {
	//변경사항 체크
	if (!chkChange()) {
		message.showAlert({
			//변경사항이 없습니다.
			message: t('MSG.CM.COM.001'),
		});

		return false;
	}

	if (!fn_processValidation()) {
		return false;
	}

	if (!refHrInfoForm.value.validate()) return; // 기본정보
	if (commonUtils.gfn_isEmpty(formObj.value.fileGrpNo)) {
		message.showAlert({
			message:t('MSG.HR.COM.043', [t('LBL.HR.COM.025')]), //사진 첨부파일은 필수 입니다.
		});
		return false;
	}
	if (!refMilsForm.value.validate()) return; // 병역정보
	if (!refDsblForm.value.validate()) return; // 장애/보훈정보

	/**/
	// 필수값 검증
	if (
		!gridAddr.value.validateGridData() ||
		!gridCrer.value.validateGridData() ||
		!gridAcbg.value.validateGridData() ||
		!gridQlfcLic.value.validateGridData() ||
		!gridLangst.value.validateGridData() ||
		!gridFmly.value.validateGridData() ||
		!gridBacnt.value.validateGridData()
	) {
		return false;
	}

	// 중복검사(주소종류코드)
	if (!gridAddr.value.checkComplexDuplicateValue('addrKdCd')) {
		return false;
	}

	// 중복검사(가족관계코드)
	if (!gridFmly.value.checkComplexDuplicateValue('fmlyRelCd')) {
		return false;
	}

	// 중복검사(급여계좌종류코드)
	if (!gridBacnt.value.checkComplexDuplicateValue('salBacntKdCd')) {
		return false;
	}

	//주소, 학력, 계좌 최소 1건 이상 등록여부 체크
	if (gridAddr.value.getGridData().length < 1) {
		message.showAlert({
			message:t('MSG.HR.COM.053', [t('LBL.CM.ADDR.001'), '1']), //주소는 최소 1건 이상 필수 입력해야 합니다.
		});
		return false;
	}

	if (gridAcbg.value.getGridData().length < 1) {
		message.showAlert({
			message:t('MSG.HR.COM.053', [t('LBL.HR.EMP.030'), '1']), //학력은 최소 1건 이상 필수 입력해야 합니다.
		});
		return false;
	}

	if (gridBacnt.value.getGridData().length < 1) {
		message.showAlert({
			message: t('MSG.HR.COM.053', [t('LBL.HR.EMP.009'), '1']), //계좌는 최소 1건 이상 필수 입력해야 합니다.
		});
		return false;
	}

	// 입대일자 시작일자, 종료일자 검증
	if (dateUtils.isBefore(formObj.value.lvamDt, formObj.value.enlDt)) {
		// 시작일자보다 종료일자가 빠릅니다.
		message.showAlert({ message: t('MSG.CM.VAL.177') });
		return;
	}

	//이메일 한번더 조합
	// formObj.value.incoEmlAddr = formObj.value.incoEmlId + incoEmlDomain.value;

	// 저장 메시지 출력(유효성 검사, 중복체크 모두 True 경우)
	message.showConfirm({
		// 저장하시겠습니까?
		message: t('MSG.CM.SAVE.001'),
		onConfirm: async () => {
			await apiCall
				.post(
					'HR_API/hr/global/public/save',
					{
						empForm: formObj.value,
						addrList: gridAddr.value.getChangedData(),
						crerList: gridCrer.value.getChangedData(),
						acbgList: gridAcbg.value.getChangedData(),
						qlfcLicList: gridQlfcLic.value.getChangedData(),
						langstList: gridLangst.value.getChangedData(),
						fmlyList: gridFmly.value.getChangedData(),
						bacntList: gridBacnt.value.getChangedData(),
					},
					{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
				)
				.then(() => {
					message.showAlert({
						//저장되었습니다.
						message: t('MSG.CM.SUC.003'),
						onConfirm: () => {
							getHrInfoRegAplyList();
						},
					});
				});
		},
	});
}

/**
 * [신청]버튼 클릭 시 실행하는 함수
 */
async function onClickAply() {
	//변경사항 체크
	if (chkChange()) {
		message.showAlert({
			//저장 후 승인요청이 가능합니다.
			message: t('MSG.HR.EVLT.028'),
		});

		return false;
	}

	if (formObj.value.hrAplySttsCd == '02') return false;

	message.showConfirm({
		// {신청} 하시겠습니까?
		message: t('MSG.CM.FIX.001', [t('LBL.CM.APLY.001')]),
		onConfirm: () => {
			apiCall
				.post(
					'HR_API/hr/global/public/saveAply',
					{
						empForm: formObj.value,
						qlfcLicList: gridQlfcLic.value.getGridData(),
						langstList: gridLangst.value.getGridData(),
						fmlyList: gridFmly.value.getGridData(),
						bacntList: gridBacnt.value.getGridData(),
					},
					{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
				)
				.then(() => {
					message.showAlert({
						//저장되었습니다.
						message: t('MSG.CM.SUC.003'),
						onConfirm: () => {
							getHrInfoRegAplyList();
						},
					});
				});
		},
	});
}

/**
 *  선택된 행이 있을 경우만 진행
 */
function fn_processValidation() {
	if (commonUtils.gfn_isEmpty(formObj.value.empNo)) {
		message.showAlert({
			//선택된 {사원정보}이(가) 없습니다.
			message: t('MSG.CM.VAL.163', [
				`${t('LBL.HR.EMP.001')}${t('LBL.CM.INFO.001')}`,
			]),
		});

		return false;
	}

	if (
		!(
			formObj.value.hrAplySttsCd == '01' ||
			formObj.value.hrAplySttsCd == '04' ||
			commonUtils.gfn_isEmpty(formObj.value.hrAplySttsCd)
		)
	) {
		message.showAlert({
			//{승인요청건}은(는) 수정할 수 없습니다.
			message: t('MSG.CM.ERR.152', [
				`${t('LBL.HR.COM.064')}${t('LBL.CM.CASES.001')}`,
			]),
		});

		return false;
	}

	return true;
}

/**
 * [행추가] 버튼
 */
async function addRow(selGrid) {
	if (!fn_processValidation()) {
		return false;
	}

	switch (selGrid) {
		case 'gridAddr': // 주소
			gridAddr.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
			});
			break;
		case 'gridCrer': // 경력
			gridCrer.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
			});
			break;
		case 'gridAcbg': // 학력
			gridAcbg.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
			});
			break;
		case 'gridQlfcLic': // 자격면허
			gridQlfcLic.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
			});
			break;
		case 'gridLangst': // 어학
			gridLangst.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
			});
			break;
		case 'gridFmly': // 가족
			gridFmly.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
				pinBtnEnable: 'N',
				locale: searchModel.value.langCd,
				telnoNatCd: commonUtils.gfn_getUserInfo().g_natCd,
			});
			break;
		case 'gridBacnt': // 계좌
			gridBacnt.value.addRow({
				compCd: formObj.value.compCd,
				empNo: formObj.value.empNo,
				pinBtnEnable: 'N',
			});
			break;
		default:
			break;
	}
}

/**
 * [행삭제] 버튼
 */
async function delRow(selGrid) {
	if (!fn_processValidation()) {
		return false;
	}

	switch (selGrid) {
		case 'gridAddr': // 주소
			gridAddr.value.removeRow('selectedIndex');
			break;
		case 'gridCrer': // 경력
			gridCrer.value.removeRow('selectedIndex');
			break;
		case 'gridAcbg': // 학력
			gridAcbg.value.removeRow('selectedIndex');
			break;
		case 'gridQlfcLic': // 자격면허
			gridQlfcLic.value.removeRow('selectedIndex');
			break;
		case 'gridLangst': // 어학
			gridLangst.value.removeRow('selectedIndex');
			break;
		case 'gridFmly': // 가족
			gridFmly.value.removeRow('selectedIndex');
			break;
		case 'gridBacnt': // 계좌
			gridBacnt.value.removeRow('selectedIndex');
			break;

		default:
			break;
	}
}

/**
 * 복호화
 */
function fn_onClickPinNo(item) {
	if (commonUtils.gfn_isEmpty(item.empNo)) return;

	item.fmlySn = item.fmlySn || 0;
	item.salBacntKdCd = item.salBacntKdCd || '';

	apiCall
		.get('HR_API/hr/global/public/listPinNo', item, {
			loader: true,
			headers: {
				'SYSTEM-TEMP-KEY': TEMP_KEY,
			},
		})
		.then(res => {
			let pinNo = res.data.data.PIN?.pin;

			let rowIndex = 0;
			switch (item.pinGbn) {
				case 'fmly': // 가족
					rowIndex = gridFmly.value.getSelectedIndex()[0];
					gridFmly.value.setCellValue(rowIndex, 'pin', pinNo); // 개인식별번호
					gridFmly.value.setCellValue(rowIndex, 'pinBtnEnable', 'N'); // 개인식별번호
					break;
				case 'bacnt': // 계좌
					rowIndex = gridBacnt.value.getSelectedIndex()[0];
					gridBacnt.value.setCellValue(rowIndex, 'acno', pinNo); // 계좌번호
					gridBacnt.value.setCellValue(rowIndex, 'pinBtnEnable', 'N'); // 개인식별번호
					break;
				default:
					break;
			}
		});
}

/**********************************************************
 * Callback 함수
 **********************************************************/

/**********************************************************
 * Function
 **********************************************************/

/**
 * 그리드 팝업
 * */
function openGridPopup(rowIndex, value, item, dataField, props, callback) {
	let __popupComp = null;
	let __callback = () => {
	};
	let __props = {};

	switch (dataField) {
		case 'zipcd':
			// 띄울 팝업 할당
			__popupComp = AddressPop;
			// 팝업의 props
			__props = {
				type: 'post',
				height: '600',
				// searchWord: custCd 값을 세팅하여 parameter로 넘겨줘야 함
				inParam: { ...item, searchWord: commonUtils.gfn_nvl(value, '') },
			};
			/**
			 * @param {object} data 팝업에서 select emit으로 넘겨받은 데이터(선택된 행 데이터)
			 * */
			__callback = data => {
				gridAddr.value.setCellValue(rowIndex, 'zipcd', data['zipNo']);
				gridAddr.value.setCellValue(rowIndex, 'addr', data['roadAddr']);
			};
			break;
		default:
			return;
	}
	/**
	 * <component/>에 팝업 컴포넌트 할당
	 * usePopup 함수 => popup 컴포넌트에 props를 바인딩하여 vnode 생성 후 vnode 랜더
	 * popUpInstance ref => 할당된 컴포넌트를 <component>에 이식
	 **/
	popUpInstance.value = usePopup(
		__popupComp,
		__props,
		__callback,
		getCurrentInstance(),
	);
	nextTick(() => {
		// DOM Update 완료되면 팝업 open
		popUpInstance.value.onKeyPressEnter();
		//popUpInstance.value.open();
	});
}

/**
 * 폼 비활성화
 */
function fn_formDisalbe(param) {
	if (param) {
		refHrInfoForm.value.onDisable(); // 기본정보
		refMilsForm.value.onDisable(); // 병역
		refDsblForm.value.onDisable(); // 장애/보훈
	} else {
		refHrInfoForm.value.onEnable(); // 기본정보
		refMilsForm.value.onEnable(); // 병역
		refDsblForm.value.onEnable(); // 장애/보훈
	}
}

/**
 * 이벤트 초기화
 */
const initEvent = () => {
	//cellEditEndBefore
	gridAddr.value.bind('cellEditEndBefore', event => {
		if (commonUtils.gfn_isEmpty(event.value)) {
			if (event.dataField === 'zipcd') {
				// 편집 진입 시 값 초기화
				gridAddr.value.setCellValue(event.rowIndex, 'zipcd', null);
				gridAddr.value.setCellValue(event.rowIndex, 'addr', null);
			}
		}
	});

	// enter key in 시, 팝업 open
	gridAddr.value.bind('cellEditEnd', event => {
		if (event.which == '13') {
			if (event.dataField === 'zipcd') {
				openGridPopup(event.rowIndex, event.value, event.item, event.dataField);
			}
		}
	});

	// 학력
	gridAcbg.value.bind('cellEditEnd', event => {
		if (event.dataField == 'fnlAcbgYn' && event.value == 'Y') {
			// 최종학교여부컬럼 체크시
			let acbgList = gridAcbg.value.getGridData();

			for (let i = 0; i < acbgList.length; i++) {
				if (acbgList[i]._$uid == event.item._$uid) continue;

				if (acbgList[i].fnlAcbgYn == 'Y') {
					gridAcbg.value.setCellValue(i, 'fnlAcbgYn', 'N');
				}
			}
		}
	});

	// 어학
	gridLangst.value.bind('cellEditEnd', e => {
		if (e.oldValue === e.value) return;
		let data = {};
		switch (e.dataField) {
			case 'frglKdCd': // 외국어종류 변경시 >> 시험명, 등급 초기화
				data = {
					langstExamCd: '',
					langstGrdCd: '',
				};
				gridLangst.value.updateRow({ ...data }, 'selectedIndex');
				break;
			case 'langstExamCd': // 시험명 변경시 >> 등급 초기화
				data = {
					langstGrdCd: '',
				};
				gridLangst.value.updateRow({ ...data }, 'selectedIndex');
				break;

			default:
				break;
		}
	});
};

/**
 * Edit End 이벤트 처리
 */
function fn_onCellEditBegin() {
	// 승인요청상태일 경우 모두 수정불가
	if (
		!(
			formObj.value.hrAplySttsCd == '01' ||
			formObj.value.hrAplySttsCd == '04' ||
			commonUtils.gfn_isEmpty(formObj.value.hrAplySttsCd)
		)
	) {
		return false;
	}
}

/**
 * Form to Grid Binding
 * Form Component의 값이 변경될 때, 그리드와 동기화한다.
 **/
watch(
	() => formObj.value.incoEmlId,
	value => {
		if (commonUtils.gfn_isEmpty(value)) {
			formObj.value.incoEmlAddr = null;
		} else {
			if (commonUtils.gfn_isNotEmpty(incoEmlDomain.value.HR_BU_DMN_CD)) {
				formObj.value.incoEmlAddr = value + '@' + incoEmlDomain.value.HR_BU_DMN_CD;
			}
		}
	},
	{ deep: true },
);

/**
 * Form to Grid Binding
 * Form Component의 값이 변경될 때, 그리드와 동기화한다.
 **/
watch(
	() => formObj.value,
	value => {
		for (const key in value) {
			// formObj 값이 emptyString일 경우 : null 변환
			if (value.hasOwnProperty(key) && value[key] === '') {
				value[key] = null;
			}
		}
	},
	{ deep: true },
);

onMounted(() => {
	gridAddr.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridCrer.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridAcbg.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridQlfcLic.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridLangst.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridFmly.value.bind('cellEditBegin', fn_onCellEditBegin);
	gridBacnt.value.bind('cellEditBegin', fn_onCellEditBegin);
});

onBeforeMount(async () => {
	searchModel.value.langCd = commonUtils.gfn_getRouteParam().langCd;
	searchModel.value.compCd = commonUtils.gfn_getRouteParam().compCd;
	searchModel.value.empNo = commonUtils.gfn_getRouteParam().empNo;
	searchModel.value.hrBuCd = commonUtils.gfn_getRouteParam().hrBuCd;

	TEMP_KEY = commonUtils.gfn_getRouteParam().tempKey;

	cmCdList.value = await commonUtils.gfn_getMultiCmCd(
		[
			// 사원
			'EMPL_SHP_CD', // 고용형태코드
			'GNDR_SE_CD', // 성별
			'SRLR_SE_CD', // 음양력구분
			'HDOFC_STTS_CD', // 재직상태코드

			// 인적사항
			'PIN_KD_CD', // 개인식별번호종류코드
			'NAT_CD', // 국적코드
			'FRGR_SE_CD', // 내외국인
			'FMLY_REL_CD', // 비상연락처관계코드
			'MILS_SE_CD', // 병역구분코드
			'MILB_CD', // 군별코드
			'MOS_CD', // 병과코드
			'DCRK_CD', // 제대계급코드
			'DSBL_SE_CD', // 장애구분코드
			'DSBL_GRD_CD', // 장애등급코드
			'DSBL_TP_CD', // 장애유형코드
			'DSBL_MLSV_CD', // 장애경중증코드
			'RWPT_SE_CD', // 보훈구분코드

			// 주소
			'ADDR_KD_CD', // 주소종류코드

			// 학력
			'ACBG_CD', // 학력코드
			'ACDG_CD', // 학위코드
			'MJR_CD', // 전공
			'CAMPUS_TP_CD', // 캠퍼스유형코드
			'GRDT_SE_CD', // 졸업구분코드

			// 자격
			'QLFC_LIC_KD_CD', // 자격종목
			'QLFC_LIC_GRD_CD', // 자격등급

			// 어학
			'FRGL_KD_CD', // 외국어종류코드
			'LANGST_EXAM_CD', // 어학시험코드
			'LANGST_GRD_CD', // 어학등급코드

			// 가족

			// 계좌
			'SAL_BACNT_KD_CD', // 급여계좌종류코드
			'BANK_CD', // 은행코드
		],
		{ compCd: 'D001' },
	);

	nextTick(() => {
		if (
			commonUtils.gfn_isNotEmpty(searchModel.value.langCd) &&
			commonUtils.gfn_isNotEmpty(searchModel.value.compCd) &&
			commonUtils.gfn_isNotEmpty(searchModel.value.empNo)
		) {
			fmlyRelCdList.value = cmCdList.value.FMLY_REL_CD.filter(e => e.dtlCd !== '50');

			initEvent();
			onClickSearch();
		} else {
			fn_formDisalbe(true);
		}
	});
	hrBuEmlDmnList.value = await getHrBuEmlDmn();
	phonNatCdList.value = await getPhoneNatCdList();
	incoEmlDomain.value.HR_BU_DMN_CD = '@' + hrBuEmlDmnList.value[0].HR_BU_DMN_CD;
});

/**
 * 전화번호 국가코드 목록 조회
 */
async function getPhoneNatCdList() {
	return await apiCall
		.get(
			'HR_API/hr/global/public/getPhoneNatCdList',
			{ ...searchModel.value },
			{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
		)
		.then(res => res.data.data);
}

/**
 * 전화번호 국가코드 목록 조회
 */
async function getHrBuEmlDmn() {
	return await apiCall
		.get(
			'HR_API/hr/global/public/getHrBuEmlDmn',
			{ ...searchModel.value },
			{ headers: { 'SYSTEM-TEMP-KEY': TEMP_KEY } },
		)
		.then(res => res.data.data);
}

/**
 * 파일 업로드 Callback
 */
function onConfirmFmly(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridFmly.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridFmly.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

function onConfirmQlfcLic(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridQlfcLic.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridQlfcLic.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

function onConfirmLangst(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridLangst.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridLangst.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

function onConfirmBacnt(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridBacnt.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridBacnt.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

function onConfirmAcbg(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridAcbg.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridAcbg.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

function onConfirmCrer(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		gridCrer.value.updateRow(
			{
				fileGrpNo: fileGrpNo,
				fileCnt: fileList.length,
			},
			'selectedIndex',
		);
	} else {
		gridCrer.value.updateRow(
			{
				fileGrpNo: null,
				fileCnt: null,
			},
			'selectedIndex',
		);
	}
	return true;
}

//파일 첨부 팝업
function onClickUploadPop() {
	refUploadPicPop.value.onClickOpen();
}

function onConfirmPhoto(fileGrpNo, fileList) {
	if (fileList.length > 0) {
		formObj.value.fileGrpNo = fileGrpNo;
	} else {
		formObj.value.fileGrpNo = null;
	}
	return true;
}
</script>

<style lang="scss" scoped>
.emhrinforegaply-wrap {
	height: 100vh;
	display: flex;
	flex-direction: column;
	gap: 13px;
	padding: 20px 25px;
}

.content-scroll {
	padding-top: 10px;
	padding-bottom: 1px;

	> div {
		&:not(:first-child) {
			margin-top: 16px;
		}
	}
}

.grid-height {
	height: calc(100% - 1px);
	min-height: 240px;
	padding-top: 10px;
}

.page-title {
	display: flex;
	// flex: 1;
	align-items: center;
	height: 21px;
	gap: 10px;

	.h1 {
		font-size: 18px;
		font-weight: bold;
		color: var(--heading-text);
	}

	.h4 {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
		height: 28px;
		color: var(--heading-text);
	}
}

</style>
