<!--------------------------------------------------------------------
    파일   : LoginPwdPop.vue
    작성   : CJ Developer
    설명   : 아이디 확인 팝업
---------------------------------------------------------------------->

<template>
	<FLayerPopup
		ref="refPopup"
		width="480"
		height="320"
		:title="currentTitle"
		:confirm="
			currentPopup === 'loginCertSend'
				? t('BTN.SA.TRAN.001')
				: t('BTN.CM.CNFM.001')
		"
		@confirm="onClickConfirm"
		@close="onClickClose"
	>
		<template #header />
		<template #body>
			<LoginIdCnfm
				v-if="currentPopup === 'loginIdCnfm'"
				v-model:lgnIdValue="lgnId"
			/>
			<!-- 이메일 인증 팝업 -->
			<LoginCertMthd
				v-else-if="currentPopup === 'loginCertMthd'"
				v-model:emlAddrValue="emlAddr"
				v-model:cpnoValue="cpno"
				v-model:certWaySeCdValue="certWaySeCd"
			/>
			<LoginCertSend
				v-else-if="currentPopup === 'loginCertSend'"
				v-model:emlAddrValue="emlAddr"
				v-model:lgnIdValue="lgnId"
				v-model:cpnoValue="cpno"
				v-model:certWaySeCdValue="certWaySeCd"
				@prev-clicked="onClickPrev"
			/>
			<LoginCertNo
				v-else-if="currentPopup === 'loginCertNo'"
				v-model:emlAddrValue="emlAddr"
				v-model:lgnIdValue="lgnId"
				v-model:cpnoValue="cpno"
				v-model:userIdValue="userId"
				v-model:certNoValue="certNo"
				v-model:certWaySeCdValue="certWaySeCd"
				v-model:compCdValue="compCd"
			/>
			<LoginPwdChg
				v-else-if="currentPopup === 'loginPwdChg'"
				v-model:pswdValue="pswd"
				v-model:pswdChkValue="pswdChk"
			/>
		</template>
		<template #footer />
	</FLayerPopup>
</template>

<script setup>
import { ref } from 'vue';
import apiCall from '@/plugins/apiCall';
import commonUtils from '@fila/lib/utils/commonUtils';
import { useMessage } from '@fila/lib/plugins/message/message';
import hex from 'crypto-js/enc-hex';
import sha512 from 'crypto-js/sha256';

import LoginIdCnfm from '@/components/login/LoginIdCnfm.vue';
import LoginCertMthd from '@/components/login/LoginCertMthd.vue';
import LoginCertSend from '@/components/login/LoginCertSend.vue';
import LoginCertNo from '@/components/login/LoginCertNo.vue';
import LoginPwdChg from '@/components/login/LoginPwdChg.vue';
const { t } = useI18n();

const props = defineProps({
	compCdValue: {
		type: [String, null],
	},
	compNmValue: {
		type: [String, null],
	},
});
const compCd = computed({
	get() {
		return props.compCdValue;
	},
});
const compNm = computed({
	get() {
		return props.compNmValue;
	},
});

const currentPopup = ref('loginIdCnfm');
//const currentTitle = ref('');
const currentTitle = computed(
	() => t('LBL.CM.LGN.022') + '(' + compNm.value + ')',
);

const lgnId = ref('');

const emlAddr = ref('');
const certWaySeCd = ref('');
const cpno = ref('');
const certMthd = ref({});
const userId = ref('');
const certNo = ref('');
const pswd = ref('');
const pswdChk = ref('');

const message = useMessage();

const onClickConfirm = () => {
	switch (currentPopup.value) {
		case 'loginIdCnfm':
			getLoginIdCnfm();
			break;
		case 'loginCertMthd':
			getLoginCertMthd();
			break;
		case 'loginCertSend':
			sendLoginCertNo();
			break;
		case 'loginCertNo':
			checkloginCertNo();
			break;
		case 'loginPwdChg':
			setloginPwdChg();
			break;
		default:
			break;
	}
};

const getLoginIdCnfm = async () => {
	const params = {
		compCd: compCd.value,
		lgnId: lgnId.value,
	};

	await apiCall
		.post('CM_API/cm/global/common/auth/findCertMthd', params)
		.then(res => {
			certMthd.value = res.data.data;
			if (commonUtils.gfn_isEmpty(certMthd.value)) {
				message.showAlert({
					message: t('MSG.CM.LGN.001'),
					onConfirm: () => {
						return false;
					},
				});
			} else {
				emlAddr.value = certMthd.value.emlAddr;
				cpno.value = certMthd.value.cpno;
				userId.value = certMthd.value.userId;

				if (commonUtils.gfn_isNotEmpty(certMthd.value.emlAddr)) {
					certWaySeCd.value = '01';
				} else if (commonUtils.gfn_isNotEmpty(certMthd.value.cpno)) {
					certWaySeCd.value = '03';
				}

				currentPopup.value = 'loginCertMthd';
			}
			return;
		});
};

const getLoginCertMthd = async () => {
	if (commonUtils.gfn_isEmpty(certWaySeCd.value)) {
		message.showAlert({
			message: t('MSG.CM.LGN.002'),
			onConfirm: () => {
				return false;
			},
		});
	} else {
		if (
			(certWaySeCd.value == '01' && commonUtils.gfn_isEmpty(emlAddr.value)) ||
			(certWaySeCd.value == '03' && commonUtils.gfn_isEmpty(cpno.value))
		) {
			message.showAlert({
				message: t('MSG.CM.LGN.002'),
				onConfirm: () => {
					return false;
				},
			});
		} else {
			currentPopup.value = 'loginCertSend';
		}
	}
};

const onClickPrev = async () => {
	currentPopup.value = 'loginCertMthd';
};

const sendLoginCertNo = async () => {
	certNo.value = '';
	currentPopup.value = 'loginCertNo';
};

const checkloginCertNo = async () => {
	const params = {
		userId: userId.value,
		certNo: certNo.value,
	};
	await apiCall
		.post('CM_API/cm/global/common/auth/checkCertNo', params)
		.then(res => {
			message.showAlert({
				message: t('MSG.CM.CERT.002'),
				onConfirm: () => {
					if (res?.data.data.successYn === '1') {
						currentPopup.value = 'loginPwdChg';
					} else {
						certNo.value = '';
						return;
					}
				},
			});
		});
};

const setloginPwdChg = async () => {
	if (!commonUtils.gfn_validPassword(pswd.value, pswdChk.value)) {
		return;
	}

	const params = {
		emlAddr: emlAddr.value,
		lgnId: lgnId.value,
		cpno: cpno.value,
		userId: userId.value,
		certWaySeCd: certWaySeCd.value,
		pswd: hex.stringify(sha512(pswd.value)),
	};
	await apiCall
		.post('CM_API/cm/global/common/auth/chgPswd', params)
		.then(res => {
			message.showAlert({
				message: res.data.statusMessage,
				onConfirm: () => {
					if (res.data.data.successYn === '1') {
						fnClose();
					}
				},
			});
		});
};

const refPopup = shallowRef(null);

//팝업오픈
const fnOpen = async () => {
	refPopup.value.open();
};

const fnClose = async () => {
	popupClose();
};

function onClickClose() {
	popupClose();
}

function popupClose() {
	currentPopup.value = 'loginIdCnfm';
	lgnId.value = '';
	emlAddr.value = '';
	certWaySeCd.value = '';
	cpno.value = '';
	certMthd.value = {};
	userId.value = '';
	certNo.value = '';
	pswd.value = '';

	refPopup.value.close();
}

function getShow() {
	return refPopup.value.getShow();
}

defineExpose({
	fnOpen,
	getShow,
});
</script>
